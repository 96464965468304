import { ref, computed, reactive } from '@nuxtjs/composition-api'
import { IMediaStream } from '../media'
import {
  IVideoCallMessage,
  IVideoCallConnectionUser,
  VideoCallTarget,
  VideoCallStatus,
} from './videoCall.types'

export enum VideoCallEvents {
  giftSelected = 'gift_selected',
  report = 'chat_block_send',
  notEnoughtCoins = 'not_enought_coins',
  remoteUserSkip = 'remote_user_skip',
}

export enum VideoCallMessageType {
  giftGot = 5,
  giftSent = 6,
  messageGot = 7,
  messageSent = 8,
}

export const videoCallActive = ref(false)
export const videoCallTarget = ref<VideoCallTarget>('none')
export const videoCallStatus = ref<VideoCallStatus>('none')
export const videoCallTimerActive = ref(false)
export const videoCallTimerSec = ref(0)
export const videoCallConnectionUser = ref<IVideoCallConnectionUser>()
export const videoCallMessages = ref<IVideoCallMessage[]>([])

export const videoCallPeerIsInit = ref(false)
export const videoCallPeerRemoteStream = ref<IMediaStream>()
export const videoCallPeerRemoteSrc = computed(
  () => videoCallConnectionUser.value?.fakeVideoUrl
)

export interface IVideoCallInfo {}
export const videoCallInfo = reactive<IVideoCallInfo>({})

export interface IVideoCallState {}
export const videoCallState = reactive<IVideoCallState>({})
