import { computed } from '@nuxtjs/composition-api'

export function useLayout() {
  const isDesktop = computed(() => process.client && window.innerWidth >= 1024)
  const isMobile = computed(() => !isDesktop.value)

  return {
    isDesktop,
    isMobile,
  }
}
