export const LOW_COINS = 600

export const EVENTBUS_CONTEXT = 'video-call'
export const EVENTBUS_COMMAND_BASE = 'base'

export enum VideoCallEvents {
  giftSelected = 'gift_selected',
  report = 'chat_block_send',
  notEnoughtCoins = 'not_enought_coins',
  remoteUserSkip = 'remote_user_skip',
}

export enum VideoCallMessageType {
  giftGot = 5,
  giftSent = 6,
  messageGot = 7,
  messageSent = 8,
  messageSentNeedReg = 100,
}
