// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U2vi[data-v-3517708a]{display:flex;align-items:center;justify-content:center;width:72px;height:72px;-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer;border-radius:50%;background-color:#fb5249;transition:.3s}.U2vi[data-v-3517708a]:hover{background-color:rgba(251,82,73,.7)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "U2vi"
};
module.exports = ___CSS_LOADER_EXPORT___;
