import { defineStore } from 'pinia'
import { AuthModalStore, AuthApiResponseErrorDetail } from '../types'

export const useAuthModalStore = defineStore('authModal', {
  state: (): AuthModalStore => ({
    loading: false,
    screen: '',
    open: false,
    email: '',
    emailValid: false,
    password: '',
    passwordValid: false,
    apiError: '',
    apiErrorCode: 0,
    apiErrorDetails: [],
  }),
  getters: {
    apiErrorDetailsEmail: (state) =>
      state.apiErrorDetails.filter(
        (item: AuthApiResponseErrorDetail) => item.key === 'email'
      ),
    apiErrorDetailsPassword: (state) =>
      state.apiErrorDetails.filter(
        (item: AuthApiResponseErrorDetail) => item.key === 'pwd'
      ),
    apiErrorDetailsGoogle: (state) =>
      state.apiErrorDetails.filter(
        (item: AuthApiResponseErrorDetail) => item.key === 'google'
      ),
  },
  actions: {
    reset() {
      this.resetApi()
      this.resetModal()
      this.screen = ''
    },
    resetApi() {
      this.apiError = ''
      this.apiErrorCode = 0
      this.apiErrorDetails = []
    },
    resetModal() {
      this.loading = false
      this.open = false
      this.email = ''
      this.emailValid = false
      this.password = ''
      this.passwordValid = false
    },
    errorDetailPush(key: string, error: string) {
      this.apiErrorDetails.push({
        key,
        error,
      })
    },
  },
})
