// import { audioSignalEntity } from '@/src/entities/audio-signals'
import { websocket } from '@/src/shared/lib/websocket'

const WEBSOCKET_EVENT_NAME = 'user_added_to_favorites'

// type FavoritesWebsocketUserAddedToFavoritesPayload = {
//   s: number
//   r: number
//   n: string
//   m: string
//   ts: number
//   p: string
//   type: 'user_added_to_favorites'
//   salt: string
//   ip: boolean
//   s_role: number
//   r_role: null
// }

async function handlerUserAddedToFavorites() {
  // payload: FavoritesWebsocketUserAddedToFavoritesPayload
  // await audioSignalEntity.model.matchPlay()
}

export function onUserAddedSignal() {
  if (!websocket.connected('application')) return
  const client = websocket.client('application')
  client.on(WEBSOCKET_EVENT_NAME, handlerUserAddedToFavorites)
}

export function offUserAddedSignal() {
  if (!websocket.connected('application')) return
  const client = websocket.client('application')
  client.off(WEBSOCKET_EVENT_NAME, handlerUserAddedToFavorites)
}
