import { urlCache } from '@/src/shared/lib/urlCache'
import { IAnalyticUserProps } from '@/src/shared/lib/analytics'

type EnterProps = Pick<
  IAnalyticUserProps,
  | 'enter_partner_id'
  | 'enter_utm_campaign'
  | 'enter_utm_content'
  | 'enter_utm_medium'
  | 'enter_utm_source'
  | 'enter_utm_term'
>

export function enterProps(): EnterProps {
  const params = urlCache.searchParamsGet('enter')
  const userProps: EnterProps = {
    enter_partner_id: '',
    enter_utm_source: '',
    enter_utm_medium: '',
    enter_utm_campaign: '',
    enter_utm_term: '',
    enter_utm_content: '',
  }
  if (params) {
    Object.keys(params).forEach((key) => {
      const enterKay = `enter_${key}` as keyof EnterProps
      switch (key) {
        case 'p':
          userProps.enter_partner_id = params[key]
          break
        default:
          userProps[enterKay] = params[key]
      }
    })
  }
  return userProps
}
