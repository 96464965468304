import { billingRepository } from '../billing/billing.api.usecase'
import {
  BILLING_AUTO_RECURRING_CHECKBOX,
  billingBalance,
  billingBalanceLoaded,
  billingCards,
  billingCardsLoaded,
  billingTransactionsLoaded,
  billingTransactions,
} from '../billing/billing.state'

export function useBilling() {
  function billingAutoRecurringCheckGet(): boolean {
    return (
      localStorage.getItem(BILLING_AUTO_RECURRING_CHECKBOX) === 'true' || false
    )
  }

  function billingAutoRecurringCheckSet(value: boolean) {
    localStorage.setItem(BILLING_AUTO_RECURRING_CHECKBOX, value.toString())
  }

  async function billingBalanceLoad(): Promise<boolean> {
    const { success, data } = await billingRepository.balanceGet()
    billingBalanceLoaded.value = true
    if (success && data) {
      billingBalance.value = data
      return true
    }
    billingBalance.value = 0
    return false
  }

  async function billingCardsLoad(): Promise<boolean> {
    const { success, data } = await billingRepository.cardsGet()
    billingCardsLoaded.value = true
    if (success && data) {
      billingCards.value = data
      return true
    }
    billingCards.value = []
    return false
  }

  async function billingTransactionsLoad(): Promise<boolean> {
    const { success, data } = await billingRepository.transactionGet()
    billingTransactionsLoaded.value = true
    if (success && data) {
      billingTransactions.value = data
      return true
    }
    billingTransactions.value = []
    return false
  }

  async function billingRefundSend(orderId: string): Promise<boolean> {
    const { success, data } = await billingRepository.refundPost(orderId)
    return success && data
  }

  async function billingReccuring(sku: string, cardId: string) {
    return await billingRepository.recurringPost(sku, cardId)
  }

  return {
    billingBalanceLoad,
    billingCardsLoad,
    billingTransactionsLoad,
    billingRefundSend,
    billingReccuring,
    billingAutoRecurringCheckGet,
    billingAutoRecurringCheckSet,
  }
}
