import { videoCallEntity } from '@/src/entities/video-call2'

export function useMessages() {
  videoCallEntity.model.useWebsocketOnMessage(function (
    message: videoCallEntity.types.VideoCallMessage
  ) {
    const store = videoCallEntity.model.useVideoCallStore()
    store.messagePush(message)
  })
}

export function messageSend(socketId: string, message: string) {
  const videoCallStore = videoCallEntity.model.useVideoCallStore()
  const callId = videoCallStore.remoteUser?.callId || ''
  videoCallEntity.model.websocketSendMessage({ socketId, message, callId })
}
