const URL_CACHE_STORAGE_NAME = 'url-cache'

type UrlCacheObject = {
  [key: string]: URL
}

type UrlCacheObjectStorage = {
  [key: string]: string
}

type UrlCacheSearchParams = {
  [key: string]: string
}

class UrlCache {
  private get cache(): UrlCacheObject | undefined {
    const cache = sessionStorage.getItem(URL_CACHE_STORAGE_NAME)
    if (!cache) return undefined
    const cacheObj: UrlCacheObjectStorage = JSON.parse(cache)
    const result: UrlCacheObject = {}
    Object.entries(cacheObj).forEach(
      ([key, value]) => (result[key] = new URL(value as string))
    )
    return result
  }

  private set cache(cacheObj: UrlCacheObject | undefined) {
    if (!cacheObj) {
      sessionStorage.removeItem(URL_CACHE_STORAGE_NAME)
    } else {
      const result: UrlCacheObjectStorage = {}
      Object.entries(cacheObj).forEach(
        ([key, value]) => (result[key] = value.href)
      )
      sessionStorage.setItem(URL_CACHE_STORAGE_NAME, JSON.stringify(result))
    }
  }

  public urlSet(path?: string, url: string = location.href) {
    const urlObj = new URL(url)
    path = path || urlObj.pathname
    this.cache = Object.assign(this.cache || {}, {
      [path]: urlObj,
    })
  }

  public searchParamsGet(path: string): UrlCacheSearchParams | undefined {
    if (!this.cache) return
    if (!this.cache[path]) return
    const searchParams: UrlCacheSearchParams = {}
    this.cache[path].searchParams.forEach(
      (value: string, key: string) => (searchParams[key] = value)
    )
    return searchParams
  }

  public searchParamGet(
    path: string,
    name: keyof UrlCacheSearchParams
  ): string | undefined {
    const searchParams = this.searchParamsGet(path)
    if (!searchParams) return
    return searchParams[name]
  }
}

export const urlCache = new UrlCache()
