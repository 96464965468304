import { defineStore } from 'pinia'
import { IMediaDevice, IMediaStream, MediaPermissionError } from '../types'

type MediaStoreState = {
  devices: IMediaDevice[]
  supported: boolean
  startInit: boolean
  permissionShow: boolean
  permissionGranted: boolean
  permissionDenied: boolean
  localStream: IMediaStream | undefined
  localStreamInit: boolean
  localStreamError: MediaPermissionError
  localStreamAudioActive: boolean
}

export const useMediaStore = defineStore('media', {
  state: (): MediaStoreState => {
    return {
      devices: [],
      supported: false,
      startInit: false,
      permissionShow: false,
      permissionGranted: false,
      permissionDenied: false,
      localStream: undefined,
      localStreamInit: false,
      localStreamError: MediaPermissionError.None,
      localStreamAudioActive: false,
    }
  },
  getters: {
    audioInputDevices: (state) =>
      state.devices.filter(
        (device: IMediaDevice) => device.kind === 'audioinput'
      ),
    videoInputDevices: (state) =>
      state.devices.filter(
        (device: IMediaDevice) => device.kind === 'videoinput'
      ),
  },
  actions: {},
})
