import { privateCallEntity } from '@/src/entities/private-call'
import { userEntity } from '@/src/entities/user'

export function enoughOnTheBalanceToCall() {
  return enoughOnTheBalance(privateCallEntity.config.CALL_COST_IN_COINS)
}

export function enoughOnTheBalance(coins: number) {
  return userEntity.model.balanceEnough(coins)
}
