import {
  videoCallActive,
  videoCallTarget,
  videoCallStatus,
  videoCallTimerActive,
  videoCallTimerSec,
  videoCallConnectionUser,
  videoCallMessages,
  videoCallPeerIsInit,
  videoCallPeerRemoteStream,
} from './videoCall.state'

export function useVideoCallHelpers() {
  function videoCallResetState() {
    videoCallActive.value = false
    videoCallTarget.value = 'none'
    videoCallStatus.value = 'none'
    videoCallTimerActive.value = false
    videoCallTimerSec.value = 0
    videoCallConnectionUser.value = undefined
    videoCallMessages.value = []
    videoCallPeerIsInit.value = false
    videoCallPeerRemoteStream.value = undefined
  }

  return {
    videoCallResetState,
  }
}
