import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api'
import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketOnFavoriteRequestReceivedCallback = () => void

const EMIT_NAME = 'favorite_request_received'

export function useWebsocketOnFavoriteRequestReceived(
  callback: VideoCallWebsocketOnFavoriteRequestReceivedCallback
) {
  const defaultClient = websocket.client('application')
  const off = () => defaultClient.off(EMIT_NAME, callback)
  onMounted(() => defaultClient.on(EMIT_NAME, callback))
  onBeforeUnmount(() => off())
  return {
    off,
  }
}
