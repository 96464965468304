import { ref } from '@nuxtjs/composition-api'
import { IBillingTransactionObject, IBillingCardObject } from './billing.types'

// TODO Experiment
export const BILLING_AUTO_RECURRING_CHECKBOX = 'auto-recurring-checkbox'
export const BILLING_AUTO_RECURRING_BALANCE = 120
export const BILLING_AUTO_RECURRING_SKU = '1050_coins'
export const BILLING_AUTO_RECURRING_AMOUNT = 10.99
export const BILLING_AUTO_RECURRING_COINS = 1050
export const BILLING_AUTO_RECURRING_ENTRY = 'bundle1-new'

export const billingBalanceLoaded = ref(false)
export const billingBalance = ref(0)

export const billingTransactionsLoaded = ref(false)
export const billingTransactions = ref<IBillingTransactionObject[]>([])

export const billingCardsLoaded = ref(false)
export const billingCards = ref<IBillingCardObject[]>([])
