const STORIES_LIMIT_CACHE = 'stories-limit-cache'
const STORIES_LIMIT_COUNT = 5
const STORIES_LIMIT_DELAY_5_MIN = 300000
const STORIES_LIMIT_DELAY_1_HOUR = 3600000
const STORIES_LIMIT_DELAY_1_DAY = 86400000

type StoriesLocalCache = {
  block: boolean
  viewed: number
  count: number
  time: number
}

function cacheGet(): StoriesLocalCache {
  const cache = localStorage.getItem(STORIES_LIMIT_CACHE)
  if (!cache) {
    return {
      block: false,
      viewed: 0,
      count: 0,
      time: 0,
    }
  }
  return typeof cache === 'string' ? JSON.parse(cache) : cache
}

function cacheSet(cache: StoriesLocalCache): void {
  localStorage.setItem(STORIES_LIMIT_CACHE, JSON.stringify(cache))
}

export function limitView() {
  const cache = cacheGet()
  cache.viewed++
  cacheSet(cache)
}

export function limitShowRegModal(): boolean {
  const cache = cacheGet()
  return cache.block && cache.count === 4
}

export function limitIsBlock() {
  const cache = cacheGet()
  if (cache.block) {
    if (Date.now() > cache.time) {
      cache.block = false
      cache.viewed = 0
      cacheSet(cache)
      return false
    } else {
      return true
    }
  }

  if (cache.viewed === STORIES_LIMIT_COUNT) {
    switch (cache.count) {
      case 0:
        cache.count = 1
        cache.time = Date.now() + STORIES_LIMIT_DELAY_5_MIN
        break
      case 1:
        cache.count = 2
        cache.time = Date.now() + STORIES_LIMIT_DELAY_1_HOUR
        break
      case 2:
        cache.count = 3
        cache.time = Date.now() + STORIES_LIMIT_DELAY_1_DAY
        break
      case 3:
        cache.count = 4
        cache.time = 0
        break
      default:
        return true
    }
    cache.block = true
    cacheSet(cache)
    return true
  }

  return false
}

export function limitGetTimeEnd(): number {
  const cache = cacheGet()
  return cache.block ? cache.time : 0
}
