import { appWarmup } from './app.state'
import { appRepository } from './app.api.usecase'

export async function useAppWarmup(): Promise<void> {
  if (appWarmup.value) return Promise.resolve()

  const { success, data } = await appRepository.warmupGet()
  if (success && data) {
    appWarmup.value = data
  }
}
