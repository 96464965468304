import { AuthResponseError } from '../auth/authError.service'
import { UserApi } from '../repository'
import {
  IUserMeGetResponse,
  IUserObject,
  IUserPreremoveGetResponse,
  IUserReasonsForRemoveObject,
  IUserByIdGetResponse,
  IUserMeUpdatePayload,
  IUserPostResponse,
  IUserPreremovePostResponse,
  IUserFeedbackTopic,
  IUserFeedbackPostResponse,
  IUserRemovePostResponse,
  IUserCountersLoadResponse,
  IUserFavoritesPostResponse,
} from './user.types'
import { UserFavoritesContext } from './user.state'

export class UserRepository {
  protected _api: UserApi
  protected _auth: AuthResponseError

  constructor() {
    this._api = new UserApi()
    this._auth = new AuthResponseError()
  }

  public async meGet() {
    let data: IUserObject | undefined
    const response = await this._api.meGET()
    const state = this._api.parse<IUserMeGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.user
    }

    return {
      ...state,
      data,
    }
  }

  public async mePost(userProps: IUserMeUpdatePayload) {
    let data: boolean = false
    const response = await this._api.mePOST(userProps)
    const state = this._api.parse<IUserPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }
    return {
      ...state,
      data,
    }
  }

  public async meRemovePost() {
    let data: boolean = false
    const response = await this._api.removePOST()
    const state = this._api.parse<IUserRemovePostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }
    return {
      ...state,
      data,
    }
  }

  public async preremoveGet() {
    let data: IUserReasonsForRemoveObject[] = []
    const response = await this._api.preremoveGET()
    const state = this._api.parse<IUserPreremoveGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.questions || []
    }
    return {
      ...state,
      data,
    }
  }

  public async preremovePost(reasons: string[]) {
    let data: boolean = false
    const response = await this._api.preremovePOST(reasons)
    const state = this._api.parse<IUserPreremovePostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }
    return {
      ...state,
      data,
    }
  }

  public async userByIdGET(userId: number) {
    let data: IUserObject | undefined
    const response = await this._api.userByIdGET(userId)
    const state = this._api.parse<IUserByIdGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.user
    }
    return {
      ...state,
      data,
    }
  }

  public async feedbackPost(topic: IUserFeedbackTopic, text: string) {
    let data: boolean = false
    const response = await this._api.feedbackPOST(topic, text)
    const state = this._api.parse<IUserFeedbackPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }
    return {
      ...state,
      data,
    }
  }

  public async countersGET() {
    let data: IUserCountersLoadResponse | undefined
    const response = await this._api.countersGET()
    const state = this._api.parse<IUserCountersLoadResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData
    }
    return {
      ...state,
      data,
    }
  }

  public async favoritesPost(userId: number, context: UserFavoritesContext) {
    let data: boolean = false
    const response = await this._api.favoritesPOST(userId, context)
    const state = this._api.parse<IUserFavoritesPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.isFavorite || false
    }
    return {
      ...state,
      data,
    }
  }
}

export const userRepository = new UserRepository()
