import { reactive } from '@nuxtjs/composition-api'
import { videoCallStatus } from './videoCall.state'

export interface IVideoCallCaptureState {
  videoNode: HTMLVideoElement | undefined
  canvasWidth: number
  canvasHeight: number
  videoWidth: number
  videoHeight: number
  lastCupture: string
}

export const videoCallCaptureState = reactive<IVideoCallCaptureState>({
  videoNode: undefined,
  canvasWidth: 0,
  canvasHeight: 0,
  videoWidth: 0,
  videoHeight: 0,
  lastCupture: '',
})

export function useVideoCallCapture() {
  function videoCallCaptureInit(videoNode: HTMLVideoElement) {
    videoCallCaptureState.videoNode = videoNode
    videoCallCaptureState.canvasWidth = videoNode.offsetWidth
    videoCallCaptureState.canvasHeight = videoNode.offsetHeight
    videoCallCaptureState.videoWidth = videoNode.videoWidth
    videoCallCaptureState.videoHeight = videoNode.videoHeight
  }

  function videoCallCaptureScreen() {
    // console.time('videoCallCaptureScreen')
    const videoNode = videoCallCaptureState.videoNode
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context || !videoNode) return
    canvas.width = videoCallCaptureState.canvasWidth
    canvas.height = videoCallCaptureState.canvasHeight
    const videoWidth = videoCallCaptureState.videoWidth
    const videoHeight = videoCallCaptureState.videoHeight

    const x = (canvas.width - videoWidth) / 2
    const y = 0
    context.rect(0, 0, canvas.width, canvas.height)
    context.fill()
    context.drawImage(
      videoNode,
      0,
      0,
      videoWidth,
      videoHeight,
      x,
      y,
      videoWidth,
      videoHeight
    )
    try {
      const imageSrcBase64 = canvas.toDataURL('image/png')
      videoCallCaptureState.lastCupture = imageSrcBase64
    } catch (err: any) {
      videoCallCaptureState.lastCupture = ''
    }
    canvas.remove()
    // console.timeEnd('videoCallCaptureScreen')
  }

  function videoCallCaptureFreezeShow() {
    videoCallCaptureScreen()
    videoCallStatus.value = 'freeze'
  }

  return {
    videoCallCaptureInit,
    videoCallCaptureScreen,
    videoCallCaptureFreezeShow,
  }
}
