import { ref } from '@nuxtjs/composition-api'
import { IMediaStream, MediaPermissionError } from './media.types'

export const mediaDevices = ref<MediaDeviceInfo[]>([])
export const mediaSupported = ref(false)
export const mediaStartInit = ref(false)
export const mediaPermissionGranted = ref(false)
export const mediaPermissionDenied = ref(false)
export const mediaLocalStream = ref<IMediaStream | null>(null)
export const mediaLocalStreamInit = ref(false)
export const mediaLocalStreamError = ref<MediaPermissionError>(
  MediaPermissionError.None
)
export const mediaLocalStreamAudioActive = ref(true)
