import { User2, UserGender, UserAvatar, UserApiMeResponse } from '../types'
import { useUserStore } from './user.store'
import { userMeGet } from '@/src/shared/api'

function meMapper(response: UserApiMeResponse): User2 {
  const data = response.user

  let gender: UserGender = ''
  switch (data.gender) {
    case 'Male':
      gender = 'male'
      break
    case 'Female':
      gender = 'female'
      break
  }

  let avatar: UserAvatar | null = null
  if (data.avatar) {
    avatar = {
      id: data.avatar.id,
      src: data.avatar.urlS || data.avatar.urlF || '',
    }
  }

  const roles = data?.roles || []

  return {
    id: data.id,
    about: data.about || '',
    name: data.name,
    age: data.age,
    partnerId: data.partnerId,
    gender,
    email: data.email,
    avatar,
    locale: data.locale,
    emailConfirmed: data.emailConfirmed,
    finishPreRegistration: !roles.includes('APP_PREREGISTER_USER'),
    approvedTerms: data.approvedTerms || false,
    approvedVideochatRules: data.approvedVideochatRules || false,
    anonymous: roles.includes('APP_PREREGISTER_USER'),
    agencyId: data.agencyId,
    role: data.role,
    roles,
    isSubscriber: roles.includes('APP_SUBSCRIBER'),
    isModerator: roles.includes('APP_MODERATOR'),
    isTest: data.isTest,
    isVip: data.isVip,
    category: data.category,
    hasPurchase: data.hasPurchase,
    streamerStatus: data.streamerStatus,
    testFeatures: data.testFeatures,
    userTests: data.userTests,
    showBlurInRandom: data.showBlurInRandom || false,
  }
}

export async function meLoad(): Promise<boolean> {
  const store = useUserStore()

  const response = await userMeGet<UserApiMeResponse>()
  if (response.isAuthError) return false

  store.meLoaded = true
  if (response.isOk && response.data) {
    const user = meMapper(response.data)
    store.me = user
    return true
  }

  return false
}
