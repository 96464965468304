import { ref, computed } from '@nuxtjs/composition-api'
import {
  IPrivateCallObject,
  IPrivateCallRemoteUserObject,
  PrivateCallDirection,
  PrivateCallStatus,
} from './privateCall.types'

export enum IPrivateCallMessageType {
  'giftGot' = 5,
  'giftSent' = 6,
  'messageGot' = 7,
  'messageSent' = 8,
}

export const ANALYTICS_TYPE = 'private'
export const COINS_FOR_PRIVATE = 120
// export const TIME_PRIVATE_SEC = 60
// export const TIME_WAIT_PURCHASE_SEC = 20
export const TIME_WAIT_PAYMENT_SEC = 60

export const privateCallsLoaded = ref(false)
export const privateCalls = ref<IPrivateCallObject[]>([])
export const privateCallsParsed = computed(() => {
  const privateCallsHash: any = {}
  return privateCalls.value.filter((item: IPrivateCallObject) => {
    if (privateCallsHash[item.id]) return false
    privateCallsHash[item.id] = true
    return true
  })
})

export const privateCallEntryPath = ref('')
export const privateCallStatus = ref<PrivateCallStatus>('')
export const privateCallDirection = ref<PrivateCallDirection>('')
export const privateCallCallerId = ref(0)
export const privateCallReceiverId = ref(0)
export const privateCallRemoteUserId = computed(() =>
  privateCallDirection.value === 'income'
    ? privateCallCallerId.value
    : privateCallReceiverId.value
)
export const privateCallRemoteUser = ref<
  IPrivateCallRemoteUserObject | undefined
>()
