import {
  IDialogThreadMessageDto,
  IDialogThreadMessageSocketDto,
  IDialogThreadMessageObject,
  DialogThreadMessageType,
} from '.'

export class DialogThreadMessageMapper {
  public static apiToDomain(
    dto: IDialogThreadMessageDto
  ): IDialogThreadMessageObject {
    const mappedData: IDialogThreadMessageObject = {
      message: dto.m,
      translate: dto.mt || '',
      timestamp: dto.ts,
      status: 'delivered',
      type: dto.t,
      fromMe: dto.o === 1,
    }

    if (dto.a) {
      mappedData.data = typeof dto.a === 'string' ? JSON.parse(dto.a) : dto.a
    }

    return mappedData
  }

  public static apiToDomains(
    dtoArr: IDialogThreadMessageDto[]
  ): IDialogThreadMessageObject[] {
    return dtoArr.map((dto) => this.apiToDomain(dto))
  }

  public static socketToDomain(
    dto: IDialogThreadMessageSocketDto,
    userId: number
  ): IDialogThreadMessageObject {
    const mappedData: IDialogThreadMessageObject = {
      message: dto.m,
      translate: dto.mt || '',
      timestamp: dto.ts,
      status: 'delivered',
      type: dto.type,
      fromMe: userId !== dto.s,
    }

    if (dto.a) {
      switch (dto.type) {
        case DialogThreadMessageType.image:
          mappedData.data = {
            aspect: dto.a.aspect,
            id: dto.a.id,
            urlB: dto.a.url_b || null,
            urlM: dto.a.url_m || null,
            urlO: dto.a.url_o || null,
            urlS: dto.a.url_s || null,
            urlT: dto.a.url_t || null,
          }
          break
        default:
          mappedData.data =
            typeof dto.a === 'string' ? JSON.parse(dto.a) : dto.a
      }
    }

    return mappedData
  }
}
