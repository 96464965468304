const STORIES_LIMIT_CACHE = 'stories-premium-limit-cache'
const STORIES_LIMIT_COUNT = 5

type StoriesPremiumLocalCache = {
  viewed: number
}

function cacheGet(): StoriesPremiumLocalCache {
  const cache = localStorage.getItem(STORIES_LIMIT_CACHE)
  if (!cache) {
    return {
      viewed: 0,
    }
  }
  return typeof cache === 'string' ? JSON.parse(cache) : cache
}

function cacheSet(cache: StoriesPremiumLocalCache): void {
  localStorage.setItem(STORIES_LIMIT_CACHE, JSON.stringify(cache))
}

export function premiumViewCheck() {
  const cache = cacheGet()
  return cache.viewed < STORIES_LIMIT_COUNT
}

export function premiumView() {
  const cache = cacheGet()
  cache.viewed++
  cacheSet(cache)
}
