import {
  mediaDevices,
  mediaPermissionGranted,
  mediaPermissionDenied,
  mediaLocalStreamInit,
  mediaLocalStreamError,
} from './media.state'
import { MediaPermissionError } from './media.types'

export function useMediaPermissions() {
  function mediaPermissionsDevicesCheck(): boolean {
    let bCheck: boolean = true
    mediaDevices.value.forEach((device: any) => {
      if (device.kind === 'audioinput' && !device.label) bCheck = false
      if (device.kind === 'videoinput' && !device.label) bCheck = false
    })
    mediaPermissionGranted.value = bCheck
    return bCheck
  }

  function mediaPermissionsStreamCheck(): boolean {
    if (mediaLocalStreamError.value !== MediaPermissionError.None) {
      mediaPermissionGranted.value = false
      mediaPermissionDenied.value = true
      return false
    } else {
      mediaPermissionGranted.value = true
      mediaPermissionDenied.value = false
    }
    return true
  }

  function mediaPermissionsCheck(): boolean {
    if (mediaLocalStreamInit.value) {
      return mediaPermissionsStreamCheck()
    }
    return mediaPermissionsDevicesCheck()
  }

  return {
    mediaPermissionsCheck,
    mediaPermissionsDevicesCheck,
    mediaPermissionsStreamCheck,
  }
}
