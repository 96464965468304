import { defineStore } from 'pinia'
import { FavoritesStoreState, FavoritesItem } from '../types'

export const useFavoritesStore = defineStore('favorites', {
  state: (): FavoritesStoreState => {
    return {
      loaded: false,
      items: [],
    }
  },
  actions: {
    itemPush(item: FavoritesItem) {
      const search = this.items.find((val: FavoritesItem) => val.id === item.id)
      if (search) return
      this.items.push(item)
    },
  },
})
