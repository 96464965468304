import { useRewardsStore } from './rewards.store'
import { rewardsGet } from '@/src/shared/api'

type DailyRewardItemDTO = {
  day: number
  coins: number
  isToday: boolean
  claimed: boolean
}

type OneTimeRewardItemDTO = {
  available: boolean
  claimed: boolean
  coins: number
  subtitle: string
  title: string
  type: string // Need to see types
}

type DailyRewardsDTO = {
  daily: DailyRewardItemDTO[]
  oneTime: OneTimeRewardItemDTO[]
  currentTimestamp: number | null
  nextAvailableAtTimestamp: number | null
}

let timerId = null
const ONE_SECOND_IN_MS = 1000

function initRewardsStoreState(rewardsData: DailyRewardsDTO) {
  const store = useRewardsStore()
  const {
    daily,
    oneTime,
    currentTimestamp,
    nextAvailableAtTimestamp,
  } = rewardsData

  store.dailyClaimed = true
  store.daily = daily
  store.oneTime = oneTime
  store.rewardClaimedDate =
    new Date(currentTimestamp * ONE_SECOND_IN_MS) || null
  store.nextRewardDate =
    new Date(nextAvailableAtTimestamp * ONE_SECOND_IN_MS) || null
  store.rewardTimer = store.rewardClaimedDate.getTime()
}

function checkIsTimeExpired(): boolean {
  const { rewardTimer, nextRewardDate } = useRewardsStore()

  return nextRewardDate.getTime() - rewardTimer <= 0
}

export function initRewardsTimer(): void {
  const store = useRewardsStore()
  if (timerId) clearInterval(timerId)
  if (!store.currentDailyReward.claimed) return
  timerId = setInterval(async () => {
    if (checkIsTimeExpired()) {
      clearInterval(timerId)
      timerId = null
      await getRewards()
    } else {
      const time = store.rewardTimer + ONE_SECOND_IN_MS
      store.rewardTimer = time
    }
  }, ONE_SECOND_IN_MS)
}

export async function getRewards(): Promise<void> {
  const response = await rewardsGet<DailyRewardsDTO>()
  const isRequestSucceed =
    response.isOk && response.data.daily && response.data.oneTime
  if (!isRequestSucceed) return
  initRewardsStoreState(response.data)
  initRewardsTimer()
}
