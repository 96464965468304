export type BillingScreen = 'Male' | 'Female'

export const CARD_VISA = 'VISA'
export const CARD_MASTERCARD = 'MASTERCARD'

export type IBillingTransactionDescriptionType = 1

/**
 * Entities
 */
export interface IBillingTransactionDescription {
  details: {
    coins?: number
  }
  simpleText: string
  type: IBillingTransactionDescriptionType
}
export interface IBillingTransactionObject {
  orderId: string
  amount: number
  currency: string
  createdAt: number
  sku: string
  description: IBillingTransactionDescription
  cardBrand?: string
  cardNumber?: string
  cardBank?: string
  order?: string
  subscriptionId?: number
  subscriptionInvoiceId?: string
  refundStatus?: 'created' | 'refunded' | 'created_from_notification'
}

export interface IBillingCardObject {
  id: string
  brand: 'VISA' | 'MASTER CARD'
  number: string
}

/**
 * API: GET user/balance
 */
export interface IBillingBalanceGetResponse {
  balance: number
  isVip: boolean
}

/**
 * API: GET payment/solid_gate/card
 */
export interface IBillingCardsGetResponse {
  cards: IBillingCardObject[]
}

/**
 * API: GET payment/solid_gate/transaction
 */
export interface IBillingTransactionsGetResponse {
  transactions: IBillingTransactionObject[]
}

/**
 * API: POST payment/solid_gate/refund
 */
export interface IBillingRefundPostResponse {
  success: boolean
}

/**
 * API: POST payment/solid_gate/init_payment
 */
export interface IBillingInitPaymentPayload {
  productId: string
  context: {
    text: string
  }
}
export interface IBillingInitPaymentPostResponse {
  orderId: string
  formUrl: string
  successUrl: string
  failUrl: string
}

/**
 * API: POST payment/solid_gate/recurring
 */
export interface IBillingRecurringPostResponse {
  orderId: string
}

/**
 * API: POST payment/solid_gate/order_status
 */
export type IBillingOrderStatusPostResponseType = 'success' | 'processing'
export interface IBillingOrderStatusPostResponse {
  status: IBillingOrderStatusPostResponseType
}

/**
 * API: payment/solid_gate/init_payment
 */

// export interface IBillingRecurringPayload extends IBillingInitPaymentPayload {
//   cardId: string
// }

/**
 * API: POST payment/solid_gate/get_payment
 */
export interface IBillingSolidGatePaymentPostResponse {
  signature: string
  merchant: string
  paymentIntent: string
  orderId: number
}
