import { useContext } from '@nuxtjs/composition-api'
import { ModalEmits } from '../modal'
import {
  mediaSupported,
  mediaStartInit,
  mediaLocalStreamInit,
  mediaPermissionGranted,
  mediaPermissionDenied,
} from './media.state'
import { useMediaDevices } from './mediaDevices.usecase'
import { useMediaPermissions } from './mediaPermissions.usecase'
import { useMediaLocalStream } from './mediaLocalStream.usecase'
// import { useMediaTakePicture } from './mediaTakePicture.usecase'

export function useMedia() {
  const { $modalOld, $modal } = useContext()
  const { mediaDevicesIsSupport, mediaDevicesLoad } = useMediaDevices()
  const {
    mediaLocalStreamCreate,
    mediaLocalStreamRemove,
  } = useMediaLocalStream()
  const { mediaPermissionsCheck } = useMediaPermissions()
  // const {
  //   mediaTakePictureInit,
  //   mediaTakePictureReset,
  //   mediaTakePictureShot,
  // } = useMediaTakePicture()

  async function mediaInit(): Promise<boolean> {
    // Проверка на доступ
    mediaDevicesIsSupport()
    if (!mediaSupported.value) return false

    // Загрузка девайсов
    await mediaDevicesLoad()

    // Проверка старых разрешений
    mediaPermissionsCheck()

    // Флаг иницилизации
    mediaStartInit.value = true

    return true
  }

  function mediaDestroy() {
    mediaLocalStreamRemove()
    // mediaTakePictureReset()
  }

  async function mediaGetAccess(): Promise<boolean> {
    // Проверка доступа
    if (mediaModalSupport()) return false
    // Отображение окна предоставление доступа, если нужно
    await mediaModalAccess()
    // Создание локального стрима
    await mediaLocalStreamCreate()
    // Проверка доступа
    mediaPermissionsCheck()
    // Отображение окна блокировки доступа, если нужно
    if (mediaModalAccessDenied()) return false
    //
    return true
  }

  function mediaModalSupport(): boolean {
    if (!mediaSupported.value) {
      $modalOld.open(ModalEmits.mediaNotSupport)
      return true
    }
    return false
  }

  function mediaModalAccess(): Promise<void> {
    if (mediaLocalStreamInit.value || mediaPermissionGranted.value)
      return Promise.resolve()

    return new Promise((resolve: any) => {
      function emitAccessOk() {
        resolve()
      }

      $modal.onConfirm('media-allow-access', emitAccessOk)
      $modal.open('media-allow-access')
    })
  }

  function mediaModalAccessDenied(): boolean {
    if (mediaPermissionDenied.value) {
      $modalOld.open(ModalEmits.mediaDeniedAccess)
      return true
    }
    return false
  }

  return {
    mediaInit,
    mediaDestroy,
    mediaGetAccess,
    mediaPermissionsCheck,
    mediaModalSupport,
    mediaModalAccess,
    mediaModalAccessDenied,
    mediaLocalStreamCreate,
    mediaLocalStreamRemove,
    // mediaTakePictureInit,
    // mediaTakePictureReset,
    // mediaTakePictureShot,
  }
}
