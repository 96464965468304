import { ref } from '@nuxtjs/composition-api'
import {
  IDialogThreadObject,
  IDialogThreadMessageObject,
  IDialogUserCacheObject,
  IDialogThreadSelectedObject,
} from './dialog.types'

export enum DialogThreadType {
  'INITED' = 101,
  'REPLIED' = 102,
  'MUTUAL' = 105,
  'RECOMMENDATION' = 106,
  'SUPPORT' = 107,
  'VIDEO_SPRINT' = 108,
  'WOW_LIKE' = 109,
  'CONFIRMED_WOW_LIKE' = 110,
  'NOT_SENT' = 111,
  'TOP_MESSAGE_SENDER' = 112,
  'TOP_MESSAGE_RECEIVER' = 113,
}

export enum DialogThreadMessageType {
  'message' = 1,
  'gif' = 2,
  'image' = 3,
  'link' = 4,
  'location' = 5,
  'sticker' = 6,
  'ice_breaker' = 7,
  'video_message' = 8,
  'video' = 9,
  'mm' = 10,
  'gift' = 11,
  'missed_call' = 13,
  'system_info' = 14,
  'add_to_favorites' = 15,
  'favorite_request' = 19,
}
export enum DialogThreadMessageDirection {
  'from_me' = 1,
  'from_interlocutor' = 0,
}

export const THREAD_LOAD_LIMIT = 15
export const MESSAGES_LOAD_LIMIT = 20
/**
 * Global
 */
export const dialogNitification = ref(0)
export const dialogNotificationPrivateCalls = ref(0)
export const dialogPrivateCallsShow = ref(false)
export const dialogActive = ref(false)
export const dialogThreadNeedOpenId = ref(0)
export const dialogThreadNeedOpenEntry = ref('')
export const dialogUsersCache = ref<IDialogUserCacheObject[]>([]) // ????
/**
 * Threads
 */
export const dialogThreadsPageCounter = ref(1)
export const dialogThreadsLoading = ref(false)
export const dialogThreadsCanLoadMore = ref(true)
export const dialogThreads = ref<IDialogThreadObject[]>([])
/**
 * Thread
 */
export const dialogThreadSelectedId = ref(0)
export const dialogThreadSelectedObject = ref<IDialogThreadSelectedObject>()
/**
 * Messages
 */
export const dialogThreadMessagesLoading = ref(false)
export const dialogThreadMessagesCanLoadMore = ref(false)
export const dialogThreadMessages = ref<IDialogThreadMessageObject[]>([])
