import { ref } from '@nuxtjs/composition-api'
import { defineStore } from 'pinia'
import {
  VideoCallStatus,
  VideoCallTarget,
  VideoCallMessage,
  VideoCallConnectionUser,
} from '../types'

type VideoCallStoreState = {
  status: VideoCallStatus
  target: VideoCallTarget
  timerActive: boolean
  timerSec: number
  connectionUser: VideoCallConnectionUser | undefined
  messages: VideoCallMessage[]
  peerIsInit: boolean
}

const localMediaStream = ref<MediaStream>()
const remoteMediaStream = ref<MediaStream>()

export const useVideoCallStore = defineStore('videoCall', {
  state: (): VideoCallStoreState => {
    return {
      status: '',
      target: '',
      timerActive: false,
      timerSec: 0,
      connectionUser: undefined,
      messages: [],
      peerIsInit: false,
    }
  },
  getters: {
    isEmpty: (state) => state.status === '',
    localMediaStreamGet: () => () => localMediaStream.value,
    remoteSocketId: (state) => state.connectionUser?.socketId,
    peerRemoteStream: () => remoteMediaStream.value,
    peerRemoteSrc: (state) => state.connectionUser?.fakeVideoUrl,
  },
  actions: {
    reset() {
      this.status = ''
      this.target = ''
      this.timerActive = false
      this.timerSec = 0
      this.connectionUser = undefined
      this.messages = []
      this.peerIsInit = false
      localMediaStream.value = undefined
      remoteMediaStream.value = undefined
    },
    resetConnection() {
      this.status = ''
      this.connectionUser = undefined
      this.peerIsInit = false
      this.messages = []
      remoteMediaStream.value = undefined
    },
    messagePush(message: VideoCallMessage) {
      this.messages.push(message)
    },
    localMediaStreamSet(stream: MediaStream) {
      localMediaStream.value = stream
    },
    remoteMediaStreamSet(stream: MediaStream) {
      remoteMediaStream.value = stream
    },
  },
})
