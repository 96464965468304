import { useContext } from '@nuxtjs/composition-api'
import { useUser, useUserNotification } from '../usecase'
import {
  IDialogThreadMessageSocketDto,
  IDialogThreadMessageObject,
} from './dialog.types'
import {
  dialogActive,
  dialogThreadSelectedId,
  dialogThreadMessages,
} from './dialog.state'
import { DialogThreadMessageMapper } from './dialogThreadMessage.mapper'
import { useDialogThreads } from './dialogThreads.usecase'
import { useDialogThreadMessages } from './dialogThreadMessages.usecase'
import { websocket } from '@/src/shared/lib/websocket'

const TIMEOUT_NOTIFICATION_RELOAD_DELAY = 2000
const TIMEOUT_READ_RELOAD_DELAY = 2000

export function useDialogWebsocket() {
  const { dialogThreadsGetById, dialogThreadsSetById } = useDialogThreads()
  const {
    dialogThreadMessageSet,
    dialogThreadMessagesRead,
  } = useDialogThreadMessages()
  const { $analytics } = useContext()
  const { userByIdLoad } = useUser()

  let notificationTimeoutId: ReturnType<typeof setTimeout> | undefined
  let readTimeoutId: ReturnType<typeof setTimeout> | undefined
  const usersRoleCache: any = {}

  /**
   * Private methods
   */
  function threadMessageIsSending(payload: IDialogThreadMessageObject): number {
    const messageIndex = dialogThreadMessages.value.findIndex(
      (message: IDialogThreadMessageObject) => {
        if (message.status !== 'sending') return false
        return message.message === payload.message
      }
    )
    return messageIndex
  }

  function parseIncomePayload(payload: IDialogThreadMessageSocketDto) {
    let thread = dialogThreadsGetById(payload.r)
    if (!thread && payload.s) {
      thread = dialogThreadsGetById(payload.s)
    }
    if (!thread) return undefined

    const message = DialogThreadMessageMapper.socketToDomain(
      payload,
      dialogThreadSelectedId.value
    )

    // Если раздел не активный не обрабатывать
    if (!dialogActive.value) {
      // TODO Notification
      return
    }

    if (thread.interlocutorId === dialogThreadSelectedId.value) {
      const messageIndex = threadMessageIsSending(message)
      if (messageIndex === -1) {
        dialogThreadMessages.value.push(message)
      } else {
        dialogThreadMessageSet(messageIndex, message)
      }
      if (!message.fromMe) threadActiveMessagesReadStartCommand()
    } else {
      dialogThreadsSetById(thread.interlocutorId, {
        lastMessage: message.message,
        read: false,
      })
      notificationReloadStartCommand()
    }
  }

  async function analyticsReceived(payload: IDialogThreadMessageSocketDto) {
    if (!dialogActive.value) return

    const userId = payload.s.toString()
    if (!usersRoleCache[userId]) {
      const response = await userByIdLoad(payload.s)
      usersRoleCache[userId] = response?.role === 8
    }

    $analytics.event('dialogs_message_received', {
      type: payload.type === 1 ? 'text' : 'media',
      secondrole: usersRoleCache[userId] ? 'host' : 'user',
      secondid: payload.s,
    })
  }

  function notificationReloadStartCommand() {
    if (notificationTimeoutId) clearTimeout(notificationTimeoutId)
    notificationTimeoutId = setTimeout(
      () => useUserNotification(),
      TIMEOUT_NOTIFICATION_RELOAD_DELAY
    )
  }

  function threadActiveMessagesReadStartCommand() {
    if (readTimeoutId) clearTimeout(readTimeoutId)
    readTimeoutId = setTimeout(
      () => dialogThreadMessagesRead(true),
      TIMEOUT_READ_RELOAD_DELAY
    )
  }

  /**
   * Events handlers
   */

  function websocketOnReceiveText(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  function websocketOnGif(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  function websocketOnPhoto(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  function websocketOnReceiveLinkText(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
  }

  function websocketOnLocation(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
  }

  function websocketOnNewFullscreenVideo(
    payload: IDialogThreadMessageSocketDto
  ) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
  }

  function websocketOnNewVideoBubble(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
  }

  function websocketOnMassMessage(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
  }

  function websocketOnGift(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  // TODO
  function websocketOnIncomingCall(payload: IDialogThreadMessageSocketDto) {
    console.log('websocketOnIncomingCall', payload)
  }

  function websocketOnMissedCall(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  function websocketOnSystemInfo(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  function websocketOnAddToFavorites(payload: IDialogThreadMessageSocketDto) {
    notificationReloadStartCommand()
    analyticsReceived(payload)
    parseIncomePayload(payload)
  }

  function websocketOnWasRead(payload: IDialogThreadMessageSocketDto) {
    let thread = dialogThreadsGetById(payload.r)
    if (!thread && payload.s) thread = dialogThreadsGetById(payload.s)
    if (!thread) return
    dialogThreadsSetById(thread.interlocutorId, {
      lastMessageReadTimestamp: payload.ts,
    })
  }

  // TODO
  function websocketOnMutualRead(payload: any) {
    console.log('websocketOnMutualRead', payload)
  }

  /**
   * Public methods
   */

  function dialogWebsocketOn() {
    if (!websocket.connected('application')) return
    const appClient = websocket.client('application')
    appClient.on('1', websocketOnReceiveText)
    appClient.on('2', websocketOnGif)
    appClient.on('3', websocketOnPhoto)
    appClient.on('4', websocketOnReceiveLinkText)
    appClient.on('5', websocketOnLocation)
    appClient.on('8', websocketOnNewFullscreenVideo)
    appClient.on('9', websocketOnNewVideoBubble)
    appClient.on('10', websocketOnMassMessage)
    appClient.on('11', websocketOnGift)
    appClient.on('12', websocketOnIncomingCall)
    appClient.on('13', websocketOnMissedCall)
    appClient.on('14', websocketOnSystemInfo)
    appClient.on('15', websocketOnAddToFavorites)
    appClient.on('mutual_read', websocketOnMutualRead)
    appClient.on('was_read', websocketOnWasRead)
  }

  function dialogWebsocketOff() {
    if (!websocket.connected('application')) return
    const appClient = websocket.client('application')
    appClient.off('1', websocketOnReceiveText)
    appClient.off('2', websocketOnGif)
    appClient.off('3', websocketOnPhoto)
    appClient.off('4', websocketOnReceiveLinkText)
    appClient.off('5', websocketOnLocation)
    appClient.off('8', websocketOnNewFullscreenVideo)
    appClient.off('9', websocketOnNewVideoBubble)
    appClient.off('10', websocketOnMassMessage)
    appClient.off('11', websocketOnGift)
    appClient.off('12', websocketOnIncomingCall)
    appClient.off('13', websocketOnMissedCall)
    appClient.off('14', websocketOnSystemInfo)
    appClient.off('15', websocketOnAddToFavorites)
    appClient.off('mutual_read', websocketOnMutualRead)
    appClient.off('was_read', websocketOnWasRead)
  }

  return {
    dialogWebsocketOn,
    dialogWebsocketOff,
  }
}
