import { reactive } from '@nuxtjs/composition-api'
import { EVENT_BUS_TARGET } from '../configs'
import { ConfirmEmailModalOpenPayload, ConfirmEmailModalEntry } from '../types'
import { modal } from '@/src/shared/ui/modal'

type CallbackFunction = (payload: ConfirmEmailModalOpenPayload) => void

const EVENT_BUS_COMMAND = 'open'
const eventName = modal.name(EVENT_BUS_TARGET, EVENT_BUS_COMMAND)

export const state = reactive({
  openCounts: 0,
})

export const emitOpen = (payload: ConfirmEmailModalOpenPayload) =>
  modal.emit(eventName, payload)

export const onOpen = (callback: CallbackFunction) =>
  modal.on(eventName, callback)

export function open(entry: ConfirmEmailModalEntry) {
  state.openCounts++
  emitOpen({
    entry,
  })
}
