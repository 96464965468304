import { userDescriptionPost } from '@/src/shared/api'

type UserUpdateDescriptionResponse = {
  success: boolean
}

export async function updateDescription(description: string) {
  const response = await userDescriptionPost<UserUpdateDescriptionResponse>(
    description
  )
  if (!response.isOk || !response.data) return ''
  return response.data.success
}
