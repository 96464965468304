import type { WebsocketClientOptions } from './websocket.types'
import { WEBSOCKET_PLATFORM, WEBSOCKET_APPLICATION } from './websocket.config'

import { languageBrowser } from '@/logic/helpers'

import { deviceIdGet } from '@/src/shared/lib/deviceId'

export function websocketClientOptions(
  userId: number,
  accessToken: string
): WebsocketClientOptions {
  return {
    query: {
      platform: WEBSOCKET_PLATFORM,
      application: WEBSOCKET_APPLICATION,
      userId,
      accessToken,
    },
    extraHeaders: {
      'X-Device-Id': deviceIdGet(),
      'X-Application': process.env.APPLICATION,
      'X-Version-Backend': process.env.VERSION_BACKEND,
      Locale: languageBrowser(),
    },
  }
}
