// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NBKg[data-v-1675e6ba]{top:16px;left:20px;right:20px;display:flex;align-items:center;justify-content:space-between;margin-bottom:16px}.T96u[data-v-1675e6ba]{color:#918899}.T96u[data-v-1675e6ba],.\\+nfW[data-v-1675e6ba]{font-family:\"Poppins\",sans-serif;font-size:11px;font-style:normal;font-weight:400;line-height:16px;letter-spacing:-.23px}.\\+nfW[data-v-1675e6ba]{padding:4px 8px;color:#e34084;background-color:#fff;border-radius:34px}@media screen and (min-width:1024px){.NBKg[data-v-1675e6ba]{position:absolute;margin-bottom:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "NBKg",
	"comp__text": "T96u",
	"comp__timer": "+nfW"
};
module.exports = ___CSS_LOADER_EXPORT___;
