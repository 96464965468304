import { render, staticRenderFns } from "./PrivateCallModalButtonSkip.vue?vue&type=template&id=3517708a&scoped=true&"
import script from "./PrivateCallModalButtonSkip.vue?vue&type=script&lang=ts&"
export * from "./PrivateCallModalButtonSkip.vue?vue&type=script&lang=ts&"
import style0 from "./PrivateCallModalButtonSkip.vue?vue&type=style&index=0&id=3517708a&lang=scss&scoped=true&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3517708a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DialogIconCallSkip: require('/Users/tarletskiy/Documents/projects/liti/fe-monorepo/apps/peachfy-old/components/dialog/DialogIconCallSkip.vue').default})
