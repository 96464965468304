import { useContext } from '@nuxtjs/composition-api'
import { IconGift } from '../ui'
import { getRewards } from './getRewards.setup'
import { websocket } from '@/src/shared/lib/websocket'

type AlertFromServerWebsocketOneTimeRewardPayload = {
  a: {
    type: string
    coins: number
    message: string
    title: string
    subtitle: string
  }
}

const EMIT_NAME = 'one_time_reward'

export function useWebsocketOnOneTimeReward() {
  const { $toaster, app } = useContext()
  async function callback(
    payload: AlertFromServerWebsocketOneTimeRewardPayload
  ) {
    const message = app.i18n.t('daily_rewards.you_get_n_coins', {
      coins: payload.a.coins,
    }) as string
    $toaster.info({
      title: payload.a.title,
      message,
      icon: IconGift,
    })
    await getRewards()
  }
  return {
    on: () => {
      const client = websocket.client('application')
      client.on(EMIT_NAME, callback)
    },
    off: () => {
      const client = websocket.client('application')
      client.off(EMIT_NAME, callback)
    },
  }
}
