import { uploaderFiles } from '.'

export function useUploaderHelpers() {
  function uploaderResetState() {
    uploaderFiles.value = []
  }

  return {
    uploaderResetState,
  }
}
