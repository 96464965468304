import { UploaderApi } from '../repository'
// import { AuthResponseError } from '../auth/authError.service'
import { IUploaderUploadPostResponse } from './uploader.types'

export class UploaderRepository {
  protected _api: UploaderApi
  // protected _auth: AuthResponseError

  constructor() {
    this._api = new UploaderApi()
    // this._auth = new AuthResponseError()
  }

  public async avatarPost(
    file: File | Blob,
    host: string,
    publicKey: string,
    userId: number,
    callback: Function
  ) {
    let data: number = 0
    const response = await this._api.avatarPOST(
      file,
      host,
      publicKey,
      userId,
      callback
    )
    const state = this._api.parse<IUploaderUploadPostResponse>(response)
    // if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.photoId || 0
    }

    return {
      ...state,
      data,
    }
  }

  public async messagePost(
    file: File | Blob,
    host: string,
    publicKey: string,
    userId: number,
    callback: Function
  ) {
    // let data: number = 0

    const response = await this._api.messagePOST(
      file,
      host,
      publicKey,
      userId,
      callback
    )

    return {
      success: response.success,
      data: response.photoId || 0,
    }

    // const state = this._api.parse<IUploaderUploadPostResponse>(response)
    // // if (state.authError) this._auth.emit(response)
    // if (state.success) {
    //   data = state.responseData?.photoId || 0
    // }

    // return {
    //   ...state,
    //   data,
    // }
  }
}

export const uploaderRepository = new UploaderRepository()
