import { useContext } from '@nuxtjs/composition-api'
import { mediaEntity } from '@/src/entities/media'

export function useMediaInit() {
  const { $modal } = useContext()

  return async function (): Promise<boolean> {
    const store = mediaEntity.model.useMediaStore()
    if (store.startInit) {
      return true
    }
    store.startInit = true
    // Проверка на доступ
    mediaEntity.model.mediaDevicesIsSupport()
    if (!store.supported) {
      $modal.open('media-not-support')
      return false
    }
    // Загрузка девайсов
    await mediaEntity.model.mediaDevicesLoad()
    if (!store.videoInputDevices.length) {
      return false
    }
    // Проверка старых разрешений
    mediaEntity.model.mediaPermissionsCheck()
    // Флаг иницилизации
    return true
  }
}
