import { AuthGender } from '../types'
import { tokenSet } from './token.service'
import { genderCacheReset } from './genderCache.service'
import { preRegistrationPost } from '@/src/shared/api'

type AuthApiResponse = {
  userId: number
  accessToken: string
  expires: number
  refreshToken: string
}

export async function signUpAnonymous(
  gender: AuthGender,
  partnerId: number
): Promise<boolean> {
  const genderNumber = gender === 'male' ? 1 : 2
  const response = await preRegistrationPost<AuthApiResponse>(
    genderNumber,
    partnerId
  )
  genderCacheReset()
  if (response.isOk && response.data) {
    tokenSet({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresAt: response.data.expires,
    })
    return true
  }
  return false
}
