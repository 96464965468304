import {
  billingBalance,
  billingBalanceLoaded,
  billingCards,
  billingCardsLoaded,
  billingTransactionsLoaded,
  billingTransactions,
} from './billing.state'

export function useBillingHelpers() {
  function billingResetState() {
    billingBalanceLoaded.value = false
    billingBalance.value = 0
    billingCardsLoaded.value = false
    billingCards.value = []
    billingTransactionsLoaded.value = false
    billingTransactions.value = []
  }

  return {
    billingResetState,
  }
}
