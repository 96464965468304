import { tokenGet, tokenSet, tokenReset, isExpires } from './token.service'
import { api } from '@/src/shared/lib/api'
import { refreshPost } from '@/src/shared/api'

interface AuthRefreshResponse {
  accessToken: string
  refreshToken: string
  expires: number
}

export async function tokenIsValid(): Promise<boolean> {
  const authToken = tokenGet()
  if (!authToken) return false
  if (!authToken.accessToken) return false
  api.tokenSet(authToken.accessToken)
  if (!isExpires()) return true
  const response = await refreshPost<AuthRefreshResponse>(
    authToken.accessToken,
    authToken.refreshToken
  )
  if (response.isOk && response.data?.accessToken) {
    api.tokenSet(response.data.accessToken)
    tokenSet({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresAt: response.data.expires,
    })
  } else {
    api.tokenReset()
    tokenReset()
    return false
  }
  return !isExpires()
}
