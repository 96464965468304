import { googleSignIn } from './google.setup'
import { useAuthModalStore } from './modal.store'
import { tokenSet } from './token.service'
import { loginBySocialPost } from '@/src/shared/api'

type AuthApiResponse = {
  userId: number
  accessToken: string
  expires: number
  refreshToken: string
}

export async function loginByGoogle(): Promise<boolean> {
  const store = useAuthModalStore()
  store.loading = true
  store.resetApi()
  let bResult = false
  const responseSignIn = await googleSignIn()

  if (responseSignIn.type === 'error') {
    store.errorDetailPush('google', responseSignIn.error)
  } else if (responseSignIn.type === 'success') {
    const response = await loginBySocialPost<AuthApiResponse>(
      responseSignIn.id,
      3,
      responseSignIn.email
    )
    if (response.data?.error) {
      store.errorDetailPush('google', response.data?.error?.message || '')
    } else if (response.isOk && response.data) {
      tokenSet({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresAt: response.data.expires,
      })
      bResult = true
    }
  }
  store.loading = false
  return bResult
}
