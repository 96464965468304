import { videoAnswerPost } from '@/src/shared/api'
import { privateCallEntity } from '@/src/entities/private-call'

type PrivateCallApiVideoAnswerResponse = {
  message: string
  status: true
}

export async function answer(answer: boolean): Promise<boolean> {
  const store = privateCallEntity.model.usePrivateCallStore()
  const response = await videoAnswerPost<PrivateCallApiVideoAnswerResponse>(
    store.callerId,
    store.receiverId,
    answer
  )
  if (response.isOk && response.data) {
    return response.data.status
  }
  return false
}
