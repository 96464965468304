import { convertBase64ToBlob } from '@/src/shared/lib/converBase64ToBlob'
import { streamerStreamScreenshotPost } from '@/src/shared/api'
import { videoCallEntity } from '@/src/entities/video-call2'
import { mediaEntity } from '@/src/entities/media'

export function useStepScreenshot(callback?: Function) {
  const videoCallStore = videoCallEntity.model.useVideoCallStore()
  const takePicture = mediaEntity.model.useMediaTackePicture()

  takePicture.init()

  async function uploadPicture() {
    const picture = takePicture.shot()
    const file = convertBase64ToBlob(picture)
    await streamerStreamScreenshotPost(
      file,
      videoCallStore.remoteUser.userId,
      'private'
    )
  }

  videoCallEntity.model.useEventBusOnStep(function (step: any) {
    if (step.name !== 'screenshot') return
    uploadPicture()
    if (callback) callback()
  })
}
