import { authToken, AuthSocial } from '../auth'
import { randomString8Leters } from '../helpers'
import {
  IHttpAPI,
  HttpAuthRequest,
  IHttpResponse,
  HttpResponseType,
} from '../core/http'

export class AuthApi extends HttpAuthRequest implements IHttpAPI {
  protected async registrationRequest(
    formData: FormData
  ): Promise<IHttpResponse> {
    const url = this.createURL('registration')
    const response = await this.post(url, formData)
    // {
    //   accessToken: '39928f377077f8a16b05cfe2f6f59838ebd63e72'
    //   expires: 1617731753
    //   partnerId: 1
    //   refreshToken: 'ee1269b596d0589328e1502924e227a82d8bed98'
    //   userId: 1371
    // }

    if (response.type === HttpResponseType.ResponseSuccess) {
      authToken.value = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresAt: response.data.expires,
      }
      this.accessTokenSet(response.data.accessToken)
    }
    return response
  }

  public async registrationByEmailAndPasswordPOST(
    email: string,
    password: string,
    partnerId?: string,
    confirmMailing?: boolean
  ): Promise<IHttpResponse> {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('pwd', password)
    if (partnerId) formData.append('p', partnerId)
    if (confirmMailing !== undefined)
      formData.append('emailAdsEnabled', confirmMailing.toString())

    return await this.registrationRequest(formData)
  }

  public async registrationBySocialPOST(
    socialId: string,
    type: AuthSocial,
    email: string,
    avatar: string,
    name?: string,
    partnerId?: string
  ): Promise<IHttpResponse> {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('social_id', socialId)
    formData.append('type', type as any)
    formData.append('profile_photos[]', avatar)
    if (name) formData.append('name', name)
    if (partnerId) formData.append('p', partnerId)

    return await this.registrationRequest(formData)
  }

  public async registrationAutoPOST(): Promise<IHttpResponse> {
    const email = `U${randomString8Leters()}@autoreg.mili`
    const password = randomString8Leters() + '' + randomString8Leters()

    const formData = new FormData()
    formData.append('reg_type', 'auto_upgradable')
    formData.append('email', email)
    formData.append('pwd', password)

    return await this.registrationRequest(formData)
  }

  public async loginByEmailAndPasswordPOST(
    email: string,
    password: string
  ): Promise<IHttpResponse> {
    const url = this.createURL('login')

    const formData = new FormData()
    formData.append('email', email)
    formData.append('pwd', password)

    const response = await this.post(url, formData)
    // {
    //   accessToken: 'd8461fbe5952458e033993b1aada5d2bb6ebb0ce'
    //   expires: 1617731702
    //   isProfileFilled: true
    //   lookingForGender: null
    //   refreshToken: '3467552a282dc597c12946ccb2cfbedc175e3f9a'
    //   role: null
    //   userId: 1370
    // }
    if (response.type === HttpResponseType.ResponseSuccess) {
      authToken.value = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresAt: response.data.expires,
      }
      this.accessTokenSet(response.data.accessToken)
    }
    return response
  }

  public async loginBySocialPOST(
    socialId: string,
    type: AuthSocial,
    email: string
  ): Promise<IHttpResponse> {
    const url = this.createURL('soclogin')

    const formData = new FormData()
    formData.append('email', email)
    formData.append('social_id', socialId)
    switch (type) {
      case AuthSocial.facebook:
        formData.append('network', 'facebook')
        break
      case AuthSocial.google:
        formData.append('network', 'google')
        break
      case AuthSocial.snapchat:
        formData.append('network', 'snapchat')
        break
    }

    const response = await this.post(url, formData)
    // {
    //   "accessToken": "81a3395e8f5f5c7afe571a528e4b1a67bf9d39de",
    //   "userId": 1728,
    //   "expires": 1622110389,
    //   "isProfileFilled": true,
    //   "lookingForGender": null,
    //   "refreshToken": "6be8194425cd66620b9b14ffcea5a4e17501d581"
    // }
    if (response.type === HttpResponseType.ResponseSuccess) {
      authToken.value = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresAt: response.data.expires,
      }
      this.accessTokenSet(response.data.accessToken)
    }
    return response
  }

  public async loginByTokenPOST(token: string): Promise<IHttpResponse> {
    const url = this.createURL('email_login')

    const formData = {
      token,
    }

    const response = await this.post(url, formData)
    // {
    //   "accessToken": "81a3395e8f5f5c7afe571a528e4b1a67bf9d39de",
    //   "userId": 1728,
    //   "expires": 1622110389,
    //   "isProfileFilled": true,
    //   "lookingForGender": null,
    //   "refreshToken": "6be8194425cd66620b9b14ffcea5a4e17501d581"
    // }
    if (response.type === HttpResponseType.ResponseSuccess) {
      authToken.value = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresAt: response.data.expires,
      }
      this.accessTokenSet(response.data.accessToken)
    }
    return response
  }

  public async recoveryRequestPOST(email: string): Promise<IHttpResponse> {
    const url = this.createURL('recovery/request')

    const formData = new FormData()
    formData.append('email', email)

    const response = await this.post(url, formData)
    return response
  }

  // OLD
  // public async recoveryPOST(email: string): Promise<IHttpResponse> {
  //   const url = this.createURL('recovery_request')

  //   const formData = new FormData()
  //   formData.append('email', email)

  //   const response = await this.post(url, formData)
  //   return response
  // }

  public async passwordRecoveryPOST(
    token: string,
    password: string,
    newPassword: string
  ): Promise<IHttpResponse> {
    const url = this.createURL('password/recovery/' + token)

    const formData = {
      'new-pwd': password,
      'new-pwd-confirm': newPassword,
    }

    const response = await this.post(url, formData)
    return response
  }

  // public logout(): Promise<boolean> {
  //   AuthService.removeToken()
  //   ApiRequest.resetToken()
  //   return Promise.resolve(true)
  // }
}
