import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api'
import { VideoCallPayloadSocketSkip } from '../types'
import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketOnSkipCallback = (
  payload: VideoCallPayloadSocketSkip
) => void

const EMIT_NAME = 'skip'

export function useWebsocketOnSkip(callback: VideoCallWebsocketOnSkipCallback) {
  const defaultClient = websocket.client('default')
  const off = () => defaultClient.off(EMIT_NAME, callback)
  onMounted(() => defaultClient.on(EMIT_NAME, callback))
  onBeforeUnmount(() => off())
  return {
    off,
  }
}
