import { AuthSocialVendor } from '../types'
import { tokenSet, tokenReset } from './token.service'
import { loginBySocialPost } from '@/src/shared/api'
import { api } from '@/src/shared/lib/api'

type AuthApiResponse = {
  userId: number
  accessToken: string
  expires: number
  refreshToken: string
}

export async function loginBySocial(
  vendor: AuthSocialVendor,
  socialId: string,
  email: string
): Promise<api.ApiResponseState<AuthApiResponse>> {
  tokenReset()
  api.tokenReset()

  let type: 2 | 3 | 4 | 5
  switch (vendor) {
    case 'facebook':
      type = 2
      break
    case 'google':
      type = 3
      break
    case 'apple':
      type = 5
      break
  }

  if (!type) throw new Error('no_type')

  const response = await loginBySocialPost<AuthApiResponse>(
    socialId,
    type,
    email
  )

  if (response.isOk && response.data) {
    tokenSet({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresAt: response.data.expires,
    })
    api.tokenSet(response.data.accessToken)
  }
  return response
}
