import { userEntity } from '@/src/entities/user'
import { videoCallEntity } from '@/src/entities/video-call2'
import { privateCallEntity } from '@/src/entities/private-call'

export function register() {
  const userStore = userEntity.model.useUserStore()
  const privateStore = privateCallEntity.model.usePrivateCallStore()
  const videoCallStore = videoCallEntity.model.useVideoCallStore()
  const payload = videoCallEntity.model.registerPrivate(
    privateStore.remoteUserId,
    privateStore.callerId
  )
  if (userStore.me) {
    payload.userId = userStore.me.id
    payload.name = userStore.me.name || undefined
    payload.age = userStore.me.age || undefined
    payload.imageUrl = userStore.me.avatar?.src
    payload.locale = userStore.me.locale
    payload.isHost = userStore.me.role === 8
    payload.hasPurchase = userStore.me.hasPurchase
    payload.streamerStatus = userStore.me.streamerStatus
    payload.agencyId = userStore.me.agencyId
    payload.category = userStore.me.category
    payload.test_features = userStore.me.testFeatures
  }
  videoCallStore.register = payload
  return payload
}

export function registerSend(
  payload: videoCallEntity.types.VideoCallPayloadRegister
) {
  videoCallEntity.model.websocketSendRegister(payload)
}
