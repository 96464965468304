import { AxiosRequestConfig } from 'axios'

/**
 * Variables
 */

export interface IHttpRequestConfig extends AxiosRequestConfig {}
export interface IHttpUrl extends URL {}

export interface IHttpResponseErrorDetail {
  key: string
  error: string
}

export interface IHttpResponseError {
  code: number
  message?: string
  errorsDetails?: IHttpResponseErrorDetail[]
}

export enum HttpResponseType {
  'ResponseSuccess',
  'ResponseErrorValidate',
  'ResponseErrorServer',
  'ResponseErrorAuthintication',
  'ResponseErrorNotFound',
  'ResponseErrorUnknown',
}

export interface IHttpResponseBase {
  status: number
}

export interface IHttpResponseSuccess extends IHttpResponseBase {
  type: HttpResponseType.ResponseSuccess
  data: any
}
export interface IHttpResponseErrorValidate extends IHttpResponseBase {
  type: HttpResponseType.ResponseErrorValidate
  data: any
  error: IHttpResponseError
}
export interface IHttpResponseError500 extends IHttpResponseBase {
  type: HttpResponseType.ResponseErrorServer
}
export interface IHttpResponseError401 extends IHttpResponseBase {
  type: HttpResponseType.ResponseErrorAuthintication
}
export interface IHttpResponseError404 extends IHttpResponseBase {
  type: HttpResponseType.ResponseErrorNotFound
}
export interface IHttpResponseErrorUnknown {
  type: HttpResponseType.ResponseErrorUnknown
}
export type IHttpResponse =
  | IHttpResponseSuccess
  | IHttpResponseErrorValidate
  | IHttpResponseError500
  | IHttpResponseError401
  | IHttpResponseError404
  | IHttpResponseErrorUnknown

export type HttpResponseKind = IHttpResponse['type']

/**
 * Interface
 */
export interface IHttpResponseState<S> {
  success: boolean
  error: boolean
  authError: boolean
  response: IHttpResponse | undefined
  responseData: S | undefined
  responseError: IHttpResponseError | undefined
}

/**
 * Class
 */
export interface IHttpAPIValidate {
  parse(status: number, data: any): IHttpResponse
}

export interface IHttpAPI {
  accessTokenSet(token: string): void
  accessTokenReset(): void
  hostSet(host: string): void
  createURL(path: string): IHttpUrl
  get(url: IHttpUrl, config: IHttpRequestConfig): Promise<IHttpResponse>
  post(
    url: IHttpUrl,
    data: any,
    config: IHttpRequestConfig
  ): Promise<IHttpResponse>
  delete(url: IHttpUrl, config: IHttpRequestConfig): Promise<IHttpResponse>
}
