import { onBeforeMount, onUnmounted } from '@nuxtjs/composition-api'
import { useMediaStore } from './media.store'

export function mediaDevicesIsSupport(): boolean {
  const store = useMediaStore()
  store.supported =
    typeof navigator === 'object' &&
    typeof navigator.mediaDevices === 'object' &&
    typeof navigator.mediaDevices.getUserMedia === 'function'
  return store.supported
}

export async function mediaDevicesLoad(): Promise<void> {
  const store = useMediaStore()
  const devices = await navigator.mediaDevices.enumerateDevices()
  store.devices = devices
}

export function mediaDevicesChange(callback: Function) {
  const store = useMediaStore()

  function handler() {
    if (callback) callback()
  }

  onBeforeMount(() => {
    if (!store.supported) return
    navigator.mediaDevices.addEventListener('devicechange', handler)
  })

  onUnmounted(() => {
    if (!store.supported) return
    navigator.mediaDevices.removeEventListener('devicechange', handler)
  })
}

export function mediaDevicesInit() {
  mediaDevicesChange(mediaDevicesLoad)
}
