import { MODAL_NAME, MODAL_ACTION_SELECT } from '../config'
import { GiftModalOpenPayload, GiftModalSelectPayload } from '../types'
import { ModalExternalService } from '@/src/shared/lib/modal'
import { eventBus } from '@/src/shared/lib/eventBus'

class ModalService extends ModalExternalService {
  constructor() {
    super(MODAL_NAME, eventBus)
  }

  public open(payload: GiftModalOpenPayload) {
    super.open(payload)
  }

  public close() {
    super.close()
  }

  public actionOnSelect(callback: Function) {
    this.actionOn(MODAL_ACTION_SELECT, callback)
  }

  public actionOffSelect(callback: Function) {
    this.actionOff(MODAL_ACTION_SELECT, callback)
  }

  public actionSelect(payload: GiftModalSelectPayload) {
    this.action(MODAL_ACTION_SELECT, payload)
  }
}

export const modal = new ModalService()
