import { useContext } from '@nuxtjs/composition-api'
import { banCacheSet } from './userBanCache.setup'
import { websocket } from '@/src/shared/lib/websocket'

type WebsocketPayload = {
  m: string
  a: {
    reason: string
  }
}

const EMIT_NAME = 'ban'

export function useWebsocketOnBan(callback: Function) {
  const { $modal } = useContext()

  function handler(payload: WebsocketPayload) {
    banCacheSet(payload?.a.reason || payload.m || '')
    $modal.open('user-banned')
    callback()
  }

  const on = () => {
    const defaultClient = websocket.client('application')
    defaultClient.on(EMIT_NAME, handler)
  }
  const off = () => {
    const defaultClient = websocket.client('application')
    defaultClient.off(EMIT_NAME, handler)
  }

  return {
    on,
    off,
  }
}
