import { useExperimentStore } from './experiment.store'

const EXPERIMENT_NAME = '20230515_new_onboarding'
const VARIANT_BASE = 'base'
const VARIANT_A = 'new_onboarding'

export function newOnboardingGenerate(): string {
  if (process.server) return VARIANT_BASE
  const store = useExperimentStore()
  let variant = localStorage.getItem(EXPERIMENT_NAME)
  if (!variant) variant = Math.random() > 0.05 ? VARIANT_BASE : VARIANT_A
  localStorage.setItem(EXPERIMENT_NAME, variant)
  store.experimentSet(EXPERIMENT_NAME, variant)
  return variant || VARIANT_BASE
}

export function newOnboardingVariant(): string {
  if (process.server) return VARIANT_BASE
  const variant = localStorage.getItem(EXPERIMENT_NAME)
  return variant || VARIANT_BASE
}

export function newOnboardingCheck(): boolean {
  return newOnboardingVariant() === VARIANT_A
}

export function newOnboardingAnalytics() {
  return {
    [EXPERIMENT_NAME]: newOnboardingVariant(),
  }
}
