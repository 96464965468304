import { io } from 'socket.io-client'
import { WEBSOCKET_URL } from './websocket.config'
import {
  WebsocketClient,
  WebsocketClientOptions,
  WebsocketNamespaces,
} from './websocket.types'

// Factory Method
export function websocketClient(
  namespace: WebsocketNamespaces,
  options?: WebsocketClientOptions
): WebsocketClient {
  const uri = [WEBSOCKET_URL]
  if (namespace !== 'default') uri.push(namespace)
  return io(uri.join('/'), options)
}
