import { userFavoritesRequestDelete } from '@/src/shared/api'

type FriendsApiResponse = {
  success: boolean
}

export async function requestDecline(userId: number): Promise<boolean> {
  const response = await userFavoritesRequestDelete<FriendsApiResponse>(userId)
  return response.isOk && !!response.data?.success
}
