import { useContext, useRoute } from '@nuxtjs/composition-api'
import { secondRoleGet, connectionUserId } from './privateCallHelpers.setup'
import { userMe } from '@/logic/user'
import { privateCallEntity } from '@/src/entities/private-call'
import { privateCallBusyModalEntity } from '@/src/entities/private-call-busy-modal'
import { privateCallNotEnoughCoinsModalEntity } from '@/src/entities/private-call-not-enough-coins-modal'
import { privateCallOutcomeModalEntity } from '@/src/entities/private-call-outcome-modal'

type PrivateCallCreateEntry =
  | 'mainscreen'
  | 'toprofiles'
  | 'historycallsslidebar'
  | 'stories'
  | 'dialogs'
  | 'historycalls'
  | 'favorites'
  | 'profile'

type PrivateCallCreateTab =
  | 'featured'
  | 'new'
  | 'ukrainian'
  | 'latino'
  | 'asian'

export function useCreateCall() {
  const { $analytics } = useContext()
  const route = useRoute()
  const privateCallStore = privateCallEntity.model.usePrivateCallStore()

  function pathSet(entry: PrivateCallCreateEntry) {
    privateCallStore.entryPath =
      entry === 'dialogs' ||
      entry === 'historycallsslidebar' ||
      entry === 'favorites' ||
      entry === 'mainscreen'
        ? entry
        : route.value.path
  }

  return async function (
    receiverId: number,
    entry: PrivateCallCreateEntry,
    tab?: PrivateCallCreateTab
  ): Promise<boolean> {
    const callerId = userMe.value?.id
    if (!callerId) {
      return false
    }

    // api video call
    const response = await privateCallEntity.model.createCall(
      callerId,
      receiverId,
      entry === 'toprofiles' ? 'topProfile' : 'default'
    )
    if (!response) {
      return false
    }

    const remotUser = privateCallStore.remoteUser
    const avatar = remotUser?.avatar || ''

    $analytics.event('private_call_pressed', {
      secondrole: secondRoleGet(),
      secondid: connectionUserId(),
      entry,
      tab,
    })
    pathSet(entry)

    switch (response) {
      case 'busy':
      case 'offline':
        privateCallBusyModalEntity.model.modal.open({
          avatar,
          name: remotUser?.name || '',
        })
        $analytics.event('private_status_decline', {
          secondrole: secondRoleGet(),
          secondid: connectionUserId(),
          type: response,
        })
        return false
      case 'notEnoughCoins':
        privateCallNotEnoughCoinsModalEntity.model.modal.open({
          avatar,
        })
        $analytics.event('private_status_decline', {
          secondrole: secondRoleGet(),
          secondid: connectionUserId(),
          type: 'notenough',
        })
        return false
      default:
        pathSet(entry)
        privateCallOutcomeModalEntity.model.modal.open({
          avatar,
          name: remotUser?.name || '',
          age: remotUser?.age || 0,
        })
    }

    return true
  }
}
