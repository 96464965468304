import { LanguageISO } from '../types'
import { env } from '@/src/shared/config'

export interface GoogleTranslateApiAnswer {
  detectedSourceLanguage: LanguageISO
  translatedText: string
}

export interface GoogleTranslateApiResponse {
  data: {
    translations: GoogleTranslateApiAnswer[]
  }
}

export class GoogleTransalteApi {
  public messageResponseDefault(
    text: string,
    locale: string
  ): GoogleTranslateApiResponse {
    return {
      data: {
        translations: [
          {
            detectedSourceLanguage: locale as LanguageISO,
            translatedText: text,
          },
        ],
      },
    }
  }

  public async messageText(
    text: string,
    locale: string
  ): Promise<GoogleTranslateApiResponse> {
    try {
      locale = locale.replace('ua', 'uk')
      const params = [
        `key=${env.GOOGLE_TRANSLATE_KEY}`,
        `q=${encodeURIComponent(text)}`,
        `target=${locale}`,
      ].join('&')
      const uri = `https://translation.googleapis.com/language/translate/v2?${params}`
      const response = await fetch(uri, {
        method: 'POST',
      })
      if (response.status !== 200) {
        return this.messageResponseDefault(text, locale)
      }
      return (await response.json()) as GoogleTranslateApiResponse
    } catch (err) {
      return this.messageResponseDefault(text, locale)
    }
  }
}
