import { chatEvent } from './chatEvents.service'
import { websocket } from '@/src/shared/lib/websocket'

export function webscoketInfoOn() {
  const appClient = websocket.client('application')
  appClient.on('1', websocketOnReceive)
  appClient.on('2', websocketOnReceive)
  appClient.on('3', websocketOnReceive)
  appClient.on('4', websocketOnReceive)
  appClient.on('5', websocketOnReceive)
  appClient.on('8', websocketOnReceive)
  appClient.on('9', websocketOnReceive)
  appClient.on('10', websocketOnReceive)
  appClient.on('11', websocketOnReceive)
  appClient.on('12', websocketOnReceive)
  appClient.on('13', websocketOnReceive)
  appClient.on('14', websocketOnReceive)
  appClient.on('15', websocketOnReceive)
}

export function webscoketInfoOff() {
  if (!websocket.connected) return
  const appClient = websocket.client('application')

  appClient.off('1', websocketOnReceive)
  appClient.off('2', websocketOnReceive)
  appClient.off('3', websocketOnReceive)
  appClient.off('4', websocketOnReceive)
  appClient.off('5', websocketOnReceive)
  appClient.off('8', websocketOnReceive)
  appClient.off('9', websocketOnReceive)
  appClient.off('10', websocketOnReceive)
  appClient.off('11', websocketOnReceive)
  appClient.off('12', websocketOnReceive)
  appClient.off('13', websocketOnReceive)
  appClient.off('14', websocketOnReceive)
  appClient.off('15', websocketOnReceive)
}

function websocketOnReceive() {
  chatEvent.baseEmit('anyMessageReceived', undefined)
}
