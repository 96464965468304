import { v2UserGet } from '@/src/shared/api'
import { FriendsState } from '@/src/shared/types'

type UserApiResponse = {
  user: {
    favoriteState: FriendsState
  }
}

export async function userLoad(): Promise<UserApiResponse['user'] | undefined> {
  const response = await v2UserGet<UserApiResponse>()
  if (!response.isOk || !response.data) return
  return response.data?.user
}
