import { defineStore } from 'pinia'
import { ExperimentModalStore } from '../types'

export const useExperimentStore = defineStore('experiment', {
  state: (): ExperimentModalStore => ({
    loaded: false,
    experiments: {},
  }),
  getters: {
    experimentGet: (state) => (name: string) => state.experiments[name],
  },
  actions: {
    experimentSet(name: string, variant: string) {
      this.experiments[name] = variant
    },
  },
})
