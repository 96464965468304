// import { onBeforeUnmount } from '@nuxtjs/composition-api'
import { meLoad } from './meLoad.setup'
import { useUser } from '@/logic/usecase'
import { websocket } from '@/src/shared/lib/websocket'
import { userEntity } from '@/src/entities/user'

const EMIT_NAME = 'update_me'

export function useWebsocketOnUpdateMe() {
  const { userMeLoad } = useUser()

  async function callback() {
    await Promise.all([userMeLoad(true), meLoad(), userEntity.model.loadAll()])
  }

  const on = () => {
    const defaultClient = websocket.client('application')
    defaultClient.on(EMIT_NAME, callback)
  }
  const off = () => {
    const defaultClient = websocket.client('application')
    defaultClient.off(EMIT_NAME, callback)
  }

  // onBeforeUnmount(() => off())
  return {
    on,
    off,
  }
}
