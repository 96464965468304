import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketSendRegisterPayload = {
  [key: string]: any
}

export function websocketSendRegister(
  payload: VideoCallWebsocketSendRegisterPayload
): void {
  websocket.emitDefault('register', payload)
}
