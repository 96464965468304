import { IHttpAPI, HttpAppRequest, IHttpResponse } from '@/logic/core/http'
import { getCookie } from '@/logic/helpers'

const PARAM_FACEBOOK_FBP = '_fbp'
const PARAM_FACEBOOK_FBC = '_fbc'

export class FacebookApi extends HttpAppRequest implements IHttpAPI {
  public async appDataPOST(): Promise<IHttpResponse> {
    const url = this.createURL('app/fbdata')

    const formData = {
      fbp: getCookie(PARAM_FACEBOOK_FBP) || null,
      fbc: getCookie(PARAM_FACEBOOK_FBC) || null,
    }

    return await this.post(url, formData)
  }
}
