import { IHttpAPI, HttpAppRequest, IHttpResponse } from '@/logic/core/http'

export class PrivateCallApi extends HttpAppRequest implements IHttpAPI {
  async videoCallHistoryGET(): Promise<IHttpResponse> {
    const url = this.createURL('video/call_history')
    return await this.get(url)
  }

  async videoCallPOST(receiverId: number): Promise<IHttpResponse> {
    const url = this.createURL('video/call')
    const formData = {
      receiverId,
      callTag: 'default',
    }
    return await this.post(url, formData)
  }

  async videoAnswerPOST(
    callerId: number,
    receiverId: number,
    answer: boolean
  ): Promise<IHttpResponse> {
    const url = this.createURL('video/answer')
    const formData = {
      callerId,
      receiverId,
      answer,
    }
    return await this.post(url, formData)
  }
}
