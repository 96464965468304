import {
  IMediaStreamConstraints,
  MediaPermissionError,
  IMediaStreamTrack,
  IMediaTrackSettings,
  MediaServiceError,
} from '../types'
import { useMediaStore } from './media.store'
import { mediaDevicesLoad } from './mediaDevices.setup'

const mediaDefaultStreamConfig: IMediaStreamConstraints = {
  audio: true,
  video: {
    facingMode: 'user',
    width: { min: 100, ideal: 1280, max: 1920 },
    height: { min: 100, ideal: 720, max: 1080 },
    // frameRate: { ideal: 10, max: 24 },
  },
}

export async function mediaLocalStreamCreate(
  config: IMediaStreamConstraints = mediaDefaultStreamConfig
): Promise<void> {
  const store = useMediaStore()
  if (!store.supported) throw new Error(MediaServiceError.NotSupport)
  try {
    const stream = await navigator.mediaDevices.getUserMedia(config)
    await mediaDevicesLoad()
    store.localStream = stream
    store.localStreamError = MediaPermissionError.None
    store.localStreamAudioActive = true
  } catch (error: any) {
    store.localStream = undefined
    switch (error.name as MediaPermissionError) {
      case MediaPermissionError.AbortError:
      case MediaPermissionError.NotAllowedError:
      case MediaPermissionError.NotFoundError:
      case MediaPermissionError.NotReadableError:
      case MediaPermissionError.OverconstrainedError:
      case MediaPermissionError.SecurityError:
      case MediaPermissionError.TypeError:
        store.localStreamError = error.name
        break
      default:
        store.localStreamError = MediaPermissionError.Unknown
    }
  } finally {
    store.localStreamInit = true
  }
}

export function mediaLocalStreamRemove(): void {
  const store = useMediaStore()
  if (!store.localStream) return
  store.localStream
    .getTracks()
    .forEach((track: IMediaStreamTrack) => track.stop())
  store.localStream = undefined
  store.localStreamAudioActive = false
}

export function mediaLocalVideoSettings(): IMediaTrackSettings {
  const store = useMediaStore()
  if (!store.localStream) throw new Error(MediaServiceError.NotLocalStream)
  return store.localStream.getVideoTracks()[0].getSettings()
}

export function mediaLocalAudioMute(value: boolean) {
  const store = useMediaStore()
  if (!store.localStream) throw new Error(MediaServiceError.NotLocalStream)
  store.localStream
    .getAudioTracks()
    .forEach((track: IMediaStreamTrack) => (track.enabled = value))
  store.localStreamAudioActive = value
}

export function mediaLocalAudioToogle() {
  const store = useMediaStore()
  mediaLocalAudioMute(!store.localStreamAudioActive)
}
