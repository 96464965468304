import { useContext } from '@nuxtjs/composition-api'
import { statusSet } from './privateCallStatus.setup'
import { privateCallEntity } from '@/src/entities/private-call'
import { videoCallEntity } from '@/src/entities/video-call2'

export function useDialogStart() {
  const { $analytics, $facebook } = useContext()
  const store = videoCallEntity.model.useVideoCallStore()

  videoCallEntity.model.useEventBusOnDialogStart(function () {
    if (!store.remoteUser) {
      return // TODO if this error
    }
    videoCallEntity.model.timerStop()
    videoCallEntity.model.websocketSendDialogStart(store.remoteUser)
    statusSet('active')
    videoCallEntity.model.timerStart()
    // analytics
    $analytics.event('videochat_startchat_shown', {
      secondrole: videoCallEntity.model.remoteUserSecondRole(),
      secondid: videoCallEntity.model.remoteUserId(),
      type: privateCallEntity.config.ANALYTICS_TYPE,
      view: videoCallEntity.model.videoStatusGet(),
    })
    $facebook.event('ViewContent', {})
  })
}
