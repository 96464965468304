import { useStoriesStore } from './stories.store'
import { apiNext, apiRewind, apiLike, apiUnlike } from './stories.api'
// import { limitViewed } from './storiesLimit.setup'

export async function rewindStory(): Promise<void> {
  await apiRewind()
}

export async function loadStory(): Promise<void> {
  const store = useStoriesStore()
  let responseItems = await apiNext()
  if (!responseItems[0]) {
    await apiRewind()
    responseItems = await apiNext()
  }
  if (responseItems[0]) store.push(responseItems[0])
}

export async function likeToggle(liked?: boolean): Promise<boolean> {
  const store = useStoriesStore()
  const id = store.current.id
  store.toogleLike(liked)
  return liked ? await apiLike(id) : await apiUnlike(id)
}

export async function mount(): Promise<void> {
  const store = useStoriesStore()
  store.$reset()
  await rewindStory()
  await loadStory()
}

export function unmount() {
  const store = useStoriesStore()
  store.$reset()
}

export async function next(): Promise<boolean> {
  const store = useStoriesStore()
  if (!store.isNext) {
    await loadStory()
  }
  if (store.isNext) {
    store.next()
    return true
  }
  return false
}

export function prev(): void {
  const store = useStoriesStore()
  if (store.isPrev) store.prev()
}

export function muteToggle() {
  const store = useStoriesStore()
  store.muted = !store.muted
}
