import { LanguageISO } from '../types'
import { TRANSLATE_SEPERATE } from '../global'
import { GoogleTransalteApi } from '../repository'
import { languageBrowser } from '../helpers'
import { env } from '@/src/shared/config'

const googleTransalteApi = new GoogleTransalteApi()

export function useLanguge() {
  function languageBrowserGet() {
    return languageBrowser() as LanguageISO
  }

  async function languageTranslateText(
    remoteLanguage: LanguageISO,
    localLanguage: LanguageISO,
    text: string
  ): Promise<string> {
    if (!localLanguage || !remoteLanguage || remoteLanguage === localLanguage) {
      return Promise.resolve(text)
    }

    const response = await googleTransalteApi.messageText(text, remoteLanguage)
    const answer = response.data.translations[0]
    if (!env.IS_PROD) {
      console.group('languageTranslateText')
      console.log(
        'localeLocal=%s remoteLanguage=%s detectedSourceLanguage=%s',
        localLanguage,
        remoteLanguage,
        answer.detectedSourceLanguage
      )
      console.log(text)
      console.log(answer.translatedText)
      console.groupEnd()
    }

    const returnText = [text]
    if (remoteLanguage !== answer.detectedSourceLanguage) {
      returnText.push(TRANSLATE_SEPERATE)
      returnText.push(answer.translatedText)
    }
    return returnText.join('')
  }

  return {
    languageBrowserGet,
    languageTranslateText,
  }
}
