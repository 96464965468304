import { useRouter } from '@nuxtjs/composition-api'
import { WEBSOCKET_EMIT_DOUBLE, ROUTE_PATH_DOUBLE_CONNECTION } from '../config'
import { appLoading } from '@/logic/app'
import { websocket } from '@/src/shared/lib/websocket'

export function useDoubleWebsocketHandler() {
  const router = useRouter()

  function handlerDouble() {
    appLoading.value = true
    router.push(ROUTE_PATH_DOUBLE_CONNECTION)
  }

  function on() {
    if (!websocket.connected('application')) return
    const appClient = websocket.client('application')
    appClient.on(WEBSOCKET_EMIT_DOUBLE, handlerDouble)
  }

  function off() {
    if (!websocket.connected('application')) return
    const appClient = websocket.client('application')
    appClient.off(WEBSOCKET_EMIT_DOUBLE, handlerDouble)
  }

  return {
    on,
    off,
  }
}
