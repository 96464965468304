import { PrivateCallPayloadSocketPrivateAnswer } from '../types'
import { websocket } from '@/src/shared/lib/websocket'

type PrivateCallWebsocketOnPrivateAnswerCallback = (
  payload: PrivateCallPayloadSocketPrivateAnswer
) => void

const EMIT_NAME = 'private_call_answer'

export function useWebsocketOnPrivateAnswer(
  callback: PrivateCallWebsocketOnPrivateAnswerCallback
) {
  return {
    on() {
      const defaultClient = websocket.client('application')
      defaultClient.on(EMIT_NAME, callback)
    },
    off() {
      const defaultClient = websocket.client('application')
      defaultClient.off(EMIT_NAME, callback)
    },
  }
}
