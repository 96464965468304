export enum RoutingPath {
  none = '',
  login = '/auth/login/',
  loginEmail = '/auth/login-email/',
  registration = '/auth/registration/',
  registrationNext = '/auth/registration-next/',
  gender = '/auth/gender/',
  recovery = '/auth/recovery/',
  funnelLogin = '/auth/funnel/login/',
  funnelRegistration = '/auth/funnel/registration/',
  funnelRegistrationNext = '/auth/funnel/registration-next/',
  funnelFreeCoins = '/auth/funnel/free-coins/',
  main = '/cab/video-chat/',
  invoiceHistory = '/cab/invoice-history/',
  blocklist = '/cab/blocklist',
  messages = '/cab/messages/',
  privateCall = '/cab/private-call/',
  topProfiles = '/cab/top-profiles/',
  onboradingExperiment = '/auth/ex/',
  onboardingStart = '/auth/funnel/gender/',
  // terms = '/terms-and-conditions/',
  // policy = '/privacy-policy/',
  emailConfirmed = '/auth/email-confirmed/',
}
