import { authEntity } from '@/src/entities/auth'
import { userEntity } from '@/src/entities/user'

export async function autoLoginWithoutRedirect() {
  const reponseValid = await authEntity.model.tokenIsValid()
  if (!reponseValid) return false

  const meResult = await userEntity.model.meLoad()
  if (!meResult) return false

  return true
}
