import { defineStore } from 'pinia'
import {
  PrivateCallStatus,
  PrivateCallDirction,
  PrivateCallRemoteUser,
} from '../types'

type PrivateCallStoreState = {
  entryPath: string
  status: PrivateCallStatus
  direction: PrivateCallDirction
  callerId: number
  receiverId: number
  remoteUser: PrivateCallRemoteUser | undefined
}

export const usePrivateCallStore = defineStore('privateCall', {
  state: (): PrivateCallStoreState => {
    return {
      entryPath: '',
      status: '',
      direction: '',
      callerId: 0,
      receiverId: 0,
      remoteUser: undefined,
    }
  },
  getters: {
    remoteUserId: (state) =>
      state.direction === 'income' ? state.callerId : state.receiverId,
    isActive: (state) => state.status === 'active',
    isConnecting: (state) => state.status === 'connecting',
  },
  actions: {
    resetCall() {
      this.status = ''
      this.direction = ''
      this.callerId = 0
      this.receiverId = 0
      this.remoteUser = undefined
    },
  },
})
