import { IVideoCallPayloadRegister } from './videoCall.types'
import { websocket } from '@/src/shared/lib/websocket'

const INTERVAL_TIME = 3000

export function useVideoCallSearch() {
  let searchTimeoutId: ReturnType<typeof setInterval>
  let payloadCache: IVideoCallPayloadRegister | undefined

  function tickInterval() {
    websocket.emitDefault('findUser', payloadCache)
  }

  function videoCallSearchStart(payload: IVideoCallPayloadRegister) {
    payloadCache = payload
    searchTimeoutId = setInterval(tickInterval, INTERVAL_TIME)
    tickInterval()
  }

  function videoCallSearchStop() {
    if (searchTimeoutId) clearInterval(searchTimeoutId)
  }

  return {
    videoCallSearchStart,
    videoCallSearchStop,
  }
}
