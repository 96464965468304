import { useVideoCallStore } from './videoCall.store'
import { websocketSendPauseTimer } from './videoCallWsSendPauseTimer.setup'
import { websocketSendResumeTimer } from './videoCallWsSendResumeTimer.setup'
import { websocketSendStep } from './videoCallWsSendStep.setup'
import { eventBusSendStep } from './videoCallEventBusStep.setup'

const STEP_INTERVAL_TIME = 1000
let stepTimerId: ReturnType<typeof setInterval>

export function timerStart() {
  const videoCallStore = useVideoCallStore()
  timerStop()
  videoCallStore.timerSec = 0
  videoCallStore.timerActive = true
  stepTimerId = setInterval(timerTick, STEP_INTERVAL_TIME)
}

export function timerPause() {
  const videoCallStore = useVideoCallStore()
  videoCallStore.timerActive = false
  websocketSendPauseTimer()
  if (stepTimerId) clearInterval(stepTimerId)
}

export function timerResume() {
  const videoCallStore = useVideoCallStore()
  videoCallStore.timerActive = true
  websocketSendResumeTimer()
  stepTimerId = setInterval(timerTick, STEP_INTERVAL_TIME)
}

export function timerStop() {
  const videoCallStore = useVideoCallStore()
  videoCallStore.timerSec = 0
  videoCallStore.timerActive = false
  if (stepTimerId) clearInterval(stepTimerId)
}

function timerTick() {
  const videoCallStore = useVideoCallStore()
  const seconds = ++videoCallStore.timerSec
  const user = videoCallStore.remoteUser
  let steps = []
  if (user) {
    steps = user.steps.filter(
      (step: any) =>
        (step.recurring &&
          seconds - step.delay > 0 &&
          (seconds - step.delay) % step.time === 0) ||
        (!step.recurring && step.time === seconds)
    )
  }
  steps.forEach((step: any) => {
    const stepFilter = [
      'recurring',
      'private_call_init',
      'private_call_recurring',
    ]
    if (!stepFilter.includes(step.name)) {
      websocketSendStep({
        user,
        step,
      })
    }
    eventBusSendStep(step)
  })
}
