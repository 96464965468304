import { onBeforeUnmount } from '@nuxtjs/composition-api'
import { appEntity } from '@/src/entities/app'
import { videoCallEntity } from '@/src/entities/video-call2'
import { mediaEntity } from '@/src/entities/media'

type PeerCallbacks = {
  status?: (status: RTCIceConnectionState) => void
  stats?: (stats: RTCStatsReport | undefined) => void
}

export function usePeer(callbacks?: PeerCallbacks) {
  const store = videoCallEntity.model.useVideoCallStore()
  const mediaStore = mediaEntity.model.useMediaStore()
  let intervalId: any

  function remoteSocketIdGet() {
    if (!store.remoteSocketId) {
      return
    }
    return store.remoteSocketId
  }

  function rtcInit() {
    if (!mediaStore.localStream) {
      return
    }
    const oldPeer = videoCallEntity.model.peerGet()
    if (oldPeer) {
      return
    }
    const peer = videoCallEntity.model.peerCreate()

    peer.addStream(mediaStore.localStream, true)

    peer.on('onicecandidates', peerOnIceCandidates)
    peer.on('streamRemote', peerOnStreamRemote)
    if (callbacks?.status) {
      peer.on('status', callbacks.status)
    }
  }

  function peerOnIceCandidates(candidates: RTCIceCandidate[]) {
    const remoteSocketId = remoteSocketIdGet()
    if (!remoteSocketId) {
      return
    }
    candidates.forEach((candidate) => {
      const candidatePayload = {
        sdp: candidate.candidate, // Специально для ios и android
        sdpMid: candidate.sdpMid?.toString(),
        sdpMLineIndex: candidate.sdpMLineIndex?.toString(),
      }
      videoCallEntity.model.websocketSendRtc(
        remoteSocketId,
        'candidate',
        candidatePayload
      )
    })
  }

  function peerOnStreamRemote(stream: MediaStream) {
    store.remoteMediaStreamSet(stream)
  }

  async function rtcStart() {
    const peer = videoCallEntity.model.peerGet()
    if (!peer) {
      return
    }
    const description = await peer.call()
    const remoteSocketId = remoteSocketIdGet()
    if (!remoteSocketId) {
      return
    }
    videoCallEntity.model.websocketSendRtc(remoteSocketId, 'offer', description)
  }

  async function rtcOffer(
    payload: videoCallEntity.types.VideoCallPayloadSocketRTC
  ) {
    const peer = videoCallEntity.model.peerGet()
    if (!peer) {
      return
    }
    const description = await peer.answer(payload.payload)
    const remoteSocketId = remoteSocketIdGet()
    if (!remoteSocketId) {
      return
    }
    videoCallEntity.model.websocketSendRtc(
      remoteSocketId,
      'answer',
      description
    )
  }

  async function rtcCandidate(
    payload: videoCallEntity.types.VideoCallPayloadSocketRTC
  ) {
    // RTCIceCandidate
    const candidate: any = {
      candidate: payload.payload.sdp, // Специально для ios и android
      sdpMid: payload.payload.sdpMid,
      sdpMLineIndex: payload.payload.sdpMid,
    }
    const peer = videoCallEntity.model.peerGet()
    if (!peer) {
      return
    }
    await peer.addIceCandidate(candidate)
  }

  async function rtcAnswer(
    payload: videoCallEntity.types.VideoCallPayloadSocketRTC
  ) {
    const peer = videoCallEntity.model.peerGet()
    if (!peer) {
      return
    }
    await peer.accept(payload.payload)
  }

  if (callbacks?.stats) {
    intervalId = setInterval(async function () {
      if (!callbacks?.stats) return
      const peer = videoCallEntity.model.peerGet()
      if (!peer) return callbacks.stats(undefined)
      const stats = await peer.getStats()
      callbacks.stats(stats)
    }, 500)
  }

  onBeforeUnmount(() => clearInterval(intervalId))

  const appStore = appEntity.model.useAppStore()
  const iceServers = appStore.warmup.webRtc?.servers
  if (!iceServers) {
    return
  }
  videoCallEntity.model.peerOptionsSet({
    config: {
      iceServers,
    },
  })
  videoCallEntity.model.useWebsocketOnRtc(function (
    payload: videoCallEntity.types.VideoCallPayloadSocketRTC
  ) {
    const isFake = store.remoteUser?.isFake
    if (!payload.from) {
      return
    }
    if (payload.from !== store.remoteUser?.socketId) {
      return
    }
    if (isFake) {
      return
    }
    switch (payload.type) {
      case 'init':
        rtcInit()
        rtcStart()
        break
      case 'offer':
        rtcInit()
        rtcOffer(payload)
        break
      case 'candidate':
        rtcCandidate(payload)
        break
      case 'answer':
        rtcAnswer(payload)
        break
    }
  })
}
