// import {} from '../types'
import { IError, ErrorBase } from '@/src/shared/lib/error'

const ERROR_NAME = 'VIDEO_CALL'

export enum VideoCallErrorDescription {
  'unknown' = 'unknown',
  'emptyIceServers' = 'emptyIceServers',
  'noLocalMediaStream' = 'noLocalMediaStream',
  // 'alreadyRegister' = 'alreadyRegister',
  'noPeerConnection' = 'noPeerConnection',
  'noRemoteUser' = 'noRemoteUser',
}

export class VideoCallError extends ErrorBase implements IError {
  constructor(description: VideoCallErrorDescription) {
    super(ERROR_NAME, description)
  }
}
