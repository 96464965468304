import { VideoCallPayloadRegister } from '../types'
import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketSendFindUserPayload = VideoCallPayloadRegister

export function websocketSendFindUser(
  payload: VideoCallWebsocketSendFindUserPayload
): void {
  websocket.emitDefault('findUser', payload)
}
