import { dialogRepository } from '../dialog/dialog.api.usecase'
import { useUserNotification, useUploader, billingBalance } from '../usecase'
import { IDialogThreadMessageObject } from './dialog.types'
import {
  MESSAGES_LOAD_LIMIT,
  DialogThreadType,
  DialogThreadMessageType,
  dialogThreadsCanLoadMore,
  dialogThreadSelectedId,
  dialogThreadSelectedObject,
  dialogThreadMessagesLoading,
  dialogThreadMessagesCanLoadMore,
  dialogThreadMessages,
} from './dialog.state'
import { useDialogThreads } from './dialogThreads.usecase'
import { userMeId } from '@/logic/user'
import { paymentFeature } from '@/src/features/payment'

export function useDialogThreadMessages() {
  /**
   * Inject
   */
  const { dialogThreadsGetById, dialogThreadsSetById } = useDialogThreads()
  const {
    uploaderGenerateName,
    uploaderMessageImage,
    uploaderByName,
  } = useUploader()

  /**
   * Variables
   */

  /**
   * Private
   */
  function dialogThreadMessageGetIndexByName(name: string): number {
    return dialogThreadMessages.value.findIndex(
      (message: any) => message.uploadName === name
    )
  }

  /**
   * Public
   */
  function dialogThreadMessagesReset() {
    dialogThreadMessagesLoading.value = false
    dialogThreadMessagesCanLoadMore.value = true
    dialogThreadMessages.value = []
  }

  function dialogThreadMessageSet(index: number, update: any): void {
    const message = dialogThreadMessages.value[index]
    dialogThreadMessages.value.splice(index, 1, Object.assign(message, update))
  }

  async function dialogThreadMessagesLoad(
    offsetTimeMarker?: number
  ): Promise<any> {
    dialogThreadMessagesLoading.value = true
    dialogThreadMessagesCanLoadMore.value = false

    const {
      success,
      responseError,
      data,
    } = await dialogRepository.threadMessagesGet(
      dialogThreadSelectedId.value,
      offsetTimeMarker,
      MESSAGES_LOAD_LIMIT
    )

    if (success && !responseError) {
      if (offsetTimeMarker) {
        data.messages
          .reverse()
          .map((message) => dialogThreadMessages.value.unshift(message))
      } else {
        if (dialogThreadSelectedObject.value) {
          dialogThreadSelectedObject.value.unlockShow = data.unlockShow
          dialogThreadSelectedObject.value.unlockPrice = data.unlockPrice
          dialogThreadSelectedObject.value.context = data.context
        }
        dialogThreadMessages.value = data.messages
      }
    }

    dialogThreadMessagesCanLoadMore.value = true
    dialogThreadMessagesLoading.value = false
  }

  async function dialogThreadMessagesLoadMore(): Promise<void> {
    if (
      dialogThreadMessagesLoading.value ||
      !dialogThreadMessagesCanLoadMore.value
    )
      return undefined
    const message = dialogThreadMessages.value[0]
    await dialogThreadMessagesLoad(message.timestamp)
    // root.$nextTick(() => (dialogThreadMessagesLoading.value = false))
  }

  async function dialogThreadMessagesRead(
    bWithoutNotification: boolean = false
  ): Promise<void> {
    const userId = dialogThreadSelectedId.value
    const thread = dialogThreadsGetById(userId)
    const { success } = await dialogRepository.threadMessageReadPost(userId)
    if (success && thread) {
      if (
        thread.type === DialogThreadType.INITED ||
        thread.type === DialogThreadType.REPLIED
      ) {
        dialogThreadsSetById(userId, { read: true })
        if (!bWithoutNotification) useUserNotification()
      }
    }
  }

  async function dialogThreadMessageSendText(message: string) {
    const userId = dialogThreadSelectedId.value
    const thread = dialogThreadSelectedObject.value
    if (!userId || !thread) return

    const messageIndex = dialogThreadMessages.value.length
    const messageObj: IDialogThreadMessageObject = {
      type: DialogThreadMessageType.message,
      timestamp: Date.now(),
      status: 'sending',
      fromMe: true,
      message,
      translate: '',
      data: {},
    }
    dialogThreadMessages.value.push(messageObj)

    const { success, data } = await dialogRepository.threadMessageTextSendPost(
      userId,
      message
    )
    // Check exists select id
    if (!dialogThreadSelectedId.value) return

    if (success && data) {
      messageObj.timestamp = data.timestamp
      messageObj.status = 'delivered'
      messageObj.translate = data.translate || ''
      dialogThreadMessages.value.splice(messageIndex, 1, messageObj)
    }
  }

  async function dialogThreadMessageSendGiftRequest(
    id: number,
    url: string,
    userId: number
  ): Promise<'no_enough_money' | 'error' | 'success'> {
    const messageIndex = dialogThreadMessages.value.length
    const messageObj: IDialogThreadMessageObject = {
      type: DialogThreadMessageType.gift,
      timestamp: Date.now(),
      status: 'sending',
      fromMe: true,
      message: 'gift',
      translate: '',
      data: {
        url,
      },
    }

    const {
      success,
      error,
      responseError,
      data,
    } = await dialogRepository.threadMessageGiftSendPost(userId, id)

    // Check exists select id
    if (!dialogThreadSelectedId.value) return 'error'

    if (error && responseError?.code === 3001) {
      dialogThreadMessages.value.splice(messageIndex, 1)
      return 'no_enough_money'
    } else if (success && data) {
      messageObj.timestamp = data?.timestamp
      messageObj.status = 'delivered'
      dialogThreadMessages.value.splice(messageIndex, 1, messageObj)
      return 'success'
    }

    return 'error'
  }

  async function dialogThreadMessageSendGift(
    id: number,
    url: string,
    price: number
  ) {
    if (!dialogThreadSelectedId.value) return
    dialogThreadsCanLoadMore.value = false

    if (billingBalance.value < price) {
      paymentFeature.open('messagesgift')
      return
    }

    const result = await dialogThreadMessageSendGiftRequest(
      id,
      url,
      dialogThreadSelectedId.value
    )

    if (result === 'no_enough_money') {
      paymentFeature.open('messagesgift')
    }
    dialogThreadsCanLoadMore.value = true
  }

  async function dialogThreadMessageSendImage(file: File) {
    const userId = dialogThreadSelectedId.value
    if (!userId) throw new Error('no userId')
    if (!userMeId.value) throw new Error('no userMeId')

    const name = uploaderGenerateName('message-image-')

    const messageObj: IDialogThreadMessageObject = {
      type: DialogThreadMessageType.image,
      timestamp: Date.now(),
      status: 'processing',
      fromMe: true,
      message: 'sent an image',
      translate: '',
      uploadName: name,
      data: {},
    }
    dialogThreadMessages.value.push(messageObj)

    const response = await uploaderMessageImage(name, file, userMeId.value)
    const fileId = response?.id
    if (!fileId) throw new Error('no response.id')

    // Check exists select id
    if (!dialogThreadSelectedId.value) return

    const messageIndex = dialogThreadMessageGetIndexByName(name)
    if (messageIndex === -1) throw new Error('no messageIndex')
    dialogThreadMessageSet(messageIndex, { status: 'sending' })
    const { success, data } = await dialogRepository.threadMessageImageSendPost(
      userId,
      fileId
    )
    // Check exists select id
    if (!dialogThreadSelectedId.value) return

    if (success && data) {
      dialogThreadMessageSet(messageIndex, {
        timestamp: data.timestamp,
        status: 'delivered',
        data: data.data,
      })
    }
  }

  function dialogThreadMessageSendImageProgress(name: string): number {
    return uploaderByName(name)?.progress || 0
  }

  return {
    dialogThreadMessagesReset,
    dialogThreadMessageSet,
    dialogThreadMessagesLoad,
    dialogThreadMessagesLoadMore,
    dialogThreadMessagesRead,
    dialogThreadMessageSendText,
    dialogThreadMessageSendGift,
    dialogThreadMessageSendImage,
    dialogThreadMessageSendImageProgress,
  }
}
