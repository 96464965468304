import Peer from 'peer-lite'
import { ref } from '@nuxtjs/composition-api'
import { defineStore } from 'pinia'
import {
  VideoCallStatus,
  VideoCallTarget,
  VideoCallMessage,
  VideoCallRemoteUser,
  VideoCallPeerOptions,
  VideoCallPayloadRegister,
  VideoCallGenderFilter,
} from '../types'

type VideoCallStoreState = {
  status: VideoCallStatus
  target: VideoCallTarget
  timerActive: boolean
  timerSec: number
  remoteUser: VideoCallRemoteUser | undefined
  messages: VideoCallMessage[]
  videoStatus: '' | 'normal' | 'black'
  register: VideoCallPayloadRegister | undefined
  peerOptions: VideoCallPeerOptions | undefined
  blur: boolean
  genderFilter: VideoCallGenderFilter
}

const remoteMediaStream = ref<MediaStream>()
const peer = ref<Peer>()

export const useVideoCallStore = defineStore('videoCall2', {
  state: (): VideoCallStoreState => {
    return {
      status: '',
      target: '',
      timerActive: false,
      timerSec: 0,
      remoteUser: undefined,
      messages: [],
      videoStatus: '',
      register: undefined,
      peerOptions: undefined,
      blur: true,
      genderFilter: 'both',
    }
  },
  getters: {
    isActive: (state) => state.status === 'active',
    isEmpty: (state) => state.status === '',
    remoteSocketId: (state) => state.remoteUser?.socketId,
    peerRemoteStream: () => remoteMediaStream.value,
    peerRemoteSrc: (state) => state.remoteUser?.fakeVideoUrl,
    peerGet: () => () => peer.value,
    remoteInFavorite: (state) => state.remoteUser?.isFavorite || false,
  },
  actions: {
    reset() {
      this.status = ''
      this.target = ''
      this.timerActive = false
      this.timerSec = 0
      this.remoteUser = undefined
      this.messages = []
      this.videoStatus = ''
      this.register = undefined
      this.peerOptions = undefined
      this.blur = true
      remoteMediaStream.value = undefined
    },
    resetConnection() {
      this.status = ''
      this.remoteUser = undefined
      this.messages = []
      this.videoStatus = ''
      this.blur = true
      remoteMediaStream.value = undefined
    },
    messagePush(message: VideoCallMessage) {
      this.messages.push(message)
    },
    remoteMediaStreamSet(stream: MediaStream) {
      remoteMediaStream.value = stream
    },
    peerSet(value: Peer) {
      peer.value = value
    },
    peerReset() {
      peer.value = undefined
    },
  },
})
