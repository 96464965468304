import { FavoritesVideochatStatus } from '../config'
import { FavoritesItem, FavoritesGender, FavoritesStatus } from '../types'
import { useFavoritesStore } from './favorites.store'
import { LanguageISO } from '@/src/shared/types'
import { userFavoritesGet } from '@/src/shared/api'

type FavoritesApiResponseUserDto = {
  id: number
  age: number
  avatar: any
  gender: string
  genderValue: number
  name: string
  role: number
  roles: string[]
  locationCountryCode: LanguageISO
  videochatStatus: FavoritesVideochatStatus
}

type FavoritesApiResponse = {
  users: FavoritesApiResponseUserDto[]
}

function favoritesMapper(dto: FavoritesApiResponseUserDto): FavoritesItem {
  let gender: FavoritesGender = 'male'
  if (dto.gender === 'Famale') gender = 'female'

  let status: FavoritesStatus = ''
  switch (dto.videochatStatus) {
    case 1:
      status = 'available'
      break
    case 2:
    case 3:
      status = 'incall'
      break
    case 4:
      status = 'offline'
      break
  }

  return {
    id: dto.id,
    age: dto.age,
    avatar: dto.avatar?.urlS || '',
    name: dto.name,
    gender,
    locale: dto.locationCountryCode?.toUpperCase() as any,
    status,
  }
}

export async function load(): Promise<void> {
  const favoritesStore = useFavoritesStore()

  const response = await userFavoritesGet<FavoritesApiResponse>()
  if (response.isOk && response.data?.users) {
    response.data?.users
      .map((item) => favoritesMapper(item))
      .forEach((item) => favoritesStore.itemPush(item))
  }

  favoritesStore.loaded = true
}
