import { useUserStore } from './user.store'

export function roleGet() {
  const me = useUserStore().me
  if (!me) return ''
  if (me.role === null && me.isTest === false) {
    return 'user'
  } else if (me.role === 8 && me.isTest === false) {
    return 'host'
  } else if (me.role === null && me.isTest === true) {
    return 'testuser'
  } else if (me.role === 8 && me.isTest === true) {
    return 'testhost'
  }
  return ''
}
