import { useContext, useRoute } from '@nuxtjs/composition-api'
import {
  appLoading,
  mediaStartInit,
  mediaLocalStream,
  useMedia,
} from '../usecase'
import { ModalEmits } from '../modal'
import {
  privateCallsLoaded,
  privateCalls,
  IPrivateCallRemoteUserObject,
  privateCallEntryPath,
  privateCallStatus,
  privateCallDirection,
  privateCallCallerId,
  privateCallReceiverId,
  privateCallRemoteUser,
} from '../privateCall'
import { privateCallRepository } from '../privateCall/privateCall.api.usecase'
import { billingBalance } from '../billing'
import { userMe } from '../user'
import { useRouting, RoutingPath } from '@/logic/routing'
import { ROUTE_NAV } from '@/src/shared/config'

// export const privateCallApi = new PrivateCallApi()

export function usePrivateCall() {
  const route = useRoute()
  const routing = useRouting()
  const { $analytics, $modalOld } = useContext()
  const { mediaInit, mediaGetAccess, mediaDestroy } = useMedia()

  /**
   * Private functions
   */
  async function privateCallApiCreate(callerId: number, receiverId: number) {
    privateCallReset()

    const {
      success,
      data,
      response,
    } = await privateCallRepository.videoCallPost(receiverId)

    if (success && data) {
      privateCallCallerId.value = callerId
      privateCallReceiverId.value = receiverId
      const remoteUser: IPrivateCallRemoteUserObject = {
        id: data?.user.id || 0,
        name: data?.user.name || '',
        avatar: data?.user.avatar?.urlS || '',
        age: data?.user.age || 0,
        isHost: data?.user.isHost || false,
      }
      privateCallRemoteUser.value = remoteUser
      privateCallDirection.value = 'outcome'
      privateCallStatus.value = 'calling'
      return data
    }

    throw new Error((response?.type as any) as string)
  }

  /**
   * Public functions
   */
  async function privateCallsLoad(): Promise<boolean> {
    const { success, data } = await privateCallRepository.historyGet()

    privateCallsLoaded.value = true
    if (success && data) {
      privateCalls.value = data
      return true
    }
    privateCalls.value = []
    return false
  }

  function privateCallReset() {
    privateCallStatus.value = ''
    privateCallDirection.value = ''
    privateCallCallerId.value = 0
    privateCallReceiverId.value = 0
    privateCallRemoteUser.value = undefined
  }

  async function privateCallMediaCheck(): Promise<boolean> {
    if (!mediaStartInit.value) {
      const initResponse = await mediaInit()
      if (!initResponse) return false
    }
    if (!mediaLocalStream.value) {
      const accessResponse = await mediaGetAccess()
      if (!accessResponse) return false
    }
    return true
  }

  async function privateCallCreateCall(
    receiverId: number,
    entry: string
  ): Promise<void> {
    const callerId = userMe.value?.id
    if (!callerId) throw new Error('no callerId')

    appLoading.value = true

    // Media check
    const mediaCheck = await privateCallMediaCheck()
    if (!mediaCheck) {
      appLoading.value = false
      return
    }

    const responce = await privateCallApiCreate(callerId, receiverId)
    if (!responce) {
      appLoading.value = false
      throw new Error('privateCallApiCreate return undefined')
    }

    const remotUserObj = privateCallRemoteUser.value
    const avatar = remotUserObj?.avatar

    $analytics.event('private_call_pressed', {
      secondrole: remotUserObj?.isHost ? 'host' : 'user',
      secondid: remotUserObj?.id,
      entry,
    })

    privateCallEntryPath.value =
      entry === 'dialogs' ||
      entry === 'historycallsslidebar' ||
      entry === 'favorites' ||
      entry === 'mainscreen'
        ? entry
        : route.value.path

    switch (responce.status) {
      case 'busy':
        $modalOld.open(ModalEmits.privateReceiverBusy, {
          avatar,
          userName: remotUserObj?.name,
        })
        mediaDestroy()
        $analytics.event('private_status_decline', {
          secondrole: remotUserObj?.isHost ? 'host' : 'user',
          secondid: remotUserObj?.id,
          type: 'busy',
        })
        break
      case 'offline':
        $modalOld.open(ModalEmits.privateReceiverBusy, {
          avatar,
          userName: remotUserObj?.name,
        })
        $analytics.event('private_status_decline', {
          secondrole: remotUserObj?.isHost ? 'host' : 'user',
          secondid: remotUserObj?.id,
          type: 'offline',
        })
        mediaDestroy()
        break
      case 'notEnoughCoins':
        $modalOld.open(ModalEmits.privateNotEnoughCoins, {
          avatar,
          coins: billingBalance.value,
        })
        $analytics.event('private_status_decline', {
          secondrole: remotUserObj?.isHost ? 'host' : 'user',
          secondid: remotUserObj?.id,
          type: 'notenough',
        })
        mediaDestroy()
        break
      default:
        privateCallEntryPath.value =
          entry === 'dialogs' ||
          entry === 'historycallsslidebar' ||
          entry === 'favorites' ||
          entry === 'mainscreen'
            ? entry
            : route.value.path

        $modalOld.open(ModalEmits.privateOutcomeCall, {
          avatar,
          userName: remotUserObj?.name,
          age: remotUserObj?.age,
        })
    }
    appLoading.value = false
  }

  async function privateCallAnswer(answer: boolean) {
    appLoading.value = true

    const { success, data } = await privateCallRepository.videoAnswerPost(
      privateCallCallerId.value,
      privateCallReceiverId.value,
      answer
    )
    if (success && data) {
      privateCallStatus.value = answer ? 'calling' : ''
    }
    // else {
    //   appLoading.value = false
    //   throw new Error('privateCallAnswer error')
    // }

    appLoading.value = false
  }

  function privateCallToPage() {
    if (route.value.path !== ROUTE_NAV.privateCall) {
      routing.pushPath(RoutingPath.privateCall)
    }
    $modalOld.close(ModalEmits.privateIncomeCall)
    $modalOld.close(ModalEmits.privateOutcomeCall)
  }

  function privateCallToMessages() {
    if (route.value.path === ROUTE_NAV.privateCall) {
      routing.replacePath(RoutingPath.messages)
    } else if (route.value.path !== ROUTE_NAV.messages) {
      routing.pushPath(RoutingPath.messages)
    }
  }

  function privateCallOnRequest(payload: any) {
    const remoteUser: IPrivateCallRemoteUserObject = {
      id: payload.a.user.id,
      name: payload.a.user.name,
      avatar: payload.a.user.avatar?.urlS,
      age: payload.a.user.age,
      isHost: payload.a.user.isHost,
    }
    privateCallReset()
    privateCallRemoteUser.value = remoteUser
    privateCallCallerId.value = payload.s
    privateCallReceiverId.value = payload.r
    privateCallDirection.value = 'income'
    privateCallStatus.value = 'calling'
    $modalOld.open(ModalEmits.privateIncomeCall, remoteUser)
  }

  function privateCallOnAnswer(payload: any) {
    if (!payload.a.answer) {
      $modalOld.close(ModalEmits.privateIncomeCall)
      $modalOld.close(ModalEmits.privateOutcomeCall)
      privateCallReset()
      return
    }
    privateCallStatus.value = 'calling'
    privateCallToPage()
  }

  return {
    privateCallReset,
    privateCallsLoad,
    privateCallCreateCall,
    privateCallAnswer,
    privateCallToPage,
    privateCallToMessages,
    privateCallOnRequest,
    privateCallOnAnswer,
    privateCallMediaCheck,
  }
}
