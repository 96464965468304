import { userMe, userPreRemoveReasons } from '../user'

export function useUserHelpers() {
  function userResetState() {
    userMe.value = undefined
    userPreRemoveReasons.value = []
  }

  return {
    userResetState,
  }
}
