import { useUserStore } from './user.store'
import { userCountersGet } from '@/src/shared/api'

type UserApiCountersResponse = {
  hearts: number
  messages: number
  missedCalls: number
  notifications: number
}

export async function countersLoad(): Promise<number> {
  const store = useUserStore()
  const response = await userCountersGet<UserApiCountersResponse>()
  if (response.isOk && response.data) {
    store.counterMessages = response.data.messages || 0
    return store.counterMessages
  }
  return 0
}
