import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketSendProlongPermissionPayload = {
  socketId: string
  callId: string
}

export function websocketSendProlongPermission(
  payload: VideoCallWebsocketSendProlongPermissionPayload
): void {
  websocket.emitDefault('prolongPermission', payload)
}
