import { IError, ErrorBase } from '@/src/shared/lib/error'

const ERROR_NAME = 'Websocket'

export enum WebsocketErrorDescription {
  'unknown' = 'unknown',
  'noClient' = 'socket client not found',
}

export class WebsocketError extends ErrorBase implements IError {
  constructor(description: WebsocketErrorDescription) {
    super(ERROR_NAME, description, false)
  }
}
