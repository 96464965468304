import { defineStore } from 'pinia'
import { FriendsItem } from '../types'

type FriendsStoreState = {
  loaded: boolean
  selected: boolean
  items: FriendsItem[]
}

export const useFriendsStore = defineStore('friends', {
  state: (): FriendsStoreState => {
    return {
      loaded: false,
      selected: false,
      items: [],
    }
  },
  getters: {
    isEmpty: (state) => state.loaded && !state.items.length,
    isList: (state) => state.loaded && !!state.items.length,
  },
  actions: {
    itemPush(item: FriendsItem) {
      this.items.push(item)
    },
  },
})
