import { ref } from '@nuxtjs/composition-api'
import {
  RandomCallGenderFilter,
  RandomCallScreen,
  RandomCallStatus,
} from './randomCall.types'

export const ANALYTICS_TYPE = 'random'
export const COINS_FOR_RANDOM = 10
// export const COINS_FOR_PROLONG = 60
// export const TIME_RANDOM_SEC = 20
// export const TIME_PROLONG_SEC = 60
// export const TIME_WAIT_PURCHASE_SEC = 20
export const TIME_WAIT_PAYMENT_SEC = 60

export const randomCallScreen = ref<RandomCallScreen>('main') // main / call
export const randomCallGenderFilter = ref<RandomCallGenderFilter>('women') // woman / men / both

export const randomCallStatus = ref<RandomCallStatus>('none')
