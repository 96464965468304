import { sendiosEmailConfirmPost } from '@/src/shared/api'

type ApiResponse = {
  success: boolean
}

export async function emailConfirm(): Promise<boolean> {
  const response = await sendiosEmailConfirmPost<ApiResponse>()
  return response.isOk && response.data.success
}
