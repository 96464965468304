import {
  VideoCallPayloadSocketUser,
  VideoCallPayloadSocketRTC,
  VideoCallPayloadSocketSkip,
  VideoCallMessage,
} from '../types'
import { videoCallEvent } from './videoCallEvents.service'
import { useVideoCallStore } from './videoCall.store'
import {
  peerAnswerAccept,
  peerIceCandidateAdd,
  peerInit,
  peerDestroy,
  peerOfferCreate,
  peerAnswerCreate,
} from './videoCallPeer.setup'
import { websocket } from '@/src/shared/lib/websocket'

export function webscoketOn() {
  const defaultClient = websocket.client('default')

  defaultClient.on('user', websocketOnUser)
  defaultClient.on('rtc', websocketOnRTC)
  defaultClient.on('skip', websocketOnSkip)
  defaultClient.on('message', websocketOnMessage)
  defaultClient.on('disconnect', websocketOnDisconnect)
  defaultClient.on('noFunds', websocketOnNofunds)
}

export function webscoketOff() {
  if (!websocket.connected) return
  const defaultClient = websocket.client('default')

  defaultClient.off('user', websocketOnUser)
  defaultClient.off('rtc', websocketOnRTC)
  defaultClient.off('skip', websocketOnSkip)
  defaultClient.off('message', websocketOnMessage)
  defaultClient.off('disconnect', websocketOnDisconnect)
  defaultClient.off('noFunds', websocketOnNofunds)
}

function websocketOnUser(payload: VideoCallPayloadSocketUser) {
  const videoCallStore = useVideoCallStore()
  videoCallStore.connectionUser = payload
  videoCallStore.status = 'connecting'
  videoCallEvent.baseEmit<VideoCallPayloadSocketUser>('userReceived', payload)
}

async function websocketOnRTC(payload: VideoCallPayloadSocketRTC) {
  const videoCallStore = useVideoCallStore()
  if (!payload.from) return undefined
  if (payload.from !== videoCallStore.connectionUser?.socketId) return undefined
  switch (payload.type) {
    case 'init':
      peerDestroy()
      if (videoCallStore.connectionUser?.isFake) return remoteIsFakeStart()
      peerInit()
      await peerOfferCreate()
      break
    case 'offer':
      peerDestroy()
      if (videoCallStore.connectionUser?.isFake) return remoteIsFakeStart()
      peerInit()
      await peerAnswerCreate(payload.payload)
      break
    case 'candidate':
      peerIceCandidateAdd({
        candidate: payload.payload.sdp, // Специально для ios и android
        sdpMid: payload.payload.sdpMid,
        sdpMLineIndex: payload.payload.sdpMid,
      } as RTCIceCandidate)
      break
    case 'answer':
      peerAnswerAccept(payload.payload)
      break
  }
}

function remoteIsFakeStart() {
  const videoCallStore = useVideoCallStore()
  videoCallStore.status = 'connected'
  videoCallEvent.baseEmit('remoteUserFakeReceived', undefined)
}

function websocketOnSkip(payload: VideoCallPayloadSocketSkip) {
  //
  videoCallEvent.baseEmit<VideoCallPayloadSocketSkip>('skipReceived', payload)
}

function websocketOnMessage(payload: VideoCallMessage) {
  const videoCallStore = useVideoCallStore()
  if (videoCallStore.status !== 'active') return
  videoCallStore.messagePush(payload)
  videoCallEvent.baseEmit<VideoCallMessage>('messageReceived', payload)
}

function websocketOnDisconnect() {
  videoCallEvent.baseEmit('disconnectReceived', undefined)
}

function websocketOnNofunds() {
  videoCallEvent.baseEmit('noFundsReceived', undefined)
}
