import { StoriesItem, StoriesStatus } from '../types'
import {
  videoStoryQueueNextGet,
  videoStoryQueueRewindPost,
  videoStoryLikePost,
  videoStoryLikeDelete,
} from '@/src/shared/api'

// videoStoryQueueNextGet
type StoriesApiUserAvatar = {
  id: number
  urlB?: string
  urlF?: string
  urlM?: string
  urlO?: string
  urlS?: string
  urlT?: string
}

type StoriesApiNextResponseItem = {
  id: number
  url: string
  preview: {
    url: string
  }
  likesCount: number
  liked: boolean
  user: {
    id: number
    name: string
    age: number
    locationCountryCode: string
    favorite: boolean
    host: boolean
    fake: boolean
    online: boolean
    inCall: boolean
    videochatStatus: number
    avatar: StoriesApiUserAvatar
    userState: 1 | 2 | 3 | 4 // 1 - AVAILABLE, 2 - BUSY, 3 - OFFLINE, 4 - BACKGROUND
  }
}

type StoriesApiNextResponse = {
  items: StoriesApiNextResponseItem[]
}
export async function apiNext(): Promise<StoriesItem[]> {
  const response = await videoStoryQueueNextGet<StoriesApiNextResponse>()
  if (!response.isOk || !response.data?.items) return []
  const items = response.data?.items
  const parsed = items.map(
    (story): StoriesItem => {
      let status: StoriesStatus = 'offline'
      switch (story.user?.userState) {
        case 1:
          status = 'available'
          break
        case 2:
          status = 'busy'
          break
        case 3:
          status = 'offline'
          break
        case 4:
          status = 'background'
          break
      }

      return {
        id: story.id,
        video: story.url,
        preview: story.preview?.url,
        liked: story.liked,
        userId: story.user?.id,
        avatar: story.user?.avatar?.urlS || '',
        name: story.user?.name,
        status,
        age:
          typeof story.user?.age === 'string'
            ? parseInt(story.user?.age)
            : story.user?.age,
      }
    }
  )
  return parsed
}

// videoStoryQueueRewindPost
type StoriesRewindResponse = {
  success: boolean
}
export async function apiRewind(): Promise<boolean> {
  const response = await videoStoryQueueRewindPost<StoriesRewindResponse>()
  return response.data?.success || false
}

// videoStoryLikeDelete
type StoriesLikeResponse = {
  success: boolean
}
export async function apiLike(id: number): Promise<boolean> {
  const response = await videoStoryLikePost<StoriesLikeResponse>(id)
  return response.data?.success || false
}

// videoStoryLikeDelete
type StoriesUnlikeResponse = {
  success: boolean
}
export async function apiUnlike(id: number): Promise<boolean> {
  const response = await videoStoryLikeDelete<StoriesUnlikeResponse>(id)
  return response.data?.success || false
}
