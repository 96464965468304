import { useExperimentStore } from './experiment.store'

const EXPERIMENT_NAME = '20230328_cheap_bundles'
const VARIANT_BASE = 'base'
const VARIANT_A = 'cheap_bundles'

export function cheapBundlesExists() {
  const store = useExperimentStore()
  return !!store.experimentGet(EXPERIMENT_NAME)
}

export function cheapBundlesVariant(): string {
  const store = useExperimentStore()
  return store.experimentGet(EXPERIMENT_NAME) || VARIANT_BASE
}

export function cheapBundlesCheckBase(): boolean {
  return cheapBundlesVariant() === VARIANT_BASE
}

export function cheapBundlesCheckA(): boolean {
  return cheapBundlesVariant() === VARIANT_A
}
