import { useContext } from '@nuxtjs/composition-api'
import { mediaEntity } from '@/src/entities/media'

export function useMediaGetPermission() {
  const { $analytics, $modal } = useContext()
  const store = mediaEntity.model.useMediaStore()

  function modalAccess(): Promise<void> {
    const store = mediaEntity.model.useMediaStore()
    if (store.localStreamInit || store.permissionGranted)
      return Promise.resolve()
    store.permissionShow = true
    return new Promise((resolve: Function) => {
      $modal.onConfirm('media-allow-access', () => {
        resolve()
        $modal.off('media-allow-access', 'confirm')
      })
      $modal.open('media-allow-access')
    })
  }

  async function getAccess(): Promise<void> {
    // Відображення вікна запросу на доступ, якщо потрібно
    await modalAccess()
    // Створення локального стріма
    await mediaEntity.model.mediaLocalStreamCreate()
    // Перевірка доступа
    mediaEntity.model.mediaPermissionsCheck()
  }

  return async function (): Promise<boolean> {
    // Перевірка чи була ініцізована медія
    if (!store.startInit) {
      return false
    }
    // Перевірка на блокування
    if (store.permissionDenied) {
      $modal.open('media-denied-access')
      // mediaDeniedAccessModalEntity.model.modal.open()
      return false
    }
    // Запрос на доступи до камери і мікрафону
    const bAccessAnalytics = store.permissionGranted
    await getAccess()
    if (!bAccessAnalytics && store.permissionShow) {
      const permission =
        store.permissionGranted && !store.permissionDenied ? 'allow' : 'denied'
      $analytics.event('videochat_access_pressed', {
        type: 'camera',
        button: permission,
      })
    }
    if (store.permissionDenied) {
      $modal.open('media-denied-access')
      return false
    }
    if (!store.videoInputDevices.length) {
      return false
    }
    return true
  }
}
