// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Waxl[data-v-8d65dc02]{display:flex;align-items:center;justify-content:center;width:72px;height:72px;-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer;border-radius:50%;background-color:#58e751;box-shadow:0 0 0 0 rgba(88,231,81,.9)}.Waxl[data-v-8d65dc02]:hover{background-color:rgba(88,231,81,.7);animation:none}@keyframes g-i0{0%{box-shadow:0 0 0 0 rgba(88,231,81,.9)}70%{box-shadow:0 0 0 12px rgba(88,231,81,0)}to{box-shadow:0 0 0 0 rgba(88,231,81,0)}}@media screen and (min-width:1024px){.Waxl[data-v-8d65dc02]{transition:.3s;animation:g-i0 1.5s infinite}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "Waxl",
	"pulse-data-v-8d65dc02": "g-i0"
};
module.exports = ___CSS_LOADER_EXPORT___;
