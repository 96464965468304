import { useExperimentStore } from './experiment.store'
import { userExperimentGet } from '@/src/shared/api'
import { IAnalytics } from '@/src/shared/lib/analytics'

type ExperimentApiResponse = {
  experiments: {
    [key: string]: {
      identifier: string
      variant: string
    }
  }
}

export async function fetch() {
  const store = useExperimentStore()
  if (store.loaded) return
  const response = await userExperimentGet<ExperimentApiResponse>()
  store.loaded = true
  if (response.isOk && response.data?.experiments) {
    Object.entries(response.data.experiments).forEach(([name, value]) =>
      store.experimentSet(name, value.variant)
    )
  }
}

export function analyticsPropsGet() {
  const store = useExperimentStore()
  if (!store.loaded) return
  const props = store.experiments
  if (!Object.keys(props).length) return
  return props
}

export function analyticsProps($analytics: IAnalytics) {
  const store = useExperimentStore()
  if (!store.loaded) return
  const props = store.experiments
  if (!Object.keys(props).length) return
  $analytics.userProps(props)
}
