import { onBeforeMount, onUnmounted } from '@nuxtjs/composition-api'
import { mediaSupported, mediaDevices } from './media.state'

export function useMediaDevices() {
  function mediaDevicesIsSupport(): boolean {
    mediaSupported.value =
      typeof navigator === 'object' &&
      typeof navigator.mediaDevices === 'object' &&
      typeof navigator.mediaDevices.getUserMedia === 'function'
    return mediaSupported.value
  }

  async function mediaDevicesLoad(): Promise<void> {
    const devices = await navigator.mediaDevices.enumerateDevices()
    mediaDevices.value = devices
  }

  function mediaDevicesInit() {
    onBeforeMount(() => {
      if (mediaSupported.value) return
      navigator.mediaDevices.addEventListener('devicechange', mediaDevicesLoad)
    })

    onUnmounted(() => {
      if (mediaSupported.value) return
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        mediaDevicesLoad
      )
    })
  }

  return {
    mediaDevicesInit,
    mediaDevicesIsSupport,
    mediaDevicesLoad,
  }
}
