import { useVideoCallStore } from './videoCall.store'
import { videochatStepPost } from '@/src/shared/api'

type VideoCallStepResponse = {
  success: boolean
}

export async function videoCallRequestStep(step: any): Promise<boolean> {
  const videoCallStore = useVideoCallStore()
  const user = videoCallStore.remoteUser
  const response = await videochatStepPost<VideoCallStepResponse>(step, user)
  return response.isOk && response.data?.success === true
}
