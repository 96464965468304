import { IHttpAPI, HttpAppRequest, IHttpResponse } from '../core/http'
import { DialogThreadMessageType, DialogThreadType } from '../dialog'

export class DialogApi extends HttpAppRequest implements IHttpAPI {
  async threadsGET(page?: number, limit?: number): Promise<IHttpResponse> {
    const url = this.createURL('messages')
    if (page) url.searchParams.set('page', page.toString())
    if (limit) url.searchParams.set('limit', limit.toString())
    return await this.get(url)
  }

  async threadMessagesGET(
    userId: number,
    timestamp?: number,
    limit?: number
  ): Promise<IHttpResponse> {
    const url = this.createURL('messages/thread')
    url.searchParams.set('user_id', userId.toString())
    if (timestamp) url.searchParams.set('ts', timestamp.toString())
    if (limit) url.searchParams.set('limit', limit.toString())
    return await this.get(url)
  }

  async threadMessageTextSendPOST(
    userId: number,
    message: string
  ): Promise<IHttpResponse> {
    const url = this.createURL('messages/thread')

    const formData = new FormData()
    formData.append('user_id', userId.toString())
    formData.append('text', message)
    formData.append('type', DialogThreadMessageType.message.toString())

    return await this.post(url, formData)
  }

  async threadMessageImageSendPOST(
    userId: number,
    id: number
    // url: string
  ): Promise<IHttpResponse> {
    const url = this.createURL('messages/thread')

    const formData = new FormData()
    formData.append('user_id', userId.toString())
    formData.append('type', DialogThreadMessageType.image.toString())
    formData.append('a', id.toString())

    return await this.post(url, formData)
  }

  async threadMessageGiftSendPOST(
    userId: number,
    id: number
  ): Promise<IHttpResponse> {
    const url = this.createURL('messages/thread')

    const formData = new FormData()
    formData.append('user_id', userId.toString())
    formData.append('type', DialogThreadMessageType.gift.toString())
    formData.append('a', id.toString())

    return await this.post(url, formData)
  }

  async threadMessageReadPOST(userId: number): Promise<IHttpResponse> {
    const url = this.createURL('messages/read')

    const formData = new FormData()
    formData.append('user_id', userId.toString())

    return await this.post(url, formData)
  }

  async threadUnlockPOST(userId: number): Promise<IHttpResponse> {
    const url = this.createURL('messages/thread_unlock')

    const formData = {
      userId,
    }

    return await this.post(url, formData)
  }

  async threadDELETE(
    userId: number,
    threadType: DialogThreadType
  ): Promise<IHttpResponse> {
    const url = this.createURL('messages')
    url.searchParams.set('ids[]', userId.toString())
    url.searchParams.set('type', threadType.toString())

    return await this.delete(url)
  }
}
