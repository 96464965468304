import { useExperimentStore } from './experiment.store'

const EXPERIMENT_NAME = '20230307_premium_white'
const VARIANT_BASE = 'base'
// const VARIANT_A = 'premium_white'
// const VARIANT_B = 'premium_white_email'

export function premiumWhiteExists() {
  const store = useExperimentStore()
  return !!store.experimentGet(EXPERIMENT_NAME)
}

export function premiumWhiteVariant(): string {
  const store = useExperimentStore()
  return store.experimentGet(EXPERIMENT_NAME) || VARIANT_BASE
}

export function premiumWhiteCheck(): boolean {
  return premiumWhiteVariant() !== VARIANT_BASE
}
