import { EVENTBUS_CONTEXT, EVENTBUS_COMMAND_BASE } from '../config'
import { VideoCallEventBusTarget } from '../types'
import { IEventBus, eventBus } from '@/src/shared/lib/eventBus'

export class VideoCallEvent {
  protected _eventBus: IEventBus

  constructor(eventBus: IEventBus) {
    this._eventBus = eventBus
  }

  public on(target: string, command: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(
      EVENTBUS_CONTEXT,
      target,
      command
    )
    this._eventBus.on(emitName, callback)
  }

  public off(target: string, command: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(
      EVENTBUS_CONTEXT,
      target,
      command
    )
    this._eventBus.off(emitName, callback)
  }

  public one(target: string, command: string, callback: Function) {
    const emitName = this._eventBus.nameGenerate(
      EVENTBUS_CONTEXT,
      target,
      command
    )
    this._eventBus.one(emitName, callback)
  }

  public emit<P>(target: string, command: string, payload: P) {
    const emitName = this._eventBus.nameGenerate(
      EVENTBUS_CONTEXT,
      target,
      command
    )
    this._eventBus.emit(emitName, payload)
  }

  public baseOn(target: VideoCallEventBusTarget, callback: Function): void {
    this.on(target, EVENTBUS_COMMAND_BASE, callback)
  }

  public baseOff(target: VideoCallEventBusTarget, callback: Function): void {
    this.off(target, EVENTBUS_COMMAND_BASE, callback)
  }

  public baseOne(target: VideoCallEventBusTarget, callback: Function): void {
    this.one(target, EVENTBUS_COMMAND_BASE, callback)
  }

  public baseEmit<P>(target: VideoCallEventBusTarget, payload: P): void {
    this.emit<P>(target, EVENTBUS_COMMAND_BASE, payload)
  }
}

export const videoCallEvent = new VideoCallEvent(eventBus)
