import { PrivateCallApi } from '../repository'
import { AuthResponseError } from '../auth/authError.service'
import {
  IPrivateCallsVideoHistoryGetResponse,
  IPrivateCallObject,
  IPrivateCallVideoCallPostResponse,
  IPrivateCallVideoAnswerPostResponse,
} from './privateCall.types'
import { PrivateCallMapper } from './privateCall.mapper'

export class PrivateCallRepository {
  protected _api: PrivateCallApi
  protected _auth: AuthResponseError

  constructor() {
    this._api = new PrivateCallApi()
    this._auth = new AuthResponseError()
  }

  public async historyGet() {
    let data: IPrivateCallObject[] = []
    const response = await this._api.videoCallHistoryGET()
    const state = this._api.parse<IPrivateCallsVideoHistoryGetResponse>(
      response
    )
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = PrivateCallMapper.apiToDomains(state.responseData?.items || [])
    }

    return {
      ...state,
      data,
    }
  }

  public async videoCallPost(receiverId: number) {
    let data: IPrivateCallVideoCallPostResponse | undefined
    const response = await this._api.videoCallPOST(receiverId)
    const state = this._api.parse<IPrivateCallVideoCallPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData
    }

    return {
      ...state,
      data,
    }
  }

  public async videoAnswerPost(
    callerId: number,
    receiverId: number,
    answer: boolean
  ) {
    let data: IPrivateCallVideoAnswerPostResponse | undefined
    const response = await this._api.videoAnswerPOST(
      callerId,
      receiverId,
      answer
    )
    const state = this._api.parse<IPrivateCallVideoAnswerPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData
    }

    return {
      ...state,
      data,
    }
  }
}

export const privateCallRepository = new PrivateCallRepository()
