import { ref } from '@nuxtjs/composition-api'
import { IUploaderFile } from '@/logic/uploader'

export enum UploaderType {
  'THREAD' = 1,
  'USER_PHOTO' = 2,
  'PANTOMIME_PHOTO' = 3,
  'STREAMER_VERIFY_PHOTO' = 4,
  'STREAM_VERIFY_PHOTO' = 5,
}

export const uploaderFiles = ref<IUploaderFile[]>([])
