import { WarmupApi } from '../repository'
import { AuthResponseError } from '../auth/authError.service'
import { IWebappWarmupGetResponse, IAppWarmup } from './app.types'

export class AppRepository {
  protected _api: WarmupApi
  protected _auth: AuthResponseError

  constructor() {
    this._api = new WarmupApi()
    this._auth = new AuthResponseError()
  }

  public async warmupGet() {
    let data: IAppWarmup | undefined
    const response = await this._api.fetchGET()
    const state = this._api.parse<IWebappWarmupGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData
    }

    return {
      ...state,
      data,
    }
  }
}

export const appRepository = new AppRepository()
