import { userDeclineRequestsDelete } from '@/src/shared/api'

type FriendsApiResponse = {
  success: boolean
}

export async function requestDeclineAll(): Promise<boolean> {
  const response = await userDeclineRequestsDelete<FriendsApiResponse>()
  return response.isOk && !!response.data?.success
}
