import { IPrivateCallDto, IPrivateCallObject } from './privateCall.types'
import { UserAvatarMapper, UserGenderMapper } from '@/logic/user'

export class PrivateCallMapper {
  public static apiToDomain(dto: IPrivateCallDto): IPrivateCallObject {
    return {
      id: dto.user.id,
      name: dto.user.name,
      avatar: UserAvatarMapper.dtoToBigString(dto.user.avatar),
      online: dto.online,
      type: dto.type,
      gender: UserGenderMapper.numberToString(dto.user.genderValue),
      age: dto.user.age,
    }
  }

  public static apiToDomains(dtoArr: IPrivateCallDto[]): IPrivateCallObject[] {
    return dtoArr.map((dto) => this.apiToDomain(dto))
  }
}
