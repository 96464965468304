import { useVideoCallStore } from './videoCall.store'
import { userFavoritesRequestPut } from '@/src/shared/api'

type FriendsApiResponse = {
  success: boolean
}

export async function friendsRequestAccept(): Promise<boolean> {
  const store = useVideoCallStore()
  if (!store.remoteUser) return false
  let userId = store.remoteUser.userId
  userId = typeof userId === 'string' ? parseInt(userId) : userId
  const response = await userFavoritesRequestPut<FriendsApiResponse>(userId)

  if (response.isOk && response.data?.success) {
    store.remoteUser.favoriteState = 3
    return true
  }
  return false
}
