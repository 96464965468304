export enum FavoritesVideochatStatus {
  STATUS_AVAILABLE = 1,
  STATUS_BUSY = 2,
  STATUS_WAITING = 3,
  STATUS_OFFLINE = 4,
  STATUS_UNAVAILABLE = 5,
}

export enum FavoritesContext {
  videoCall = 1,
  profile = 2,
}
