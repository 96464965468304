import { userBlacklistPost } from '@/src/shared/api'

type BlocklistApiUserBlacklistPostResponse = {
  success: boolean
}

export async function create(blocklistId: number): Promise<boolean> {
  const response = await userBlacklistPost<BlocklistApiUserBlacklistPostResponse>(
    blocklistId
  )
  return response.isOk && (response.data?.success || false)
}
