import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketSendMessagePayload = {
  socketId: string
  message: string
  callId: string
}

export function websocketSendMessage(
  payload: VideoCallWebsocketSendMessagePayload
): void {
  websocket.emitDefault('sendMessage', payload)
}
