import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api'
import { EVENTBUS_CONTEXT, EVENTBUS_COMMAND_BASE } from '../config'
import { eventBus } from '@/src/shared/lib/eventBus'

type VideoCallEventBusOnPremiumCallback = () => void

const EVENTBUS_TARGET = 'premium'

function generateTopicName(): string {
  return eventBus.nameGenerate(
    EVENTBUS_CONTEXT,
    EVENTBUS_TARGET,
    EVENTBUS_COMMAND_BASE
  )
}

export function useEventBusOnPremium(
  callback: VideoCallEventBusOnPremiumCallback
) {
  const topicName = generateTopicName()
  const off = () => eventBus.off(topicName, callback)
  onMounted(() => eventBus.on(topicName, callback))
  onBeforeUnmount(() => off())
  return {
    off,
  }
}

export function eventBusSendPremium() {
  const topicName = generateTopicName()
  eventBus.emit(topicName)
}
