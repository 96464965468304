import { UserPhoto } from '../types'
import { userPhotosGet } from '@/src/shared/api'

type UserPhotoItemResponse = {
  id: number
  urlS: string
  order: number
  isAvatar: false
}

type UserApiResponse = {
  photos: UserPhotoItemResponse[]
}

function mapUserPhotos(array: UserPhotoItemResponse[]): UserPhoto[] {
  return array.map((item: UserPhotoItemResponse) => ({
    id: item.id,
    isAvatar: item.isAvatar,
    src: item.urlS,
    loading: false,
    order: item.order,
  }))
}

export async function getPhotos(): Promise<UserPhoto[]> {
  const response = await userPhotosGet<UserApiResponse>()
  if (!response.isOk || !response.data) return []
  return mapUserPhotos(response.data.photos)
}
