import { useAuthModalStore } from './modal.store'
import { tokenSet } from './token.service'
import { loginPost } from '@/src/shared/api'

type AuthApiLoginResponse = {
  accessToken: string
  expires: number
  refreshToken: string
  userId: number
}

export async function loginByEmailAndPassword(): Promise<boolean> {
  const store = useAuthModalStore()
  store.loading = true
  store.resetApi()
  const response = await loginPost<AuthApiLoginResponse>(
    store.email,
    store.password
  )
  const error = response.error || response.data?.error
  if (error) {
    if (error?.errorsDetails) {
      // TODO
    } else if (error?.message) {
      store.errorDetailPush('email', error.message)
    }
  } else if (response.isOk && response.data) {
    tokenSet({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresAt: response.data.expires,
    })
  }
  store.loading = false
  return response.isOk
}
