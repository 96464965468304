import { videoCallEntity } from '@/src/entities/video-call2'
import { privateCallEntity } from '@/src/entities/private-call'

export function useDialogEnd(callback: Function) {
  const store = videoCallEntity.model.useVideoCallStore()

  videoCallEntity.model.useWebsocketOnDisconnect(function () {
    if (!store.remoteUser) {
      return
    }
    videoCallEntity.model.timerStop()
    store.resetConnection()
    callback()
  })
}

export function dialogEnd() {
  const privateStore = privateCallEntity.model.usePrivateCallStore()
  const videoStore = videoCallEntity.model.useVideoCallStore()
  if (videoStore.register) {
    videoCallEntity.model.websocketSendUnregister()
  }
  videoCallEntity.model.peerDestroy()
  videoCallEntity.model.timerStop()
  privateStore.$reset()
  videoStore.reset()
}
