import { EVENT_BUS_TARGET } from '../configs'
import { modal } from '@/src/shared/ui/modal'

type CallbackFunction = () => void

const EVENT_BUS_COMMAND = 'close'
const eventName = modal.name(EVENT_BUS_TARGET, EVENT_BUS_COMMAND)

export const emitClose = () => modal.emit(eventName, undefined)

export const onClose = (callback: CallbackFunction) =>
  modal.on(eventName, callback)

export const oneClose = (callback: CallbackFunction) =>
  modal.one(eventName, callback)

export const offClose = (callback: CallbackFunction) =>
  modal.off(eventName, callback)
