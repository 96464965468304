import { StorageCache } from '@/logic/core/storage'
import { UserGender } from '@/logic/user'

const NAME_CACHE = 'userCache'

interface IRegistrationCache {
  gender?: string
}

export class UserCache extends StorageCache<IRegistrationCache> {
  constructor() {
    super('local', NAME_CACHE, {})
  }

  public cacheClear() {
    this.cache = {}
  }

  public get gender(): UserGender {
    return this.cache.gender as UserGender
  }

  public set gender(value: UserGender) {
    this.cache.gender = value as string
    this.cacheSave()
  }
}
