import { IRouter, IRouting } from './routing.types'
import { RoutingPath } from './routing.vars'

// TODO Найти решение для to
export class Routing implements IRouting {
  protected _router: IRouter
  protected _currentPath: RoutingPath

  constructor(router: IRouter) {
    this._router = router
    this._currentPath = RoutingPath.none
  }

  public get currentPath(): RoutingPath {
    return this._currentPath
  }

  public set currentPath(path: RoutingPath) {
    this._currentPath = path
  }

  public pushPath(path: RoutingPath): boolean {
    // if (this._currentPath === path && path !== '/cab/private-call/')
    //   return false
    // this._currentPath = path
    this._router.push(path)
    return true
  }

  public replacePath(path: RoutingPath): boolean {
    // if (this._currentPath === path) return false
    // this._currentPath = path
    this._router.replace(path)
    return true
  }
}
