// import { useContext } from '@nuxtjs/composition-api'
import { getRewards } from './getRewards.setup'
import { websocket } from '@/src/shared/lib/websocket'
// type AlertFromServerWebsocketOnDailyPayload = {
//   a: {
//     type: string
//     coins: number
//     message: string
//   }
// }

const EMIT_NAME = 'daily_reward'

export function useWebsocketOnDailyReward() {
  async function callback() {
    await getRewards()
  }
  return {
    on: () => {
      const client = websocket.client('application')
      client.on(EMIT_NAME, callback)
    },
    off: () => {
      const client = websocket.client('application')
      client.off(EMIT_NAME, callback)
    },
  }
}
