import { useAuthModalStore } from './modal.store'
import { recoveryRequestPost } from '@/src/shared/api'

export async function recoveryRequest(): Promise<boolean> {
  const store = useAuthModalStore()
  store.loading = true
  store.resetApi()
  const response = await recoveryRequestPost(store.email)
  if (response.isError && response.error?.message) {
    store.errorDetailPush('email', response.error.message)
  }
  store.loading = false
  return response.isOk
}
