import { AxiosRequestConfig } from 'axios'
import { UploaderType } from '../uploader'
import { IHttpAPI, HttpUploaderRequest, IHttpResponse } from '../core/http'
import { threadAttachmentImagePost } from '@/src/shared/api'

export class UploaderApi extends HttpUploaderRequest implements IHttpAPI {
  protected formData(
    file: File | Blob,
    publicKey: string,
    userId: number,
    type: string
  ): FormData {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('size', file.size.toString())
    formData.append('pk', publicKey)
    formData.append('app', process.env.UPLOAD_APP as string)
    formData.append('type', type)
    formData.append('app_name', process.env.UPLOAD_APP_NAME as string)
    formData.append('source', 'client')
    formData.append('user_id', userId.toString())
    return formData
  }

  protected config(onUploadProgress: Function): AxiosRequestConfig {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      transformRequest: (data, headers) => {
        delete headers['X-Device-Id']
        delete headers['X-Application']
        delete headers.Locale
        delete headers.Authorization
        delete headers.common.Authorization
        delete headers.Locale
        return data
      },
      onUploadProgress(event: ProgressEvent) {
        const percents = Math.ceil((event.loaded / event.total) * 100)
        onUploadProgress(percents > 100 ? 100 : percents)
      },
    }
  }

  public async messagePOST(
    file: File | Blob,
    host: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publicKey: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userId: number,
    onUploadProgress: Function
  ): Promise<any> {
    this.hostSet(host.replace(/\/$/, ''))
    // const url = this.createURL('upload')
    // const formData = this.formData(
    //   file,
    //   publicKey,
    //   userId,
    //   UploaderType.THREAD.toString()
    // )
    const axiosConfig = this.config(onUploadProgress)
    delete axiosConfig.transformRequest

    const response = await threadAttachmentImagePost<any>(file, axiosConfig)

    return {
      success: response.isOk && response.data?.success === true,
      photoId: response.isOk && response.data?.attachmentImage?.id,
    }
    // return await this.post(url, formData, axiosConfig)
  }

  public async avatarPOST(
    file: File | Blob,
    host: string,
    publicKey: string,
    userId: number,
    onUploadProgress: Function
  ): Promise<IHttpResponse> {
    this.hostSet(host.replace(/\/$/, ''))
    const url = this.createURL('upload')
    const formData = this.formData(
      file,
      publicKey,
      userId,
      UploaderType.USER_PHOTO.toString()
    )
    const axiosConfig = this.config(onUploadProgress)
    return await this.post(url, formData, axiosConfig)
  }
}
