import { userGet } from '@/src/shared/api'

type ApiResponse = {
  user: any
}

export async function userLoadById(userId: number) {
  const response = await userGet<ApiResponse>(userId)
  return response
}
