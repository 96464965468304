import { AuthToken } from '../types'

const AUTH_TOKEN_CACHE = 'authToken'

export function tokenGet(): AuthToken | undefined {
  if (process.server) return
  const authToken = localStorage.getItem(AUTH_TOKEN_CACHE)
  if (!authToken) return
  return JSON.parse(authToken)
}

export function tokenSet(authToken: AuthToken) {
  if (process.server) return
  localStorage.setItem(AUTH_TOKEN_CACHE, JSON.stringify(authToken))
}

export function tokenReset() {
  if (process.server) return
  localStorage.removeItem(AUTH_TOKEN_CACHE)
}

export function accessTokenGet(): string | undefined {
  const authToken = tokenGet()
  return authToken?.accessToken
}

export function refreshTokenGet(): string | undefined {
  const authToken = tokenGet()
  return authToken?.refreshToken
}

export function expiresAtGet(): number | undefined {
  const authToken = tokenGet()
  return authToken?.expiresAt || Date.now() / 1000 - 1
}

export function isExpires(): boolean {
  const expiresAt = expiresAtGet() || 0
  const unixSec = Math.ceil(Date.now() / 1000)
  return expiresAt < unixSec
}
