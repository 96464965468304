import { useUserStore } from './user.store'
import { userPost } from '@/src/shared/api'

const SESSION_VIDEOCHAT_RULES = 'videochat-rules'

export async function approvedTerms(): Promise<boolean> {
  const response = await userPost({
    approvedTerms: true,
  })
  return response.isOk
}

export function approvedTermsCheck(): boolean {
  const store = useUserStore()
  return !!store.me?.approvedTerms
}

export function approvedVideochatRules(): boolean {
  // const response = await userPost({
  //   approvedVideochatRules: true,
  // })
  // return response.isOk
  sessionStorage.setItem(SESSION_VIDEOCHAT_RULES, 'true')
  return true
}

export function approvedVideochatRulesCheck(): boolean {
  return sessionStorage.getItem(SESSION_VIDEOCHAT_RULES) === 'true'
}
