import { MediaPermissionError, IMediaDevice } from '../types'
import { useMediaStore } from './media.store'

export function mediaPermissionsDevicesCheck(): boolean {
  const store = useMediaStore()
  let bCheck: boolean = true
  store.devices.forEach((device: IMediaDevice) => {
    if (device.kind === 'audioinput' && !device.label) bCheck = false
    if (device.kind === 'videoinput' && !device.label) bCheck = false
  })
  store.permissionGranted = bCheck
  return bCheck
}

export function mediaPermissionsStreamCheck(): boolean {
  const store = useMediaStore()
  if (store.localStreamError !== MediaPermissionError.None) {
    store.permissionGranted = false
    store.permissionDenied = true
    return false
  } else {
    store.permissionGranted = true
    store.permissionDenied = false
  }
  return true
}

export function mediaPermissionsCheck(): boolean {
  const store = useMediaStore()
  if (store.localStreamInit) {
    return mediaPermissionsStreamCheck()
  }
  return mediaPermissionsDevicesCheck()
}
