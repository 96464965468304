import { useUser } from '../usecase'
import { dialogRepository } from '../dialog/dialog.api.usecase'
import { IDialogThreadSelectedObject } from './dialog.types'
import {
  dialogThreadsCanLoadMore,
  dialogThreadMessagesLoading,
  dialogThreadSelectedId,
  dialogThreadSelectedObject,
} from './dialog.state'
import { useDialogThreads } from './dialogThreads.usecase'
import { useDialogThreadMessages } from './dialogThreadMessages.usecase'

import { userEntity } from '@/src/entities/user'
import { friendsEntity } from '@/src/entities/friends'

export function useDialogThread() {
  const { userByIdLoad } = useUser()
  /**
   * Inject
   */
  const {
    dialogThreadsGetById,
    dialogThreadsRemoveById,
    dialogThreadsAddFromUser,
  } = useDialogThreads()
  const {
    dialogThreadMessagesReset,
    dialogThreadMessagesLoad,
    dialogThreadMessagesRead,
  } = useDialogThreadMessages()

  /**
   * Variables
   */

  /**
   * Private
   */
  function dialogThreadSelectedGenerateDefault(): IDialogThreadSelectedObject {
    return {
      loading: true,
      userId: 0,
      type: undefined,
      online: false,
      name: '',
      age: 0,
      avatar: undefined,
      gender: undefined,
      locale: undefined,
      isHost: false,
      createdAt: 0,
      lastMessageReadTimestamp: 0,
      unlockShow: false,
      unlockPrice: 0,
      favoriteState: 0,
    }
  }

  /**
   * Public
   */
  function dialogThreadReset() {
    dialogThreadSelectedId.value = 0
    dialogThreadSelectedObject.value = undefined
  }

  function dialogThreadSelectCheckId(userId: number): boolean {
    return dialogThreadSelectedId.value === userId
  }

  async function dialogThreadSelectById(userId: number) {
    dialogThreadMessagesLoading.value = true
    dialogThreadSelectedId.value = userId
    dialogThreadSelectedObject.value = dialogThreadSelectedGenerateDefault()
    const thread = dialogThreadSelectedObject.value
    const threadForList = dialogThreadsGetById(userId)
    thread.userId = userId
    if (threadForList) {
      if (threadForList.age) thread.age = threadForList.age
      if (threadForList.online) thread.online = threadForList.online
      if (threadForList.name) thread.name = threadForList.name
      if (threadForList.type) thread.type = threadForList.type
      if (threadForList.gender) thread.gender = threadForList.gender
      if (threadForList.avatar) thread.avatar = threadForList.avatar
      if (threadForList.timestamp) thread.createdAt = threadForList.timestamp
    }
    const user = await userByIdLoad(userId)
    const userFriend = await userEntity.model.userLoad()

    // Check exists select id
    if (!dialogThreadSelectedId.value) return
    if (user) {
      if (user.isOnline) thread.online = user.isOnline
      if (user.locale) thread.locale = user.locale
      if (user.name) thread.name = user.name
      if (user.gender) thread.gender = user.gender
      if (user.avatar?.urlS) thread.avatar = user.avatar.urlS
      if (user.role) thread.isHost = user.role === 8
      if (user.age) thread.age = user.age
    }
    if (userFriend) {
      thread.favoriteState = userFriend.favoriteState
    }

    dialogThreadMessagesReset()

    // Check exists select id
    if (!dialogThreadSelectedId.value) return
    await dialogThreadMessagesLoad()

    //
    if (!dialogThreadSelectedId.value) return
    await dialogThreadMessagesRead()

    // TODO Remove after API ready
    if (user) dialogThreadsAddFromUser(user)
    //

    //
    if (!dialogThreadSelectedId.value) return
    thread.loading = false
    dialogThreadMessagesLoading.value = false
  }

  async function dialogThreadDeleteById() {
    const userId = dialogThreadSelectedId.value
    const threadType = dialogThreadSelectedObject.value?.type
    if (!userId || !threadType) return
    dialogThreadsCanLoadMore.value = false
    const { success } = await dialogRepository.threadDelete(userId, threadType)
    if (success) {
      dialogThreadReset()
      dialogThreadMessagesReset()
      dialogThreadsRemoveById(userId)
      await friendsEntity.model.deleteFromFriends(userId)
    }
    dialogThreadsCanLoadMore.value = true
  }

  async function dialogThreadUnlockById() {
    const userId = dialogThreadSelectedId.value
    if (!userId) return
    dialogThreadsCanLoadMore.value = false
    const { success } = await dialogRepository.threadUnlock(userId)
    if (success) {
      if (dialogThreadSelectedObject.value) {
        dialogThreadSelectedObject.value.unlockShow = false
      }
    }
    dialogThreadsCanLoadMore.value = true
  }

  return {
    dialogThreadReset,
    dialogThreadSelectCheckId,
    dialogThreadSelectById,
    dialogThreadUnlockById,
    dialogThreadDeleteById,
  }
}
