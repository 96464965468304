import {
  mediaSupported,
  mediaLocalStream,
  mediaLocalStreamInit,
  mediaLocalStreamError,
  mediaLocalStreamAudioActive,
} from './media.state'
import {
  IMediaStreamConstraints,
  MediaPermissionError,
  IMediaStreamTrack,
  IMediaTrackSettings,
  MediaServiceError,
} from './media.types'
import { useMediaDevices } from './mediaDevices.usecase'

export function useMediaLocalStream() {
  const { mediaDevicesLoad } = useMediaDevices()

  const mediaDefaultStreamConfig: IMediaStreamConstraints = {
    audio: true,
    video: {
      facingMode: 'user',
      width: { min: 100, ideal: 1280, max: 1920 },
      height: { min: 100, ideal: 720, max: 1080 },
      // frameRate: { ideal: 10, max: 24 },
    },
  }

  async function mediaLocalStreamCreate(
    config: IMediaStreamConstraints = mediaDefaultStreamConfig
  ) {
    if (!mediaSupported.value) throw new Error(MediaServiceError.NotSupport)
    try {
      const stream = await navigator.mediaDevices.getUserMedia(config)
      await mediaDevicesLoad()
      mediaLocalStream.value = stream
      mediaLocalStreamError.value = MediaPermissionError.None
    } catch (error: any) {
      mediaLocalStream.value = null
      switch (error.name as MediaPermissionError) {
        case MediaPermissionError.AbortError:
        case MediaPermissionError.NotAllowedError:
        case MediaPermissionError.NotFoundError:
        case MediaPermissionError.NotReadableError:
        case MediaPermissionError.OverconstrainedError:
        case MediaPermissionError.SecurityError:
        case MediaPermissionError.TypeError:
          mediaLocalStreamError.value = error.name
          break
        default:
          mediaLocalStreamError.value = MediaPermissionError.Unknown
      }
    } finally {
      mediaLocalStreamInit.value = true
    }
  }

  function mediaLocalStreamRemove() {
    if (!mediaLocalStream.value) return
    mediaLocalStream.value.getTracks().forEach((track: any) => track.stop())
    mediaLocalStream.value = null
    mediaLocalStreamAudioActive.value = true
  }

  function mediaLocalVideoSettings(): IMediaTrackSettings {
    if (!mediaLocalStream.value)
      throw new Error(MediaServiceError.NotLocalStream)
    return mediaLocalStream.value.getVideoTracks()[0].getSettings()
  }

  function mediaLocalAudioMute(value: boolean) {
    if (!mediaLocalStream.value)
      throw new Error(MediaServiceError.NotLocalStream)
    mediaLocalStream.value
      .getAudioTracks()
      .forEach((track: IMediaStreamTrack) => (track.enabled = value))
    mediaLocalStreamAudioActive.value = value
  }

  return {
    mediaDefaultStreamConfig,
    mediaLocalStreamCreate,
    mediaLocalStreamRemove,
    mediaLocalVideoSettings,
    mediaLocalAudioMute,
  }
}
