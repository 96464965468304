import { useContext } from '@nuxtjs/composition-api'

export function useVideoCallBlackScreen() {
  const { $sentry } = useContext()

  function videoCallBlackScreenCheck(videoNode: HTMLVideoElement) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) return
    try {
      canvas.width = 3
      canvas.height = 3
      context.drawImage(
        videoNode,
        0,
        0,
        videoNode.offsetWidth,
        videoNode.offsetHeight
      )
      const pixels = [
        videoCallBlackScreenPixel(context, 0, 0),
        videoCallBlackScreenPixel(context, 1, 1),
        videoCallBlackScreenPixel(context, 2, 2),
      ]
      let blackPixels = 0
      pixels.forEach((bBlack) => {
        if (bBlack) ++blackPixels
      })
      const isBlack = blackPixels === 3
      // if (isBlack) $sentry.captureMessage('VideoCall: black screen detect')
      return {
        success: true,
        isBlack,
      }
    } catch (err: any) {
      $sentry.captureMessage('VideoCall: black screen checker error')
      return {
        success: false,
        error: err.message,
      }
    } finally {
      canvas.remove()
    }
  }

  function videoCallBlackScreenPixel(
    context: CanvasRenderingContext2D,
    x: number,
    y: number
  ) {
    const pixel = context.getImageData(x, y, 1, 1).data
    return pixel[0] === 0 && pixel[1] === 0 && pixel[2] === 0
  }

  return {
    videoCallBlackScreenCheck,
  }
}
