import { AuthResponseError } from '../auth/authError.service'
import { BillingApi } from '../repository'
import {
  IBillingBalanceGetResponse,
  IBillingCardsGetResponse,
  IBillingCardObject,
  IBillingTransactionsGetResponse,
  IBillingTransactionObject,
  IBillingRefundPostResponse,
  IBillingInitPaymentPostResponse,
  IBillingRecurringPostResponse,
  IBillingOrderStatusPostResponse,
  IBillingOrderStatusPostResponseType,
  IBillingSolidGatePaymentPostResponse,
} from './billing.types'

export class BillingRepository {
  protected _api: BillingApi
  protected _auth: AuthResponseError

  constructor() {
    this._api = new BillingApi()
    this._auth = new AuthResponseError()
  }

  public async balanceGet() {
    let data: number = 0
    const response = await this._api.balanceGET()
    const state = this._api.parse<IBillingBalanceGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.balance || 0
    }

    return {
      ...state,
      data,
    }
  }

  public async cardsGet() {
    let data: IBillingCardObject[] = []
    const response = await this._api.cardsGET()
    const state = this._api.parse<IBillingCardsGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.cards || []
    }

    return {
      ...state,
      data,
    }
  }

  public async transactionGet() {
    let data: IBillingTransactionObject[] = []
    const response = await this._api.transactionGET()
    const state = this._api.parse<IBillingTransactionsGetResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.transactions || []
    }

    return {
      ...state,
      data,
    }
  }

  public async refundPost(orderId: string) {
    let data: boolean = false
    const response = await this._api.refundPOST(orderId)
    const state = this._api.parse<IBillingRefundPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }

    return {
      ...state,
      data,
    }
  }

  public async initPaymentPost(productId: string) {
    let data: IBillingInitPaymentPostResponse | undefined
    const response = await this._api.initPaymentPOST(productId)
    const state = this._api.parse<IBillingInitPaymentPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData
    }

    return {
      ...state,
      data,
    }
  }

  public async recurringPost(productId: string, cardId: string) {
    let data: string = ''
    const response = await this._api.recurringPOST(productId, cardId)
    const state = this._api.parse<IBillingRecurringPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.orderId || ''
    }

    return {
      ...state,
      data,
    }
  }

  public async orderStatusPost(orderId: number) {
    let data: IBillingOrderStatusPostResponseType | undefined
    const response = await this._api.orderStatusPOST(orderId)
    const state = this._api.parse<IBillingOrderStatusPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.status
    }

    return {
      ...state,
      data,
    }
  }

  public async solidGatePaymentPost(productId: string) {
    let data: IBillingSolidGatePaymentPostResponse | undefined

    const response = await this._api.solidGatePaymentPOST(productId)
    const state = this._api.parse<IBillingSolidGatePaymentPostResponse>(
      response
    )
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData
    }

    return {
      ...state,
      data,
    }
  }
}

export const billingRepository = new BillingRepository()
