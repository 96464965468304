import { UserPhoto } from '../types'
import { userPhotoAvatarPost } from '@/src/shared/api'

type UserApiResponse = {
  photo: UserPhoto
}

export async function userPhotoSetAvatar(
  photoId: number
): Promise<number | undefined> {
  const response = await userPhotoAvatarPost<UserApiResponse>(photoId)
  if (!response.isOk || !response.data) return 0
  const photo = response.data.photo
  return photo ? photo.id : undefined
}
