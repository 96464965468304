import { WEBSOCKET_EVENT } from './websocket.config'
import {
  WebsocketClient,
  WebsocketNamespaces,
  IWebscoketNamespaceClients,
} from './websocket.types'
import { WebsocketError, WebsocketErrorDescription } from './websocket.error'
import { websocketClient } from './websocketClient'
import { websocketClientOptions } from './websocketClientOptions'
import { IEventBus } from '@/src/shared/lib/eventBus'

const webSocketCache: IWebscoketNamespaceClients = {}

export function websocketConnect(
  eventBus: IEventBus,
  namespace: WebsocketNamespaces,
  userId: number,
  accessToken: string
): Promise<boolean> {
  const config = websocketClientOptions(userId, accessToken)
  const socketClient = websocketClient(namespace, config)
  webSocketCache[namespace] = socketClient

  socketClient.onAny((event, payload) => {
    const eventName = eventBus.nameGenerate(WEBSOCKET_EVENT, namespace, event)
    eventBus.emit(eventName, payload)
  })

  return new Promise(function (resolve) {
    socketClient.once('connect', () => {
      resolve(true)
    })
  })
}

export function websocketClientGet(
  namespace: WebsocketNamespaces
): WebsocketClient {
  const client = webSocketCache[namespace]
  if (!client) {
    throw new WebsocketError(WebsocketErrorDescription.noClient)
  }
  return client
}

export function websocketSocketIdGet(namespace: WebsocketNamespaces): string {
  const client = webSocketCache[namespace]
  if (!client) {
    throw new WebsocketError(WebsocketErrorDescription.noClient)
  }
  return client.id
}

export function websocketConnected(namespace: WebsocketNamespaces): boolean {
  const client = webSocketCache[namespace]
  if (!client) return false
  return client.connected
}

export function websocketDisconnect(namespace: WebsocketNamespaces): void {
  const client = webSocketCache[namespace]
  if (!client) {
    return
  }
  if (client.disconnected) return
  client.disconnect()
}

export function websocketEmit<P>(
  namespace: WebsocketNamespaces,
  event: string,
  payload: P
): void {
  const client = webSocketCache[namespace]
  if (!client) {
    return
  }
  client.emit(event, payload)
}
