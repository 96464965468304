import { userRepository } from '../user/user.api.usecase'
import { UserCache } from '../repository'
import { useAuth } from '../usecase'
import {
  userMe,
  userPreRemoveReasons,
  IUserReasonsForRemoveObject,
  IUserObject,
  IUserMeUpdatePayload,
} from '../user'
import { resetFeature } from '@/src/features/reset'

export const userCache = new UserCache()

export function useUser() {
  const { authLogout } = useAuth()

  async function userMeLoad(bReload: boolean = false): Promise<boolean> {
    if (!userMe.value || bReload) {
      const { success, data } = await userRepository.meGet()
      userMe.value = success && data ? data : undefined
    }
    return !!userMe.value
  }

  async function userMeUpdate(payload: IUserMeUpdatePayload): Promise<any> {
    return await userRepository.mePost(payload)
  }

  function userMeLogout() {
    userMe.value = undefined
    resetFeature.model.all()
    authLogout()
  }

  async function userPrepemoveLoad(): Promise<IUserReasonsForRemoveObject[]> {
    if (!userPreRemoveReasons.value.length) {
      const { success, data } = await userRepository.preremoveGet()
      if (success && data) {
        userPreRemoveReasons.value = data
      } else {
        userPreRemoveReasons.value = []
      }
    }
    return userPreRemoveReasons.value
  }

  async function userByIdLoad(
    userId: number
  ): Promise<IUserObject | undefined> {
    const { success, data } = await userRepository.userByIdGET(userId)
    return success && data ? data : undefined
  }

  return {
    userMeLoad,
    userMeUpdate,
    userMeLogout,
    userPrepemoveLoad,
    userByIdLoad,
  }
}
