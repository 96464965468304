import { render, staticRenderFns } from "./PrivateCallModalButtonAnswer.vue?vue&type=template&id=8d65dc02&scoped=true&"
import script from "./PrivateCallModalButtonAnswer.vue?vue&type=script&lang=ts&"
export * from "./PrivateCallModalButtonAnswer.vue?vue&type=script&lang=ts&"
import style0 from "./PrivateCallModalButtonAnswer.vue?vue&type=style&index=0&id=8d65dc02&lang=scss&scoped=true&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8d65dc02",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DialogIconCallAnswear: require('/Users/tarletskiy/Documents/projects/liti/fe-monorepo/apps/peachfy-old/components/dialog/DialogIconCallAnswear.vue').default})
