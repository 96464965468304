import { VideoCallMessage } from '../types'
import { VideoCallMessageType } from '../config'
import { VideoCallError, VideoCallErrorDescription } from './videoCall.error'
import { useVideoCallStore } from './videoCall.store'
import { websocketEmit } from './videoCallWebsocketEmit.setup'
import { LanguageISO } from '@/src/shared/types'
import { languageBrowser } from '@/src/shared/lib/languageBrowser'
import { translateText } from '@/src/shared/lib/translateText'

export async function messageTextSend(
  message: string,
  userLocale?: LanguageISO
) {
  const videoCallStore = useVideoCallStore()
  if (!videoCallStore.connectionUser)
    throw new VideoCallError(VideoCallErrorDescription.noRemoteUser)

  const localLanguage = userLocale || languageBrowser()
  const remoteLanguage = videoCallStore.connectionUser.locale
  const socketId = videoCallStore.connectionUser.socketId
  message = await translateText(
    remoteLanguage,
    localLanguage as LanguageISO,
    message
  )
  websocketEmit('sendMessage', {
    socketId,
    message,
  })
}

export function messageTextFake(message: string) {
  const videoCallStore = useVideoCallStore()
  if (!videoCallStore.connectionUser)
    throw new VideoCallError(VideoCallErrorDescription.noRemoteUser)

  const payload: VideoCallMessage = {
    type: VideoCallMessageType.messageSentNeedReg,
    message,
    data: {
      name: 'You',
    },
  }
  videoCallStore.messagePush(payload)
}
