import { privateCallEntity } from '@/src/entities/private-call'
import { privateCallIncomeModalEntity } from '@/src/entities/private-call-income-modal'

export function useOnIncome() {
  function callback(
    payload: privateCallEntity.types.PrivateCallPayloadSocketPrivateRequest
  ) {
    const store = privateCallEntity.model.usePrivateCallStore()
    store.resetCall()
    const remoteUser: privateCallEntity.types.PrivateCallRemoteUser = {
      id: payload.a.user.id,
      name: payload.a.user.name,
      avatar: payload.a.user.avatar?.urlS || '',
      age: payload.a.user.age,
      isHost: payload.a.user.isHost,
    }
    store.remoteUser = remoteUser
    store.callerId = payload.s
    store.receiverId = payload.r
    store.direction = 'income'
    store.status = 'calling'
    privateCallIncomeModalEntity.model.modal.open({
      avatar: remoteUser.avatar,
      name: remoteUser.name,
      age: remoteUser.age,
    })
  }

  return privateCallEntity.model.useWebsocketOnPrivateRequest(callback)
}
