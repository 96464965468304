import { useUserHelpers } from '@/logic/user/userHelper.usecase'
import { useBillingHelpers } from '@/logic/billing/billingHelpers.usecase'
import { useDialogHelpers } from '@/logic/dialog/dialogHelpers.usecase'
import { usePrivateCallHelpers } from '@/logic/privateCall/privateCallHelpers.usecase'
import { useUploaderHelpers } from '@/logic/uploader/uploaderHelpers.usecase'
// import { webPuchStateReset } from '@/logic/webPush'
import { useRandomCallHelpers, useVideoCallHelpers } from '@/logic/usecase'
// auth
import { blocklistEntity } from '@/src/entities/blocklist'
import { chatEntity } from '@/src/entities/chat'
import { favoritesEntity } from '@/src/entities/favorites'
import { experimentEntity } from '@/src/entities/experiment'
// import { mediaEntity } from '@/src/entities/media'
import { paymentEntity } from '@/src/entities/payment'
import { storiesEntity } from '@/src/entities/stories'
import { userEntity } from '@/src/entities/user'
import { videoCallEntity } from '@/src/entities/video-call'

export function all() {
  const { userResetState } = useUserHelpers()
  const { billingResetState } = useBillingHelpers()
  const { dialogResetState } = useDialogHelpers()
  const { privateCallResetState } = usePrivateCallHelpers()
  const { randomCallResetState } = useRandomCallHelpers()
  const { uploaderResetState } = useUploaderHelpers()
  const { videoCallResetState } = useVideoCallHelpers()

  // webPuchStateReset()
  userResetState()
  billingResetState()
  dialogResetState()
  privateCallResetState()
  randomCallResetState()
  uploaderResetState()
  videoCallResetState()

  // new ----
  blocklistEntity.model.useBlocklistStore().$reset()
  chatEntity.model.useChatStore().$reset()
  favoritesEntity.model.useFavoritesStore().$reset()
  storiesEntity.model.useStoriesStore().$reset()
  userEntity.model.useUserStore().$reset()
  videoCallEntity.model.useVideoCallStore().$reset()
  paymentEntity.model.usePaymentCardsStore().$reset()
  paymentEntity.model.usePaymentTransactionStore().$reset()
  paymentEntity.model.usePaymentModalStore().$reset()
  experimentEntity.model.useExperimentStore().$reset()
}
