import { userPhotoDelete } from '@/src/shared/api'

type UserApiResponse = {
  success: boolean
}

export async function photoDelete(photoId: number): Promise<boolean> {
  const response = await userPhotoDelete<UserApiResponse>(photoId)
  if (!response.isOk || !response.data) return false
  return response.data.success
}
