import { userRepository } from '../user/user.api.usecase'
import { dialogNitification, dialogNotificationPrivateCalls } from '../usecase'

export async function useUserNotification() {
  const { success, data } = await userRepository.countersGET()
  if (success && data) {
    dialogNitification.value = data.messages
    dialogNotificationPrivateCalls.value = data.notifications
  }
}
