import { tokenSet, tokenReset } from './token.service'
import { registrationByEmailAndPasswordPost } from '@/src/shared/api'
import { api } from '@/src/shared/lib/api'

type AuthApiResponse = {
  accessToken: string
  expires: number
  refreshToken: string
  userId: number
  partnerId: number
}

export async function registrationByEmailPassword(
  email: string,
  password: string,
  parnerId?: string,
  confirmMailing?: boolean
): Promise<api.ApiResponseState<AuthApiResponse>> {
  tokenReset()
  api.tokenReset()

  const response = await registrationByEmailAndPasswordPost<AuthApiResponse>(
    email,
    password,
    parnerId,
    confirmMailing
  )
  if (response.isOk && response.data) {
    tokenSet({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresAt: response.data.expires,
    })
    api.tokenSet(response.data.accessToken)
  }
  return response
}
