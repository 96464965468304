const memoryCache: any = {}

class MemmoryStorage {
  static getItem(name: string): string | null {
    return memoryCache[name] || null
  }

  static setItem(name: string, value: string) {
    memoryCache[name] = value
  }

  static removeItem(name: string) {
    if (memoryCache[name]) delete memoryCache[name]
  }
}

export abstract class StorageCache<S> {
  protected _storage: Storage
  protected _name: string
  protected _cache: S

  constructor(target: 'session' | 'local', name: string, cache: S) {
    if (process.client) {
      this._storage = target === 'session' ? sessionStorage : localStorage
    } else {
      this._storage = MemmoryStorage as any
    }
    this._name = name
    this._cache = cache
    this.cacheGet()
  }

  protected cacheGet() {
    if (process.server) return this._cache
    const cache = this._storage.getItem(this._name) || '{}'
    this._cache = JSON.parse(cache)
  }

  protected cacheSave() {
    if (process.server) return
    if (!this._cache) {
      this._storage.removeItem(this._name)
    } else {
      this._storage.setItem(this._name, JSON.stringify(this._cache))
    }
  }

  public get cache(): S {
    return this._cache
  }

  public set cache(value: S) {
    this._cache = value
    this.cacheSave()
  }
}
