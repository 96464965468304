import { PrivateCallPayloadSocketPrivateRequest } from '../types'
import { websocket } from '@/src/shared/lib/websocket'

type PrivateCallWebsocketOnPrivateRequestCallback = (
  payload: PrivateCallPayloadSocketPrivateRequest
) => void

const EMIT_NAME = 'private_call_request'

export function useWebsocketOnPrivateRequest(
  callback: PrivateCallWebsocketOnPrivateRequestCallback
) {
  return {
    on() {
      const defaultClient = websocket.client('application')
      defaultClient.on(EMIT_NAME, callback)
    },
    off() {
      const defaultClient = websocket.client('application')
      defaultClient.off(EMIT_NAME, callback)
    },
  }
}
