import { Gift, GiftEntry, GiftApiSubmitResult } from '../types'
import { useGiftsStore } from './gifts.store'
import { giftGet, giftSubmitPost } from '@/src/shared/api'

type GiftApiResponse = Gift[]

type GiftApiSubmitResponse = {
  success: boolean
}

export async function load(isVip: boolean) {
  const store = useGiftsStore()
  const response = await giftGet<GiftApiResponse>(isVip)
  store.$reset()
  store.loaded = true
  if (response.isOk) {
    response.data?.forEach(store.giftAdd)
  }
}

export async function submit(
  userId: number,
  giftId: number,
  entry: GiftEntry
): Promise<GiftApiSubmitResult> {
  const response = await giftSubmitPost<GiftApiSubmitResponse>(
    userId,
    giftId,
    entry
  )
  if (response.isOk) {
    return response.data?.success ? 'success' : 'error'
  } else if (response.error) {
    return response.error.code === 3001 ? 'no_enough_money' : 'error'
  }
  return 'error'
}
