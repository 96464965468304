import { defineStore } from 'pinia'
import { StoriesItem } from '../types/stories.types'

type StoriesStore = {
  stories: StoriesItem[]
  currentIndex: number
  muted: boolean
}

export const useStoriesStore = defineStore('stories', {
  state: (): StoriesStore => ({
    stories: [],
    currentIndex: 0,
    muted: true,
  }),
  getters: {
    current: (state) => state.stories[state.currentIndex],
    isNext: (state) => !!state.stories[state.currentIndex + 1],
    isPrev: (state) => !!state.stories[state.currentIndex - 1],
  },
  actions: {
    next() {
      this.muted = true
      this.currentIndex++
    },
    prev() {
      this.muted = true
      this.currentIndex--
    },
    toogleLike(liked?: boolean) {
      const story = this.current
      liked = liked ?? !story.liked
      const newStroy = Object.assign(story, { liked })
      this.stories.splice(this.currentIndex, 1, newStroy)
    },
    push(item: StoriesItem) {
      this.stories.push(item)
    },
  },
})
