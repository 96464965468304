import { Gender } from '@/src/shared/types'
import { userPost } from '@/src/shared/api'
import { api } from '@/src/shared/lib/api'

type UserMeUpdatePayload = {
  name?: string
  age?: number
  gender?: Gender
  approvedTerms?: boolean
  approvedVideochatRules?: boolean
}

type ApiResponse = {
  success: boolean
}

export async function meUpdate(
  payload: UserMeUpdatePayload
): Promise<api.ApiResponseState<ApiResponse>> {
  const response = await userPost<ApiResponse>(payload)

  // if (response.isOk && response.data?.success) {
  //   return true
  // }
  return response
}
