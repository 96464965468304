import { useRewardsStore } from './rewards.store'
import { initRewardsTimer } from './getRewards.setup'
import { rewardsClaimRewardPost } from '@/src/shared/api'

type ClaimDailyRewardDTO = {
  success: boolean
  currentTimestamp: number | null
  nextAvailableAtTimestamp: number | null
}

const ONE_SECOND_IN_MS = 1000

export async function claimDailyReward(): Promise<void> {
  const rewardsStore = useRewardsStore()
  const response = await rewardsClaimRewardPost<ClaimDailyRewardDTO>()
  if (response.isOk && response.data.success) {
    rewardsStore.currentDailyReward.claimed = true
    rewardsStore.rewardClaimedDate = new Date(
      response.data.currentTimestamp * ONE_SECOND_IN_MS
    )
    rewardsStore.nextRewardDate = new Date(
      response.data.nextAvailableAtTimestamp * ONE_SECOND_IN_MS
    )
    initRewardsTimer()
  }
}
