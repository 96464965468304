import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api'
import { VideoCallPayloadSocketRTC } from '../types'
import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketOnRtcCallback = (
  payload: VideoCallPayloadSocketRTC
) => void

const EMIT_NAME = 'rtc'

export function useWebsocketOnRtc(callback: VideoCallWebsocketOnRtcCallback) {
  const defaultClient = websocket.client('default')
  const off = () => defaultClient.off(EMIT_NAME, callback)
  onMounted(() => defaultClient.on(EMIT_NAME, callback))
  onBeforeUnmount(() => off())
  return {
    off,
  }
}
