import { ref } from '@nuxtjs/composition-api'
// import { GoogleAuth } from '../googleAuth'

export enum AuthSocial {
  'facebook' = 2,
  'google' = 3,
  'snapchat' = 4,
}

export enum AuthModalEmits {
  photoUpload = 'photoUpload',
  tackPhoto = 'takePhoto',
}

export enum AuthModalActionsUploadMenu {
  takePhoto = 'takePhoto',
  uploadFile = 'uploadFile',
  deletePhoto = 'deletePhoto',
}

export enum AuthModalActionsTakePhoto {
  file = 'file',
}

export const EVENT_AUTH = 'auth'
export const EVENT_ERROR = 'error'
export const EVENT_LOGOUT = 'logout'

export const GENDER_CACHE = 'auth-gender-cache'

export const authUserIsAuth = ref(false)

// export const authGoogleClient = ref<GoogleAuth | undefined>()
export const authGoogleClientActived = ref(false)
