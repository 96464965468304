import { videoCallStatus } from '@/logic/videoCall/videoCall.state'
import { randomCallScreen } from '@/logic/randomCall'
import { videoCallEntity } from '@/src/entities/video-call'

export function inCall(): boolean {
  const store = videoCallEntity.model.useVideoCallStore()
  if (!store.isEmpty) return true
  if (randomCallScreen.value === 'call') return true
  if (videoCallStatus.value === 'none') return false
  return true
}
