import { videoCallEntity } from '@/src/entities/video-call2'

export function useStepEnroll(callbackSkip: Function) {
  const videoCallStore = videoCallEntity.model.useVideoCallStore()

  videoCallEntity.model.useEventBusOnStep(async function (step: any) {
    const stepFilter = [
      'recurring',
      'private_call_init',
      'private_call_recurring',
    ]
    if (!stepFilter.includes(step.name)) return

    const result = await videoCallEntity.model.videoCallRequestStep(step)
    if (result) {
      videoCallEntity.model.websocketSendProlongPermission({
        socketId: videoCallStore.remoteUser?.socketId || '',
        callId: videoCallStore.remoteUser?.callId || '',
      })
    } else {
      callbackSkip()
    }
  })
}
