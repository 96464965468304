import { googleSignIn } from './google.setup'
import { useAuthModalStore } from './modal.store'
import { tokenSet } from './token.service'
import { loginBySocialPost, registrationBySocialPost } from '@/src/shared/api'

type AuthApiLoginResponse = {
  userId: number
  accessToken: string
  expires: number
  refreshToken: string
}

type AuthApiRegistrationResponse = {
  accessToken: string
  expires: number
  refreshToken: string
  userId: number
  partnerId: number
}

export async function registrationByGoogle(): Promise<boolean> {
  const store = useAuthModalStore()
  store.loading = true
  store.resetApi()
  let bResult = false
  const responseSignIn = await googleSignIn()

  if (responseSignIn.type === 'error') {
    store.errorDetailPush('google', responseSignIn.error)
  } else if (responseSignIn.type === 'success') {
    const responseLogin = await loginBySocialPost<AuthApiLoginResponse>(
      responseSignIn.id,
      3,
      responseSignIn.email
    )
    if (responseLogin.isOk && responseLogin.data) {
      tokenSet({
        accessToken: responseLogin.data.accessToken,
        refreshToken: responseLogin.data.refreshToken,
        expiresAt: responseLogin.data.expires,
      })
      bResult = true
    } else if (responseLogin.data?.error?.code === 1008) {
      const responseReg = await registrationBySocialPost<AuthApiRegistrationResponse>(
        responseSignIn.id,
        3,
        responseSignIn.email,
        responseSignIn.avatar
        // parnerId
      )
      if (responseReg.isOk && responseReg.data) {
        tokenSet({
          accessToken: responseReg.data.accessToken,
          refreshToken: responseReg.data.refreshToken,
          expiresAt: responseReg.data.expires,
        })
        bResult = true
      } else {
        store.errorDetailPush(
          'google',
          responseLogin.data?.error?.message || ''
        )
      }
    } else {
      store.errorDetailPush('google', responseLogin.data?.error?.message || '')
    }
  }
  store.loading = false
  return bResult
}
