import { googleSignIn } from './google.setup'
import { useAuthModalStore } from './modal.store'
import { preRegistrationFinishByGooglePost } from '@/src/shared/api'

type AuthApiPreRegistrationFinishByGoogleResponse = {
  success: boolean
}

export async function preRegistrationByGoogle(): Promise<boolean> {
  const store = useAuthModalStore()
  store.loading = true
  store.resetApi()
  let bResult = false
  const responseSignIn = await googleSignIn()
  if (responseSignIn.type === 'error') {
    store.errorDetailPush('google', responseSignIn.error)
  } else if (responseSignIn.type === 'success') {
    const response = await preRegistrationFinishByGooglePost<AuthApiPreRegistrationFinishByGoogleResponse>(
      responseSignIn.email,
      responseSignIn.id
    )
    if (!response.isOk) {
      store.errorDetailPush('google', response.data?.error?.message || '')
    } else {
      bResult = true
    }
  }
  store.loading = false
  return bResult
}
