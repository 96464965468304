import { useRouter, useRoute } from '@nuxtjs/composition-api'
import { IRouting } from './routing.types'
import { RoutingPath } from './routing.vars'
import { Routing } from './routing.service'

// TODO R&D
// https://router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures

export function useRouting(): IRouting {
  const route = useRoute()
  const router = useRouter()

  const rounting = new Routing(router)
  rounting.currentPath = route.value.path as RoutingPath

  return rounting
}
