import { StorageCache } from '../core/storage'
import { IAuthAccessToken } from '../auth'

export const AUTH_TOKEN_CACHE = 'authToken'
export const AUTH_ACCESS_TOKEN = 'accessToken'
export const AUTH_REFRESH_TOKEN = 'refreshToken'
export const AUTH_EXPIRES_AT = 'expiresAt'

export class AuthTokenCache extends StorageCache<IAuthAccessToken | undefined> {
  constructor() {
    super('local', AUTH_TOKEN_CACHE, undefined)
  }

  public get token(): IAuthAccessToken | undefined {
    return this.cache
  }

  public set token(value: IAuthAccessToken | undefined) {
    this.cache = value
  }

  public removeToken() {
    this.cache = undefined
  }

  public get accessToken(): string {
    return this.cache?.accessToken || ''
  }

  public get refreshToken(): string {
    return this.cache?.refreshToken || ''
  }

  public get expiresAt(): number {
    return this.cache?.expiresAt || Date.now() / 1000 - 1
  }

  public get isExpires(): boolean {
    const unixSec = Math.ceil(Date.now() / 1000)
    return this.expiresAt < unixSec
  }
}
