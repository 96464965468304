import { AuthResponseError } from '../auth/authError.service'
import { DialogApi } from '../repository'
import {
  IDialogTreadsLoadResponse,
  IDialogThreadObject,
  IDialogThreadMessagesLoadResponse,
  IDialogThreadMessageObject,
  IDialogThreadMessagesDeleteResponse,
  IDialogThreadMessagesSendResponse,
  IDialogThreadMessagesReadResponse,
  IDialogThreadUnlockPostResponse,
} from './dialog.types'
import { DialogThreadType } from './dialog.state'
import { DialogThreadMapper } from './dialogThread.mapper'
import { DialogThreadMessageMapper } from './dialogThreadMessage.mapper'

export class DialogRepository {
  protected _api: DialogApi
  protected _auth: AuthResponseError

  constructor() {
    this._api = new DialogApi()
    this._auth = new AuthResponseError()
  }

  public async threadsGet(page?: number, returnLimit?: number) {
    let data: IDialogThreadObject[] = []
    const response = await this._api.threadsGET(page, returnLimit)
    const state = this._api.parse<IDialogTreadsLoadResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      // TODO Remove
      const messages = (state.responseData?.messages || []).filter(
        (message) => !!message.ts
      )
      data = DialogThreadMapper.apiToDomains(messages)
    }

    return {
      ...state,
      data,
    }
  }

  public async threadDelete(userId: number, threadType: DialogThreadType) {
    let data: boolean = false
    const response = await this._api.threadDELETE(userId, threadType)
    const state = this._api.parse<IDialogThreadMessagesDeleteResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }

    return {
      ...state,
      data,
    }
  }

  public async threadUnlock(userId: number) {
    let data: boolean = false
    const response = await this._api.threadUnlockPOST(userId)
    const state = this._api.parse<IDialogThreadUnlockPostResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }

    return {
      ...state,
      data,
    }
  }

  public async threadMessagesGet(
    userId: number,
    offsetTimestamp?: number,
    returnLimit?: number
  ) {
    const data = {
      messages: [] as IDialogThreadMessageObject[],
      unlockShow: false,
      unlockPrice: 0,
      context: {},
    }
    const response = await this._api.threadMessagesGET(
      userId,
      offsetTimestamp,
      returnLimit
    )
    const state = this._api.parse<IDialogThreadMessagesLoadResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data.messages = DialogThreadMessageMapper.apiToDomains(
        state.responseData?.messages || []
      )
      data.unlockShow = state.responseData?.isPaid === false
      data.unlockPrice = state.responseData?.unlockPrice || 0
      data.context = state.responseData?.context || {}
    }

    return {
      ...state,
      data,
    }
  }

  public async threadMessageTextSendPost(userId: number, message: string) {
    let data: IDialogThreadMessageObject | undefined
    const response = await this._api.threadMessageTextSendPOST(userId, message)
    const state = this._api.parse<IDialogThreadMessagesSendResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      if (state.responseData?.msg) {
        data = DialogThreadMessageMapper.apiToDomain(state.responseData?.msg)
      }
    }

    return {
      ...state,
      data,
    }
  }

  public async threadMessageGiftSendPost(userId: number, giftId: number) {
    let data: IDialogThreadMessageObject | undefined
    const response = await this._api.threadMessageGiftSendPOST(userId, giftId)
    const state = this._api.parse<IDialogThreadMessagesSendResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      if (state.responseData?.msg) {
        data = DialogThreadMessageMapper.apiToDomain(state.responseData?.msg)
      }
    }

    return {
      ...state,
      data,
    }
  }

  public async threadMessageImageSendPost(userId: number, photoId: number) {
    let data: IDialogThreadMessageObject | undefined
    const response = await this._api.threadMessageImageSendPOST(userId, photoId)
    const state = this._api.parse<IDialogThreadMessagesSendResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      if (state.responseData?.msg) {
        data = DialogThreadMessageMapper.apiToDomain(state.responseData?.msg)
      }
    }

    return {
      ...state,
      data,
    }
  }

  public async threadMessageReadPost(userId: number) {
    let data: boolean = false
    const response = await this._api.threadMessageReadPOST(userId)
    const state = this._api.parse<IDialogThreadMessagesReadResponse>(response)
    if (state.authError) this._auth.emit(response)
    if (state.success) {
      data = state.responseData?.success || false
    }

    return {
      ...state,
      data,
    }
  }
}

export const dialogRepository = new DialogRepository()
