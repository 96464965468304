import { useUserStore } from './user.store'
import { userBalanceGet } from '@/src/shared/api'

type ApiResponse = {
  balance: number
  isVip: boolean
}

export async function balanceLoad(): Promise<boolean> {
  const store = useUserStore()

  const response = await userBalanceGet<ApiResponse>()

  store.balanceLoaded = true
  if (response.isOk && response.data) {
    store.balance = response.data.balance
    return true
  }
  return false
}

export function balanceEnough(balance: number) {
  const store = useUserStore()
  if (!store.balanceLoaded)
    throw new Error('todo: balaceEuqal !store.balanceLoaded')
  return store.balance >= balance
}
