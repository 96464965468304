import { VideoCallPayloadRegister } from '../types'
// import { useVideoCallStore } from './videoCall.store'
import { languageBrowser } from '@/src/shared/lib/languageBrowser'
import { websocket } from '@/src/shared/lib/websocket'

export function registerRandom(
  lookingFor: number[] = [2]
): VideoCallPayloadRegister {
  // const videoCallStore = useVideoCallStore()
  // videoCallStore.target = 'random'
  return registerPayloadGenerate({
    lookingFor,
    callType: 1,
  })
}

export function registerPrivate(
  peerUserId: number,
  callerId: number
): VideoCallPayloadRegister {
  // const videoCallStore = useVideoCallStore()
  // videoCallStore.target = 'private'
  return registerPayloadGenerate({
    peerUserId,
    callerId,
    callType: 2,
  })
}

function registerPayloadGenerate(
  setObj: Partial<VideoCallPayloadRegister>
): VideoCallPayloadRegister {
  const payload: VideoCallPayloadRegister = {
    userId: 0,
    name: undefined,
    age: undefined,
    gender: 1,
    lookingFor: [2],
    socketId: websocket.socketId('default'),
    status: 1,
    imageUrl: undefined,
    callType: 1,
    platform: 'web',
    isHost: false,
    appVersion: 0, // TODO
    application: process.env.APPLICATION,
    locale: languageBrowser(),
    hasPurchase: false,
    streamerStatus: false,
    agencyId: undefined,
    category: undefined,
    debuggable: !process.env.IS_PROD,
    isNewFinder: true,
    systemChargeType: 2,
    sendAccept: false,
    ...setObj,
  }
  return payload
}
