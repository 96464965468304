import { tokenSet, tokenReset } from './token.service'
import { loginPost } from '@/src/shared/api'
import { api } from '@/src/shared/lib/api'

type ApiResponse = {
  accessToken: string
  expires: number
  refreshToken: string
  userId: number
  expiresAt: string
}

export async function loginByEmailPassword(
  email: string,
  password: string
): Promise<api.ApiResponseState<ApiResponse>> {
  tokenReset()
  api.tokenReset()

  const response = await loginPost<ApiResponse>(email, password)
  if (response.isOk && response.data) {
    tokenSet({
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      expiresAt: response.data.expires,
    })
    api.tokenSet(response.data.accessToken)
  }
  return response
}
