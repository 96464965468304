import { ref } from '@nuxtjs/composition-api'
import { defineStore } from 'pinia'
import { User, User2 } from '../types/user.types'

export const me = ref<User>() // TODO remove
export const balance = ref(0) // TODO remove
// TODO remove
export const loaded = ref({
  me: false,
  balance: false,
})

// v2
type UserStoreState = {
  me: User2 | undefined
  meLoaded: boolean
  balance: number
  balanceLoaded: boolean
  counterMessages: number
}

export const useUserStore = defineStore('user', {
  state: (): UserStoreState => {
    return {
      me: undefined,
      meLoaded: false,
      balance: 0,
      balanceLoaded: false,
      counterMessages: 0,
    }
  },
  getters: {
    isRegistrationFinish: (state) => !!state.me?.finishPreRegistration,
    isModerator: (state) => state.me.roles.includes('APP_MODERATOR'),
  },
  actions: {},
})
