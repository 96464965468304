import { ref, watch, computed } from '@nuxtjs/composition-api'
import { AuthTokenCache } from '../repository/authAccessToken.cache'

export const authTokenCache = new AuthTokenCache()

// Global
export const authToken = ref(authTokenCache.token)
watch(authToken, (value: any) => (authTokenCache.token = value))

export const accessTokenCorrect = computed(
  () => !!authToken.value && !authTokenCache.isExpires
)
export const accessTokenExists = computed(() => !!authToken.value)
