import { urlCache } from '@/src/shared/lib/urlCache'
import { trackTrafficSourceEntity } from '@/src/entities/track-traffic-source'

function trafficSourcePayload(
  type: trackTrafficSourceEntity.types.TrackTrafficSourceType
) {
  const params = urlCache.searchParamsGet('enter')
  if (!params?.p && !params?.fbclid && !params?.gclid && !params?.ttclid) return

  const payload = trackTrafficSourceEntity.model.payload(type)
  if (params.partnerId) payload.partnerId = params.partnerId
  if (params.fbclid) {
    payload.source = 'facebook'
    payload.sourceId = params.fbclid
  }
  if (params.gclid) {
    payload.source = 'google'
    payload.sourceId = params.gclid
  }
  if (params.ttclid) {
    payload.source = 'tiktok'
    payload.sourceId = params.ttclid
  }

  return payload
}

export async function trafficSource(
  type: trackTrafficSourceEntity.types.TrackTrafficSourceType
) {
  const trafficPayload = trafficSourcePayload(type)
  if (trafficPayload) {
    await trackTrafficSourceEntity.model.request(trafficPayload)
  }
}
