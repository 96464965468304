import { defineStore } from 'pinia'

import type { RewardsStoreState } from '../types'

export const useRewardsStore = defineStore('dailyRewards', {
  state: (): RewardsStoreState => {
    return {
      dailyClaimed: false,
      daily: [],
      oneTime: [],
      rewardTimer: 0,
      nextRewardDate: null,
      rewardClaimedDate: null,
    }
  },
  getters: {
    currentDailyReward(state) {
      return state.daily.find((reward) => reward.isToday)
    },
  },
})
