import {
  websocketConnect,
  websocketConnected as connected,
  websocketDisconnect,
  websocketClientGet as client,
  websocketSocketIdGet as socketId,
  websocketEmit,
} from './websocket'
import type { WebsocketNamespaces } from './websocket.types'
import { WEBSOCKET_EVENT } from './websocket.config'

import { eventBus } from '@/src/shared/lib/eventBus'

export { connected, client, socketId }

export async function connectAll(
  userId: number,
  accessToken: string
): Promise<boolean> {
  // Default
  const defaultPromise = websocketConnect(
    eventBus,
    'default',
    userId,
    accessToken
  )

  // Application
  const applicationPromise = websocketConnect(
    eventBus,
    'application',
    userId,
    accessToken
  )

  // Connect all
  const results = await Promise.all([defaultPromise, applicationPromise])
  return !results.includes(false)
}

export function disconnectAll() {
  websocketDisconnect('default')
  websocketDisconnect('application')
}

export function emitDefault<P>(name: string, payload: P) {
  websocketEmit('default', name, payload)
}

export function emitApplication<P>(name: string, payload: P) {
  websocketEmit('application', name, payload)
}

export function onName(namespace: WebsocketNamespaces, event: string): string {
  return eventBus.nameGenerate(WEBSOCKET_EVENT, namespace, event)
}
