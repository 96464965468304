import type { TrackTrafficSourceType } from '../types'
import { webTrafficSourcePost } from '@/src/shared/api'

type TrackTrafficSourcePayload = {
  type: TrackTrafficSourceType
  partnerId?: string
  source?: 'facebook' | 'google' | 'tiktok'
  sourceId?: string
}

type TrackTrafficSourceResponse = {
  success: boolean
}

export function payload(
  type: TrackTrafficSourceType
): TrackTrafficSourcePayload {
  return {
    type,
  }
}

export async function request(
  payload: TrackTrafficSourcePayload
): Promise<boolean> {
  const response = await webTrafficSourcePost<
    TrackTrafficSourceResponse,
    TrackTrafficSourcePayload
  >(payload)
  if (response.isOk && response.data?.success) {
    return response.data.success
  }
  return false
}
