import { defineStore } from 'pinia'
import { BlocklistUser } from '../types'

type BlocklistStoreState = {
  loaded: boolean
  users: BlocklistUser[]
}

export const useBlocklistStore = defineStore('blocklist', {
  state: (): BlocklistStoreState => {
    return {
      loaded: false,
      users: [],
    }
  },
  getters: {
    isReady: (state) => state.loaded && !!state.users.length,
    isEmpty: (state) => state.loaded && !state.users.length,
  },
  actions: {
    userAdd(user: BlocklistUser) {
      this.users.push(user)
    },
  },
})
