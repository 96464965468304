import { VideoCallRemoteUser } from '../types'
import { useVideoCallStore } from './videoCall.store'

export function remoteUserSet(remoteUser: VideoCallRemoteUser) {
  const store = useVideoCallStore()
  store.remoteUser = remoteUser
}

export function remoteUserSecondRole(): string {
  const store = useVideoCallStore()
  if (store.remoteUser?.isHost) return 'host'
  if (store.remoteUser?.isFake) return 'fake'
  return 'user'
}

export function remoteUserId(): number {
  const store = useVideoCallStore()
  return store.remoteUser?.userId || 0
}
