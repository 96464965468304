import { IHttpAPI, HttpAppRequest, IHttpResponse } from '../core/http'
import { IUserMeUpdatePayload, IUserFeedbackTopic } from '../user/user.types'
import { UserFavoritesContext } from '../user/user.state'

export class UserApi extends HttpAppRequest implements IHttpAPI {
  async meGET(): Promise<IHttpResponse> {
    const url = this.createURL('user/me')
    return await this.get(url)
  }

  async mePOST(payload: IUserMeUpdatePayload): Promise<IHttpResponse> {
    const url = this.createURL('user')

    const formData = new FormData()
    Object.keys(payload).forEach((name: string) => {
      switch (name) {
        case 'name':
          if (payload.name) {
            formData.append(name, payload.name)
          }
          break
        case 'age':
          if (payload.age) {
            formData.append(name, payload.age as any)
          }
          break
        case 'gender':
          if (payload.gender) {
            let gender = 0
            switch (payload.gender.toLowerCase()) {
              case 'male':
                gender = 1
                break
              case 'female':
                gender = 2
                break
            }
            formData.append(name, gender as any)
          }
          break
      }
    })

    return await this.post(url, formData)
  }

  async userByIdGET(userId: number): Promise<IHttpResponse> {
    const url = this.createURL('user')
    url.searchParams.set('id', userId.toString())
    return await this.get(url)
  }

  async preremoveGET(): Promise<IHttpResponse> {
    const url = this.createURL('user/preremove')
    return await this.get(url)
  }

  async preremovePOST(reasons: string[]): Promise<IHttpResponse> {
    const formData = new FormData()
    formData.append('reasons', reasons.join(','))

    const url = this.createURL('user/preremove')
    return await this.post(url, formData)
  }

  async feedbackPOST(
    topic: IUserFeedbackTopic,
    text: string
  ): Promise<IHttpResponse> {
    const formData = new FormData()
    formData.append('topic', topic)
    formData.append('text', text)

    const url = this.createURL('utils/feedback')
    return await this.post(url, formData)
  }

  async removePOST(): Promise<IHttpResponse> {
    const url = this.createURL('user/remove')
    return await this.post(url, undefined)
  }

  async countersGET(): Promise<IHttpResponse> {
    const url = this.createURL('user/counters')
    return await this.get(url)
  }

  async favoritesPOST(
    userId: number,
    context: UserFavoritesContext
  ): Promise<IHttpResponse> {
    const url = this.createURL('user/favorites')
    const formData = {
      userId,
      context,
    }
    return await this.post(url, formData)
  }
}
