import Peer from 'peer-lite'
import { VideoCallPeerOptions, VideoCallPeer } from '../types'
import { useVideoCallStore } from './videoCall.store'

export function peerOptionsSet(
  options: Partial<VideoCallPeerOptions>
): VideoCallPeerOptions {
  const store = useVideoCallStore()
  store.peerOptions = Object.assign(store.peerOptions || {}, options)
  return store.peerOptions
}

export function peerCreate(): Peer {
  const store = useVideoCallStore()
  const peer = new Peer(store.peerOptions)
  store.peerSet(peer)
  return peer
}

export function peerGet(): VideoCallPeer | undefined {
  const store = useVideoCallStore()
  return store.peerGet()
}

export function peerDestroy(): void {
  const store = useVideoCallStore()
  const peer = store.peerGet()
  if (peer) peer.hangup()
  store.peerReset()
}
