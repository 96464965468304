import { modalsClose } from './privateCallCloseModals.setup'
import { useRedirectToCall } from './privateCallRedirect.setup'
import { appEntity } from '@/src/entities/app'
import { privateCallEntity } from '@/src/entities/private-call'

export function useOnAnswer() {
  const redirectToCall = useRedirectToCall()
  function callback(
    payload: privateCallEntity.types.PrivateCallPayloadSocketPrivateAnswer
  ) {
    console.log('useOnAnswer', payload)
    if (!payload.a.answer) {
      modalsClose()
      appEntity.model.loaderOff()
      return
    }
    //
    console.log('useOnAnswer-----------2', payload)
    redirectToCall()
  }

  return privateCallEntity.model.useWebsocketOnPrivateAnswer(callback)
}
