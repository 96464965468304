import Peer from 'peer-lite'
import { PeerLiteOptions } from 'peer-lite/dist/types/peer'
import { VideoCallSocketRTCCommand } from '../types'
import { VideoCallError, VideoCallErrorDescription } from './videoCall.error'
import { useVideoCallStore } from './videoCall.store'
import { websocketEmit } from './videoCallWebsocketEmit.setup'
import { videoCallEvent } from './videoCallEvents.service'

let peerConnection: any | Peer | undefined
let peerConfig: PeerLiteOptions | undefined

export function peerConfigGenerate(iceServers: any[]): PeerLiteOptions {
  return {
    config: { iceServers },
  }
}

export function peerConfigSet(config: PeerLiteOptions) {
  peerConfig = config
}

export function peerLocalStreamSet(stream: MediaStream) {
  const videoCallStore = useVideoCallStore()
  videoCallStore.localMediaStreamSet(stream)
}

export function peerInit(): void {
  const videoCallStore = useVideoCallStore()
  if (videoCallStore.peerIsInit) peerDestroy()
  videoCallStore.peerIsInit = true
  peerConnection = new Peer(peerConfig)
  const localMediaStream = videoCallStore.localMediaStreamGet()
  if (localMediaStream) {
    peerConnection.addStream(localMediaStream, true)
  }
  peerConnection.on('onicecandidates', (candidates: RTCIceCandidate[]) =>
    candidates.forEach(onIceCandidate)
  )
  peerConnection.on('streamRemote', onStreamRemote)
}

export function peerDestroy(): void {
  const videoCallStore = useVideoCallStore()
  if (peerConnection) peerConnection.hangup()
  videoCallStore.peerIsInit = false
  peerConnection = undefined
}

export async function peerOfferCreate(): Promise<RTCSessionDescriptionInit> {
  if (!peerConnection)
    throw new VideoCallError(VideoCallErrorDescription.noPeerConnection)
  const offer = await peerConnection.call()
  websocketCommandRTC('offer', offer)
  return offer
}

export async function peerAnswerCreate(
  offer: RTCSessionDescriptionInit
): Promise<RTCSessionDescriptionInit> {
  if (!peerConnection)
    throw new VideoCallError(VideoCallErrorDescription.noPeerConnection)
  const answer = await peerConnection.answer(offer)
  websocketCommandRTC('answer', answer)
  return answer
}

export async function peerAnswerAccept(
  answer: RTCSessionDescriptionInit
): Promise<void> {
  if (!peerConnection)
    throw new VideoCallError(VideoCallErrorDescription.noPeerConnection)
  await peerConnection.accept(answer)
}

export async function peerIceCandidateAdd(
  candidate: RTCIceCandidate
): Promise<void> {
  if (!peerConnection)
    throw new VideoCallError(VideoCallErrorDescription.noPeerConnection)
  await peerConnection.addIceCandidate(candidate)
}

function websocketCommandRTC(type: VideoCallSocketRTCCommand, payload: any) {
  const videoCallStore = useVideoCallStore()
  websocketEmit('rtc', {
    to: videoCallStore.remoteSocketId,
    payload,
    type,
  })
}

function onIceCandidate(candidate: RTCIceCandidate): void {
  const candidatePayload = {
    sdp: candidate.candidate, // Специально для ios и android
    sdpMid: candidate.sdpMid?.toString(),
    sdpMLineIndex: candidate.sdpMLineIndex?.toString(),
  }
  websocketCommandRTC('candidate', candidatePayload)
}

function onStreamRemote(stream: MediaStream): void {
  const videoCallStore = useVideoCallStore()
  if (!videoCallStore.peerRemoteStream) {
    videoCallStore.status = 'connected'
    videoCallStore.remoteMediaStreamSet(stream)
    videoCallEvent.baseEmit('streamReceived', { stream })
  }
}
