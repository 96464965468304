// for v2
import { AuthGender } from '../types'

const AUTH_GENDER_CACHE = 'auth-gender-cache-v2'

export function genderCacheSet(gender: AuthGender) {
  sessionStorage.setItem(AUTH_GENDER_CACHE, gender)
}

export function genderCacheGet(): AuthGender | null {
  return sessionStorage.getItem(AUTH_GENDER_CACHE) as AuthGender
}

export function genderCacheReset() {
  sessionStorage.removeItem(AUTH_GENDER_CACHE)
}
