import { useExperimentStore } from './experiment.store'

const EXPERIMENT_NAME = '20221220_paypal'
const PREMIUM_VARIANT_BASE = 'base'
const PREMIUM_VARIANT_A = 'paypal'

export function paypalExists() {
  const store = useExperimentStore()
  return !!store.experimentGet(EXPERIMENT_NAME)
}

export function paypalVariant(): string {
  const store = useExperimentStore()
  return store.experimentGet(EXPERIMENT_NAME) || PREMIUM_VARIANT_BASE
}

export function paypalCheckBase(): boolean {
  return paypalVariant() === PREMIUM_VARIANT_BASE
}

export function paypalCheckA(): boolean {
  return paypalVariant() === PREMIUM_VARIANT_A
}
