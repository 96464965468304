import { websocket } from '@/src/shared/lib/websocket'

type AlertFromServerWebsocketOnNotifyPayload = {
  m: string
}

const EMIT_NAME = 'notify'

export function useWebsocketOnNotify() {
  function callback(payload: AlertFromServerWebsocketOnNotifyPayload) {
    alert(payload.m)
  }
  return {
    on: () => {
      const client = websocket.client('application')
      client.on(EMIT_NAME, callback)
    },
    off: () => {
      const client = websocket.client('application')
      client.off(EMIT_NAME, callback)
    },
  }
}
