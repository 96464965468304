import { useExperimentStore } from './experiment.store'

const EXPERIMENT_NAME = '20230502_coomeet_premium'
const VARIANT_BASE = 'base'
const VARIANT_A = 'coomeet_premium_weekly'
const VARIANT_B = 'coomeet_premium_monthly'

export function coomeetPremiumExists() {
  const store = useExperimentStore()
  return !!store.experimentGet(EXPERIMENT_NAME)
}

export function coomeetPremiumVariant(): string {
  const store = useExperimentStore()
  return store.experimentGet(EXPERIMENT_NAME) || VARIANT_BASE
}

export function coomeetPremiumCheckBase(): boolean {
  return coomeetPremiumVariant() === VARIANT_BASE
}

export function coomeetPremiumCheckA(): boolean {
  return coomeetPremiumVariant() === VARIANT_A
}

export function coomeetPremiumCheckB(): boolean {
  return coomeetPremiumVariant() === VARIANT_B
}
