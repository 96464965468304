import { userBlacklistDelete } from '@/src/shared/api'

type BlocklistApiUserBlacklistDeleteResponse = {
  success: boolean
}

export async function remove(blocklistId: number): Promise<boolean> {
  const response = await userBlacklistDelete<BlocklistApiUserBlacklistDeleteResponse>(
    blocklistId
  )
  return response.isOk && (response.data?.success || false)
}
