import { onMounted, onBeforeUnmount, useContext } from '@nuxtjs/composition-api'
import { secondRoleGet } from './privateCallHelpers.setup'
import { userBlockModalEntity } from '@/src/entities/user-block-modal'
import { videoCallEntity } from '@/src/entities/video-call2'
import { privateCallEntity } from '@/src/entities/private-call'

export function useReport(callback: Function) {
  const { $analytics } = useContext()
  const videoCallStore = videoCallEntity.model.useVideoCallStore()

  function onCallback(
    payload: userBlockModalEntity.types.UserBlockModalSubmitPayload
  ) {
    if (!videoCallStore.remoteUser) {
      return
    }
    $analytics.event('videochat_report_pressed', {
      button: 'report',
      reason: payload.reason,
      secondrole: secondRoleGet(),
      secondid: videoCallStore.remoteUser?.userId,
      type: privateCallEntity.config.ANALYTICS_TYPE,
    })
    callback()
  }

  onMounted(() => userBlockModalEntity.model.modal.actionOnSubmit(onCallback))
  onBeforeUnmount(() =>
    userBlockModalEntity.model.modal.actionOffSubmit(onCallback)
  )
}
