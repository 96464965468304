import { MODAL_NAME, MODAL_ACTION_SUBMIT } from '../config'
import {
  UserBlockModalOpenPayload,
  UserBlockModalSubmitPayload,
} from '../types'
import { ModalExternalService } from '@/src/shared/lib/modal'
import { eventBus } from '@/src/shared/lib/eventBus'

class ModalService extends ModalExternalService {
  constructor() {
    super(MODAL_NAME, eventBus)
  }

  public open(payload: UserBlockModalOpenPayload) {
    super.open(payload)
  }

  public close() {
    super.close()
  }

  public actionOnSubmit(callback: Function) {
    this.actionOn(MODAL_ACTION_SUBMIT, callback)
  }

  public actionOffSubmit(callback: Function) {
    this.actionOff(MODAL_ACTION_SUBMIT, callback)
  }

  public actionSubmit(payload: UserBlockModalSubmitPayload) {
    this.action(MODAL_ACTION_SUBMIT, payload)
  }
}

export const modal = new ModalService()
