import { dialogRepository } from '../dialog/dialog.api.usecase'
import { IUserObject } from '../user'
import { IDialogThreadObject } from './dialog.types'
import {
  THREAD_LOAD_LIMIT,
  DialogThreadType,
  dialogThreadsLoading,
  dialogThreadsCanLoadMore,
  dialogThreadsPageCounter,
  dialogThreads,
} from './dialog.state'

export function useDialogThreads() {
  /**
   * Inject
   */

  /**
   * Variables
   */

  /**
   * Private
   */
  async function dialogThreadsLoad(page: number = 1): Promise<any> {
    const { success, responseError, data } = await dialogRepository.threadsGet(
      page,
      THREAD_LOAD_LIMIT
    )

    if (success && !responseError) {
      const threads = data
        .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
        .filter((thread) => !dialogThreadsGetById(thread.interlocutorId))
      dialogThreads.value = dialogThreads.value.concat(threads)
      dialogThreadsPageCounter.value++
    }
  }

  function dialogThreadsGetIndexById(userId: number): number {
    return dialogThreads.value.findIndex(
      (thread: IDialogThreadObject) => thread.interlocutorId === userId
    )
  }

  /**
   * Public
   */
  function dialogThreadsReset() {
    dialogThreadsLoading.value = false
    dialogThreadsCanLoadMore.value = true
    dialogThreadsPageCounter.value = 1
    dialogThreads.value = []
  }

  function dialogThreadsRemoveById(userId: number) {
    const index = dialogThreadsGetIndexById(userId)
    if (index === -1) return
    dialogThreads.value.splice(index, 1)
  }

  function dialogThreadsGetById(
    userId: number
  ): IDialogThreadObject | undefined {
    return dialogThreads.value.find(
      (threadTemp: IDialogThreadObject) => threadTemp.interlocutorId === userId
    )
  }

  function dialogThreadsSetById(
    userId: number,
    set: Partial<IDialogThreadObject>
  ) {
    const index = dialogThreads.value.findIndex(
      (threadTemp: IDialogThreadObject) => threadTemp.interlocutorId === userId
    )
    if (index === -1) return
    dialogThreads.value.splice(
      index,
      1,
      Object.assign({}, dialogThreads.value[index], set)
    )
  }

  function dialogThreadsAddFromUser(user: IUserObject) {
    const userSelect = dialogThreads.value.find(
      (thread: IDialogThreadObject) => thread.interlocutorId === user.id
    )
    if (userSelect) return
    const dialogThread: IDialogThreadObject = {
      interlocutorId: user.id,
      type: DialogThreadType.INITED,
      online: false,
      name: user.name,
      age: user.age,
      gender: user.gender,
      read: true,
      avatar: user.avatar?.urlS || user.avatar?.urlF || null,
      timestamp: Date.now(),
      lastMessage: '',
      lastMessageReadTimestamp: Date.now(),
      isVip: false,
    }
    dialogThreads.value.unshift(dialogThread)
  }

  // function dialogThreadSet(index: number, update: any): void {
  //   const thread = dialogThreads.value[index]
  //   dialogThreads.value.splice(index, 1, Object.assign(thread, update))
  // }

  async function dialogThreadsLoadFirst(): Promise<void> {
    if (dialogThreadsLoading.value) return
    dialogThreadsReset()
    dialogThreadsLoading.value = true
    await dialogThreadsLoad(dialogThreadsPageCounter.value)
    dialogThreadsLoading.value = false
  }

  async function dialogThreadsLoadMore(): Promise<void> {
    if (dialogThreadsLoading.value || !dialogThreadsCanLoadMore.value)
      return undefined
    dialogThreadsLoading.value = true
    const threadsLength = dialogThreads.value.length
    if (threadsLength < THREAD_LOAD_LIMIT) {
      dialogThreadsLoading.value = false
      return undefined
    }
    await dialogThreadsLoad(dialogThreadsPageCounter.value)
    dialogThreadsCanLoadMore.value =
      threadsLength !== dialogThreads.value.length
    dialogThreadsLoading.value = false
  }

  return {
    dialogThreadsReset,
    dialogThreadsLoadFirst,
    dialogThreadsLoadMore,
    dialogThreadsGetById,
    dialogThreadsSetById,
    dialogThreadsRemoveById,
    dialogThreadsAddFromUser,
  }
}
