import {
  privateCallsLoaded,
  privateCalls,
  privateCallEntryPath,
  privateCallStatus,
  privateCallDirection,
  privateCallCallerId,
  privateCallReceiverId,
  privateCallRemoteUser,
} from '.'

// export const privateCallApi = new PrivateCallApi()

export function usePrivateCallHelpers() {
  /**
   * Public functions
   */

  function privateCallResetState() {
    privateCallsLoaded.value = false
    privateCallStatus.value = ''
    privateCalls.value = []
    privateCallEntryPath.value = ''
    privateCallStatus.value = ''
    privateCallDirection.value = ''
    privateCallCallerId.value = 0
    privateCallReceiverId.value = 0
    privateCallRemoteUser.value = undefined
  }

  return {
    privateCallResetState,
  }
}
