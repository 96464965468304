import { useExperimentStore } from './experiment.store'

const EXPERIMENT_NAME = '20230412_cheap_bundles_old_users'
const VARIANT_BASE = 'base'
const VARIANT_A = 'cheap_bundles'

export function cheapBundlesOldExists() {
  const store = useExperimentStore()
  return !!store.experimentGet(EXPERIMENT_NAME)
}

export function cheapBundlesOldVariant(): string {
  const store = useExperimentStore()
  return store.experimentGet(EXPERIMENT_NAME) || VARIANT_BASE
}

export function cheapBundlesOldCheckBase(): boolean {
  return cheapBundlesOldVariant() === VARIANT_BASE
}

export function cheapBundlesOldCheckA(): boolean {
  return cheapBundlesOldVariant() === VARIANT_A
}
