import { FriendsItem } from '../types'
import { useFriendsStore } from './friends.store'
import { LanguageISO } from '@/src/shared/types'
import { userFavoritesRequestGet } from '@/src/shared/api'

type FriendsApiResponseDtoRoles = 'APP_USER' | 'APP_ACQUIRED' | 'APP_PATRON'

type FriendsApiResponseUserDto = {
  id: number
  name: string
  gender: 'Male' | 'Female'
  genderValue: 1 | 2
  age: number
  role: null | number
  roles: FriendsApiResponseDtoRoles[]
  videochatStatus: number
  online: boolean
  avatar: null | any
  locationCountryCode: LanguageISO
  connectionPrice: number
}

type FriendsApiResponse = {
  users: FriendsApiResponseUserDto[]
}

function friendsMapper(dto: FriendsApiResponseUserDto): FriendsItem {
  let gender: FriendsItem['gender'] = 'male'
  if (dto.gender === 'Female') gender = 'female'

  return {
    userId: dto.id,
    avatar: dto.avatar?.urlS || '',
    age: dto.age,
    name: dto.name,
    gender,
  }
}

export async function requestsLoad(): Promise<void> {
  const friendsStore = useFriendsStore()

  const response = await userFavoritesRequestGet<FriendsApiResponse>()
  friendsStore.items = []
  if (response.isOk && response.data?.users) {
    response.data.users.map(friendsMapper).forEach(friendsStore.itemPush)
  }

  friendsStore.loaded = true
}
