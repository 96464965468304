import { useAuthModalStore } from './modal.store'
import { preRegistrationFinishPost } from '@/src/shared/api'

export async function preRegisrationFinish(): Promise<boolean> {
  const store = useAuthModalStore()
  store.loading = true
  store.resetApi()
  const response = await preRegistrationFinishPost(store.email, store.password)
  const error = response.error || response.data?.error
  if (error) {
    if (error?.errorsDetails) {
      // TODO
    } else if (error?.message) {
      store.errorDetailPush('email', error.message)
    }
  }
  store.loading = false
  return response.isOk
}
