import { confirmEmailModalEntity } from '@/src/entities/modal/confirm-email-modal'
import { userEntity } from '@/src/entities/user'

export function openAndWaitClose(
  entry: confirmEmailModalEntity.types.ConfirmEmailModalEntry
): Promise<void> {
  return new Promise((resolve) => {
    confirmEmailModalEntity.model.open(entry)
    confirmEmailModalEntity.model.oneClose(() => resolve())
  })
}

export function openOnlyOne(
  user: userEntity.types.User,
  entry: confirmEmailModalEntity.types.ConfirmEmailModalEntry
): boolean {
  if (user.emailConfirmed || confirmEmailModalEntity.model.state.openCounts)
    return false
  confirmEmailModalEntity.model.open(entry)
  return true
}

export function openBeforePayment(
  user: userEntity.types.User,
  entry: confirmEmailModalEntity.types.ConfirmEmailModalEntry
): boolean {
  if (user.emailConfirmed) return false
  confirmEmailModalEntity.model.open(entry)
  return true
}

const { onClose, offClose } = confirmEmailModalEntity.model
export { onClose, offClose }
