import {
  IHttpAPI,
  IHttpRequestConfig,
  HttpResponseType,
  IHttpResponse,
  IHttpUrl,
  IHttpResponseState,
} from './http.types'
import { api } from '@/src/shared/lib/api'

export abstract class HttpRequest implements IHttpAPI {
  protected _host: string
  constructor(host: string) {
    this._host = host
  }

  public accessTokenSet(token: string): void {
    api.$axios.setToken(token, 'Bearer')
  }

  public accessTokenReset(): void {
    api.$axios.setToken(false)
  }

  public hostSet(host: string): void {
    this._host = host
  }

  public createURL(path: string): IHttpUrl {
    return new URL(`${this._host}/${path}`)
  }

  protected async request(config: IHttpRequestConfig): Promise<IHttpResponse> {
    let responseResult: IHttpResponse = {
      type: HttpResponseType.ResponseErrorUnknown,
    }
    try {
      const response = await api.$axios(config)
      responseResult = this.validate(response.status, response.data)
    } catch (error: any) {
      const status = error?.response?.status || error?.status
      switch (status) {
        case 401:
          responseResult = {
            type: HttpResponseType.ResponseErrorAuthintication,
            status,
          }
          break
        case 404:
          responseResult = {
            type: HttpResponseType.ResponseErrorNotFound,
            status,
          }
          break
        case 500:
          responseResult = {
            type: HttpResponseType.ResponseErrorServer,
            status,
          }
          break
      }
    }
    return responseResult
  }

  protected validate(status: number, data: any): IHttpResponse {
    if (data.error) {
      return {
        type: HttpResponseType.ResponseErrorValidate,
        status,
        data,
        error: data.error,
      }
    } else {
      return {
        type: HttpResponseType.ResponseSuccess,
        status,
        data,
      }
    }
  }

  public parse<S>(response: IHttpResponse): IHttpResponseState<S> {
    const state: IHttpResponseState<S> = {
      success: false,
      error: false,
      authError: false,
      response,
      responseData: undefined,
      responseError: undefined,
    }

    switch (response.type) {
      case HttpResponseType.ResponseSuccess:
        state.success = true
        state.responseData = response.data
        break
      case HttpResponseType.ResponseErrorValidate:
        state.error = true
        state.responseError = response.error
        break
      case HttpResponseType.ResponseErrorAuthintication:
        state.error = true
        state.authError = true
        break
      default:
        state.error = true
    }

    return state
  }

  public async get(
    url: IHttpUrl,
    config: IHttpRequestConfig = {}
  ): Promise<IHttpResponse> {
    return await this.request({
      method: 'GET',
      url: url.href,
      ...config,
    })
  }

  public async post(
    url: IHttpUrl,
    data: any,
    config: IHttpRequestConfig = {}
  ): Promise<IHttpResponse> {
    return await this.request({
      method: 'POST',
      url: url.href,
      data,
      ...config,
    })
  }

  public async delete(
    url: IHttpUrl,
    config: IHttpRequestConfig = {}
  ): Promise<IHttpResponse> {
    return await this.request({
      method: 'DELETE',
      url: url.href,
      ...config,
    })
  }
}
