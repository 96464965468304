import { useUserStore } from './user.store'
import { userMe } from '@/logic/user'

const APP_PATRON = 'APP_PATRON' // якщо були покупки
const APP_SUBSCRIBER = 'APP_SUBSCRIBER' // якщо є підписка
const APP_ACQUIRED = 'APP_ACQUIRED' // якщо платний трафік
const APP_SUSPECTED = 'APP_SUSPECTED' // якщо з України

export function isOrganic(): boolean {
  return !isPaid()
}

export function isPaid(): boolean {
  const store = useUserStore()
  if (userMe.value?.roles.includes(APP_ACQUIRED)) return true
  if (store.me?.roles.includes(APP_ACQUIRED)) return true
  return false
}

export function isSubscriber(): boolean {
  const store = useUserStore()
  if (userMe.value?.roles.includes(APP_SUBSCRIBER)) return true
  if (store.me?.roles.includes(APP_SUBSCRIBER)) return true
  return false
}

export function isPatron(): boolean {
  const store = useUserStore()
  if (userMe.value?.roles.includes(APP_PATRON)) return true
  if (store.me?.roles.includes(APP_PATRON)) return true
  return false
}

export function isSuspected(): boolean {
  const store = useUserStore()
  if (userMe.value?.roles.includes(APP_SUSPECTED)) return true
  if (store.me?.roles.includes(APP_SUSPECTED)) return true
  return false
}
