import { useRoute, useRouter } from '@nuxtjs/composition-api'
import { modalsClose } from './privateCallCloseModals.setup'
import { privateCallEntity } from '@/src/entities/private-call'
import { videoCallEntity } from '@/src/entities/video-call2'
import { ROUTE_NAV } from '@/src/shared/config'
import {
  dialogThreadNeedOpenEntry,
  dialogThreadNeedOpenId,
} from '@/logic/usecase'

export function useRedirectToCall() {
  const route = useRoute()
  const router = useRouter()

  return () => {
    if (route.value.path !== ROUTE_NAV.privateCall) {
      router.push(ROUTE_NAV.privateCall)
    }
    modalsClose()
  }
}

export function useRedirectFromCall() {
  const router = useRouter()
  const privateStore = privateCallEntity.model.usePrivateCallStore()
  const videoStore = videoCallEntity.model.useVideoCallStore()

  return () => {
    modalsClose()
    const entryPath = privateStore.entryPath
    switch (entryPath) {
      case 'dialogs':
        router.replace(ROUTE_NAV.messages)
        break
      case 'historycallsslidebar':
      case 'mainscreen':
        router.replace(ROUTE_NAV.main)
        break
      case 'favorites':
        router.replace(ROUTE_NAV.topProfiles)
        break
      case ROUTE_NAV.messages:
        if (!videoStore.remoteUser) {
          return
        }
        dialogThreadNeedOpenEntry.value = 'videochat'
        dialogThreadNeedOpenId.value =
          typeof videoStore.remoteUser?.userId === 'string'
            ? parseInt(videoStore.remoteUser?.userId)
            : videoStore.remoteUser?.userId
        router.replace(ROUTE_NAV.messages)
        break
      case ROUTE_NAV.main:
        router.replace(ROUTE_NAV.main)
        break
      default:
        router.replace(entryPath || (ROUTE_NAV.messages as any))
        break
    }
  }
}
