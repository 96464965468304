import {} from '@nuxtjs/composition-api'
import { AuthAccessTokenApi } from '../repository'
import { authUserIsAuth, authToken, authTokenCache } from '.'
import { useRouting, RoutingPath } from '@/logic/routing'

export const authAccessTokenApi = new AuthAccessTokenApi()

export function useAuth() {
  const routing = useRouting()

  async function authTokenCheck(redirect: boolean = true) {
    const authResponse = await authAccessTokenApi.tokenCheck()
    if (!authResponse) {
      if (redirect) {
        routing.pushPath(RoutingPath.login)
      }
      return false
    }
    authUserIsAuth.value = true
    return true
  }

  function authLogout() {
    authAccessTokenApi.accessTokenReset()
    authToken.value = undefined
    authTokenCache.removeToken()
    authUserIsAuth.value = false
  }

  return {
    authTokenCheck,
    authLogout,
  }
}
