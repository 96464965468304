import { appWarmup } from '../usecase'
import { uploaderRepository } from '../uploader/uploader.api.usecase'
import { IUploaderFile, UploaderType, uploaderFiles } from '.'

export function useUploader() {
  function uploaderGenerateName(prefix: string) {
    return prefix + Math.ceil(Math.random() * 10000000)
  }

  function uploaderByName(name: string): IUploaderFile | undefined {
    return uploaderFiles.value.find(
      (upload: IUploaderFile) => upload.name === name
    )
  }

  function uploaderSetByName(
    name: string,
    update: Partial<IUploaderFile>
  ): void {
    const index = uploaderFiles.value.findIndex(
      (upload: IUploaderFile) => upload.name === name
    )
    if (index === -1) throw new Error('no uploader file')

    uploaderFiles.value.splice(
      index,
      1,
      Object.assign(uploaderFiles.value[index], update)
    )
  }

  // function uploaderConvertBase64ToFile(base64: string): File {
  //   const blobImage = new Blob([base64], { type: 'image/png' })
  //   const imageFile = new File([blobImage], 'avatar.png', {
  //     type: 'image/png',
  //   })
  //   return imageFile
  // }

  function uploaderConvertBase64ToBlob(
    base64: string,
    contentType: string = 'image/png',
    sliceSize: number = 512
  ): Blob {
    const base64BlockSlit = base64.split(';')
    contentType = base64BlockSlit[0].split(':')[1]
    const realData = base64BlockSlit[1].split(',')[1]

    const byteCharacters = atob(realData)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  async function uploaderMessageImage(
    name: string,
    file: File,
    userId: number
  ): Promise<IUploaderFile | undefined> {
    const host = appWarmup.value?.uploadPhotoUrl
    const publicKey = appWarmup.value?.uploadPhotoPk
    if (!host || !publicKey) throw new Error('no upload information in warup')

    const uploadFileIndex = uploaderFiles.value.length
    const uploadFile: IUploaderFile = {
      name,
      size: file.size,
      progress: 0,
      id: 0,
      completed: false,
      type: UploaderType.THREAD,
      error: undefined,
    }
    uploaderFiles.value.push(uploadFile)

    const { success, data: photoId } = await uploaderRepository.messagePost(
      file,
      host,
      publicKey,
      userId,
      function (progress: number) {
        uploaderSetByName(name, { progress })
      }
    )

    if (success && photoId) {
      uploaderSetByName(name, {
        id: photoId,
        progress: 100,
        completed: true,
      })

      return uploaderFiles.value[uploadFileIndex]
    }

    return undefined
  }

  async function uploaderAvatar(
    name: string,
    file: File | Blob,
    userId: number
  ): Promise<IUploaderFile | undefined> {
    const host = appWarmup.value?.uploadPhotoUrl
    const publicKey = appWarmup.value?.uploadPhotoPk
    if (!host || !publicKey) throw new Error('no upload information in warup')

    const uploadFileIndex = uploaderFiles.value.length
    const uploadFile: IUploaderFile = {
      name,
      size: file.size,
      progress: 0,
      id: 0,
      completed: false,
      type: UploaderType.STREAMER_VERIFY_PHOTO,
      error: undefined,
    }
    uploaderFiles.value.push(uploadFile)
    const { success, data } = await uploaderRepository.avatarPost(
      file,
      host,
      publicKey,
      userId,
      function (progress: number) {
        uploaderSetByName(name, { progress })
      }
    )

    if (success && data) {
      uploaderSetByName(name, {
        id: data,
        progress: 100,
        completed: true,
      })

      return uploaderFiles.value[uploadFileIndex]
    }

    return undefined
  }

  return {
    uploaderGenerateName,
    uploaderByName,
    uploaderConvertBase64ToBlob,
    uploaderMessageImage,
    uploaderAvatar,
  }
}
