import { websocket } from '@/src/shared/lib/websocket'

export type VideoCallWebsocketSendStepPayload = {
  [key: string]: any
}

export function websocketSendStep(
  payload: VideoCallWebsocketSendStepPayload
): void {
  websocket.emitDefault('step', payload)
}
