import { useVideoCallStore } from './videoCall.store'
import { videoCallStatPost } from '@/src/shared/api'

type VideoCallStatSkipReason =
  | 'server_sent'
  | 'manual'
  | 'not_enough'
  | 'unexpected_error'

type VideoCallStatSkipType = 'send_skip' | 'receive_skip'

type VideoCallStatResponse = {
  success: boolean
}

export async function statSend(
  skipReason: VideoCallStatSkipReason,
  skipType: VideoCallStatSkipType,
  skipDetails?: string
): Promise<boolean> {
  const store = useVideoCallStore()

  const payload = {
    callId: store.remoteUser.callId,
    peerUserId: Number(store.remoteUser.userId),
    skipReason,
    skipType,
    duration: store.timerSec,
    skipDetails,
  }
  const response = await videoCallStatPost<VideoCallStatResponse>(payload)
  return response.isOk && response.data.success
}
