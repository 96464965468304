import { onMounted, onBeforeUnmount } from '@nuxtjs/composition-api'
import { EVENTBUS_CONTEXT, EVENTBUS_COMMAND_BASE } from '../config'
import { eventBus } from '@/src/shared/lib/eventBus'

type VideoCallEventBusOnNextCallback = () => void

const EVENTBUS_TARGET = 'next'

function generateTopicName(): string {
  return eventBus.nameGenerate(
    EVENTBUS_CONTEXT,
    EVENTBUS_TARGET,
    EVENTBUS_COMMAND_BASE
  )
}

export function useEventBusOnNext(callback: VideoCallEventBusOnNextCallback) {
  const topicName = generateTopicName()
  const off = () => eventBus.off(topicName, callback)
  onMounted(() => eventBus.on(topicName, callback))
  onBeforeUnmount(() => off())
  return {
    off,
  }
}

export function eventBusSendNext() {
  const topicName = generateTopicName()
  eventBus.emit(topicName)
}
