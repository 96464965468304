import {
  IGoogleAuthService,
  GoogleAuthUser,
  GoogleAuthError,
  GoogleAuthSignInResponse,
} from './googleAuth.types'

export class GoogleAuth implements IGoogleAuthService {
  protected clientId: string
  protected googleAuth: any

  constructor(clientId: string) {
    this.clientId = clientId
  }

  public async init(): Promise<boolean> {
    const initScriptPromise = this.initScript()
    if (this.initScriptSDK()) {
      await initScriptPromise
    }
    return await this.initAuth()
  }

  initScript() {
    return new Promise((resolve) => {
      const _window = window as any
      _window.initGoogleSignin = function () {
        _window.gapi.load('auth2', resolve)
      }
    })
  }

  initScriptSDK() {
    if (document.getElementById('google-jssdk')) return false
    const js = document.createElement('script')
    js.id = 'google-jssdk'
    js.src = 'https://apis.google.com/js/platform.js?onload=initGoogleSignin'
    document.querySelector('head')?.appendChild(js)
    // add meta tag
    const meta = document.createElement('meta')
    meta.name = 'google-signin-client_id'
    meta.content = this.clientId
    document.querySelector('head')?.appendChild(meta)
    return true
  }

  async initAuth() {
    const _window = window as any
    try {
      await _window.gapi.auth2.init({
        client_id: this.clientId,
      })
      this.googleAuth = _window.gapi.auth2.getAuthInstance()
      return true
    } catch (err) {
      return false
    }
  }

  // https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams
  async authMethod(method: string, options?: any) {
    try {
      return await this.googleAuth[method](options)
    } catch (err: any) {
      return {
        error: err.error,
      }
    }
  }

  // https://developers.google.com/identity/sign-in/web/reference#users
  // https://developers.google.com/identity/sign-in/web/reference#googleusergetbasicprofile
  userProfile(): GoogleAuthUser {
    const currentUser = this.googleAuth.currentUser.get()
    const baseProfile = currentUser.getBasicProfile()
    return {
      type: 'success',
      id: baseProfile.getId(),
      name: baseProfile.getName(),
      firstName: baseProfile.getGivenName(),
      lastName: baseProfile.getFamilyName(),
      avatar: baseProfile.getImageUrl(),
      email: baseProfile.getEmail(),
    }
  }

  public async signIn(): Promise<GoogleAuthSignInResponse | GoogleAuthError> {
    const popupResponse = await this.authMethod('signIn')
    if (popupResponse.error) {
      const response: GoogleAuthError = {
        type: 'error',
        error: popupResponse.error,
      }
      return response
    }
    return this.userProfile()
  }
}
