import { websocket } from '@/src/shared/lib/websocket'

export interface VideoCallWebsocketSendRtc {
  init: any
  offer: RTCSessionDescriptionInit
  answer: RTCSessionDescriptionInit
  candidate: any
}

export function websocketSendRtc<T extends keyof VideoCallWebsocketSendRtc>(
  remoteSocketId: string,
  type: T,
  payload: VideoCallWebsocketSendRtc[T]
): void {
  websocket.emitDefault('rtc', {
    to: remoteSocketId,
    payload,
    type,
  })
}
