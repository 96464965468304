import { VideoCallPayloadRegister } from '../types'
import { useVideoCallStore } from './videoCall.store'
import {
  websocketSocketId,
  websocketEmit,
} from './videoCallWebsocketEmit.setup'
import { languageBrowser } from '@/src/shared/lib/languageBrowser'

export function registerEmit(payload: VideoCallPayloadRegister) {
  // const videoCallStore = useVideoCallStore()
  websocketEmit('register', payload)
  // videoCallStore.status = 'register'
  return payload
}

export function registerRandom(
  lookingFor: number[] = [2]
): VideoCallPayloadRegister {
  const videoCallStore = useVideoCallStore()
  videoCallStore.target = 'random'
  return registerPayloadGenerate({
    lookingFor,
    callType: 1,
  })
}

export function registerPrivate(
  peerUserId: number,
  callerId: number
): VideoCallPayloadRegister {
  const videoCallStore = useVideoCallStore()
  videoCallStore.target = 'private'
  return registerPayloadGenerate({
    peerUserId,
    callerId,
    callType: 2,
  })
}

function registerPayloadGenerate(
  setObj: Partial<VideoCallPayloadRegister>
): VideoCallPayloadRegister {
  const payload: VideoCallPayloadRegister = Object.assign(
    {
      userId: undefined,
      name: undefined,
      age: undefined,
      gender: 1,
      lookingFor: [2],
      socketId: websocketSocketId(),
      status: 1,
      imageUrl: null,
      callType: null,
      platform: 'web',
      isHost: false,
      appVersion: 0, // TODO
      application: process.env.APPLICATION,
      locale: languageBrowser(),
      hasPurchase: false,
      streamerStatus: undefined,
      agencyId: undefined,
      category: undefined,
      debuggable: !process.env.IS_PROD,
    },
    setObj
  )
  return payload
}
