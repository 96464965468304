export function convertBase64ToBlob(
  base64: string,
  contentType: string = 'image/png',
  sliceSize: number = 512
): Blob {
  const base64BlockSlit = base64.split(';')
  contentType = base64BlockSlit[0].split(':')[1]
  const realData = base64BlockSlit[1].split(',')[1]

  const byteCharacters = atob(realData)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}
