import {
  ref,
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
  useContext,
} from '@nuxtjs/composition-api'
import { ModalEmits, ModalActionEmits } from '../modal'
import { useDialogThreads } from '../dialog/dialogThreads.usecase'
import { useDialogThread } from '../dialog/dialogThread.usecase'
import { useDialogThreadMessages } from '../dialog/dialogThreadMessages.usecase'
import { billingBalance } from '../billing/billing.state'
import {
  dialogNotificationPrivateCalls,
  dialogActive,
  dialogThreads,
  dialogPrivateCallsShow,
  dialogThreadNeedOpenId,
  dialogThreadNeedOpenEntry,
  dialogThreadsCanLoadMore,
  dialogThreadsLoading,
  dialogThreadSelectedId,
  dialogThreadSelectedObject,
  dialogThreadMessagesLoading,
  dialogThreadMessages,
} from '../dialog/dialog.state'
import { useLayout, appLoading, privateCallsParsed } from '.'
import { useRouting, RoutingPath } from '@/logic/routing'

import { appEntity } from '@/src/entities/app'
import { userEntity } from '@/src/entities/user'
import { chatBlockingModalEntity } from '@/src/entities/modal/chat-blocking-modal'
import { giftsModalEntity } from '@/src/entities/gifts-modal'
import { friendsEntity } from '@/src/entities/friends'

import { privateCallFeature } from '@/src/features/private-call'
import { mediaFeature } from '@/src/features/media'
import { paymentFeature } from '@/src/features/payment'

export function useDialogMessages() {
  const routing = useRouting()
  const { isDesktop } = useLayout()
  const { $modalOld, $analytics, $modal } = useContext()
  const {
    dialogThreadsReset,
    dialogThreadsLoadFirst,
    dialogThreadsLoadMore,
  } = useDialogThreads()
  const {
    dialogThreadReset,
    dialogThreadSelectCheckId,
    dialogThreadSelectById,
    dialogThreadUnlockById,
    dialogThreadDeleteById,
  } = useDialogThread()
  const {
    dialogThreadMessagesReset,
    dialogThreadMessagesLoadMore,
    dialogThreadMessageSendText,
    dialogThreadMessageSendGift,
    dialogThreadMessageSendImage,
  } = useDialogThreadMessages()

  const friendsStore = friendsEntity.model.useFriendsStore()

  const isReady = ref(false)
  const isEmpty = computed(
    () => friendsStore.isEmpty && dialogThreads.value.length === 0
  )
  const isThreadsShow = computed(() => {
    if (isDesktop.value) return true
    if (dialogPrivateCallsShow.value) return false
    if (isFriendsOpen.value) return false
    return !dialogThreadSelectedObject.value
  })
  const isDialogShow = computed(() => {
    if (dialogPrivateCallsShow.value) return false
    if (isDesktop.value) return true
    if (isFriendsOpen.value) return true
    return !!dialogThreadSelectedObject.value
  })
  const isDialogNotSelectShow = computed(() => {
    if (dialogPrivateCallsShow.value) return false
    if (
      isDesktop.value &&
      !dialogThreadSelectedObject.value &&
      !isFriendsOpen.value
    )
      return true
    return false
  })
  const isDialogMessagesEmpty = computed(() => {
    if (dialogPrivateCallsShow.value) return false
    return !messagesParsed.value.length
  })
  const isDialogMessagesLoading = computed(
    () => dialogThreadMessagesLoading.value
  )
  const selectedThreadName = computed(
    () => dialogThreadSelectedObject.value?.name ?? ''
  )
  const selectedThreadAvatar = computed(
    () => dialogThreadSelectedObject.value?.avatar ?? ''
  )
  const selectedThreadGender = computed(
    () => dialogThreadSelectedObject.value?.gender ?? ''
  )
  const selectedIsOnline = computed(
    () => dialogThreadSelectedObject.value?.online ?? false
  )
  const selectedUnlockShow = computed(
    () => dialogThreadSelectedObject.value?.unlockShow ?? false
  )
  const selectedUnlockPrice = computed(
    () => dialogThreadSelectedObject.value?.unlockPrice ?? 0
  )
  const messagesParsed = computed(() => {
    if (!dialogThreadSelectedObject.value) return []
    const thread = dialogThreadSelectedObject.value
    return dialogThreadMessages.value.map((message: any) => {
      const messageObj = Object.assign({}, message)
      if (thread.lastMessageReadTimestamp >= messageObj.timestamp) {
        messageObj.status = 'viewed'
      }
      return messageObj
    })
  })
  const isFriendsOpen = computed(() => friendsStore.selected)
  const isFriendRemove = computed(
    () => (dialogThreadSelectedObject.value?.favoriteState || 0) > 0
  )
  const showBuyPremium = computed(() => !userEntity.model.isPatron())

  const createPrivateCall = privateCallFeature.model.useCreateCall()
  const mediaInit = mediaFeature.model.useMediaInit()
  const mediaGetPermission = mediaFeature.model.useMediaGetPermission()

  function dialogReset() {
    dialogActive.value = false
    dialogThreadNeedOpenId.value = 0
    dialogThreadNeedOpenEntry.value = ''
  }

  async function privateCallCreate(userId: number, bHistoryCall: boolean) {
    // Лоудер
    appEntity.model.loaderOn()
    // Ініцілізація медія
    const mediaResult = await mediaInit()
    if (!mediaResult) return appEntity.model.loaderOff()
    // Отримання доступа к медія
    const mediaPermissionResult = await mediaGetPermission()
    if (!mediaPermissionResult) return appEntity.model.loaderOff()
    // Створення дзвінка
    const callResponse = await createPrivateCall(
      userId,
      bHistoryCall ? 'historycalls' : 'dialogs'
    )
    if (!callResponse) {
      mediaFeature.model.destroy()
      return appEntity.model.loaderOff()
    }
  }

  /**
   * Public
   */
  async function onSelectThreadFriends() {
    appLoading.value = true
    friendsStore.selected = true
    dialogThreadReset()
    dialogThreadMessagesReset()
    await friendsEntity.model.requestsLoad()
    appLoading.value = false
  }

  async function onSelectThread(userId: number) {
    // Если диалог выбрать тогда игнор
    friendsStore.selected = false
    if (dialogThreadSelectCheckId(userId)) return
    dialogPrivateCallsShow.value = false
    appLoading.value = true
    await dialogThreadSelectById(userId)
    $analytics.event('dialogs_screen_pressed', {
      button: 'secondprofile',
      secondid: userId,
      view: dialogThreadSelectedObject.value?.unlockShow ? 'lock' : 'unlock',
    })
    appLoading.value = false
  }

  async function onFileAttach(file: File) {
    if (!file) return
    // appLoading.value = true
    // dialogFileAttach(file)
    $analytics.event('dialogs_message_sent', {
      type: 'media',
      secondrole: dialogThreadSelectedObject.value?.isHost ? 'host' : 'user',
      secondid: dialogThreadSelectedId.value,
    })
    await dialogThreadMessageSendImage(file)
    appLoading.value = false
  }

  function onClickEmpty() {
    routing.pushPath(RoutingPath.main)
  }

  function onBackPrivateCalls() {
    dialogPrivateCallsShow.value = false
  }

  function onCallToPrivate(receiverId: number) {
    privateCallCreate(receiverId, true)
  }

  function onOpenFile() {
    appLoading.value = true
    setTimeout(() => (appLoading.value = false), 1000)
    $analytics.event('dialogs_screen_pressed', {
      button: 'image',
    })
  }

  function onOpenSettings() {
    $analytics.event('dialogs_screen_pressed', {
      button: 'delete',
    })
  }

  function onOpenDeleting() {
    $modalOld.open(ModalEmits.dialogDelete, {
      toUserId: dialogThreadSelectedId.value,
    })
  }

  async function onConfirmDeleteDialog() {
    appLoading.value = true
    await dialogThreadDeleteById()
    appLoading.value = false
  }

  function onOpenBlocking() {
    $analytics.event('dialogs_screen_pressed', {
      button: 'block',
    })
    const payload = {
      userId: dialogThreadSelectedId.value,
      userName: dialogThreadSelectedObject.value?.name || '',
    }
    $modal.open<chatBlockingModalEntity.types.ChatBlockingModalOpenPayload>(
      'chat-blocking',
      payload
    )
  }

  function onOpenReporting() {
    $analytics.event('dialogs_screen_pressed', {
      button: 'report',
    })
    $modalOld.open(ModalEmits.dialogReporting, {
      toUserId: dialogThreadSelectedId.value,
    })
  }

  function checkDialogActive(userId: number): boolean {
    return dialogThreadSelectedId.value === userId
  }

  async function onMessageSend(message: string) {
    dialogThreadsCanLoadMore.value = false
    $analytics.event('dialogs_message_sent', {
      type: 'text',
      secondrole: dialogThreadSelectedObject.value?.isHost ? 'host' : 'user',
      secondid: dialogThreadSelectedId.value,
    })
    await dialogThreadMessageSendText(message)
    dialogThreadsCanLoadMore.value = true
  }

  function onGiftModalOpen() {
    $analytics.event('dialogs_screen_pressed', {
      button: 'gift',
    })
    giftsModalEntity.model.modal.open({
      entry: 'messagesGifts',
      toUserId: dialogThreadSelectedId.value,
    })
  }

  async function onGiftModalSelected(
    payload: giftsModalEntity.types.GiftModalSelectPayload
  ) {
    appLoading.value = true
    await dialogThreadMessageSendGift(
      payload.gift.id,
      payload.gift.imageSrc,
      payload.gift.price
    )
    appLoading.value = false
  }

  function onClickThreadBack() {
    dialogThreadReset()
    dialogThreadMessagesReset()
  }

  function onFriendsBack() {
    friendsStore.selected = false
    dialogThreadReset()
    dialogThreadMessagesReset()
  }

  async function onFriendsLoad() {
    await dialogThreadsLoadFirst()
  }

  async function onRemoveFriends() {
    if (!dialogThreadSelectedId.value) return
    appLoading.value = true
    const userId = dialogThreadSelectedId.value
    await friendsEntity.model.requestDecline(userId)
    appLoading.value = false
  }

  function onCallToPrivateInDialog() {
    const userId = dialogThreadSelectedId.value
    if (!userId) return undefined
    privateCallCreate(userId, false)
  }

  function onMessageScrollTop() {
    dialogThreadMessagesLoadMore()
  }

  async function onUnlockMessages() {
    $analytics.event('dialogs_screen_pressed', {
      button: 'unlock',
    })
    if (selectedUnlockPrice.value > billingBalance.value) {
      paymentFeature.open('dialogUnlock')
      return
    }
    appLoading.value = true
    await dialogThreadUnlockById()
    appLoading.value = false
  }

  async function onDialogBlocked() {
    isReady.value = false
    appLoading.value = true
    await dialogThreadsLoadFirst()
    dialogThreadSelectedObject.value = undefined
    dialogThreadSelectedId.value = 0
    appLoading.value = false
    isReady.value = true
  }

  function onPremium() {
    switch (dialogThreadSelectedObject.value?.context?.premium_ver3) {
      case 'favorite_add':
        paymentFeature.open('premiumv3_favourites')
        break
      case 'story_like':
        paymentFeature.open('premiumv3_likes')
        break
      case 'not_enough_money_for_private_call':
        paymentFeature.open('premiumv3_private')
        break
      default:
        paymentFeature.open('premium_messages')
    }
  }

  function onProfile() {
    const userId = dialogThreadSelectedId.value
    $modal.open('streamer-profile', {
      streamerId: userId,
      avatar: dialogThreadSelectedObject.value?.avatar || '',
    })
  }

  function setupIntersectionObserver() {
    const rootEl = document.getElementById('asideWrapper')
    if (!rootEl) return

    const options = {
      root: rootEl,
      rootMargin: '0px',
      threshold: 0.1,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target)
          const lastTarget = rootEl.lastElementChild
          const lastItemIntersecting = entry.target === lastTarget
          if (lastItemIntersecting) {
            await dialogThreadsLoadMore()
            observer.observe(rootEl.lastElementChild as Element)
          }
        }
      })
    }, options)

    observer.observe(rootEl.lastElementChild as Element)
  }

  onBeforeMount(() => {
    appLoading.value = true
    dialogPrivateCallsShow.value = false
  })

  onMounted(async () => {
    const userId = dialogThreadNeedOpenId.value
    const entry = dialogThreadNeedOpenEntry.value
    if (userId && entry) {
      $analytics.event('dialogs_screen_shown', {
        entry,
      })
    } else {
      $analytics.event('dialogs_screen_shown')
    }
    dialogThreadNeedOpenId.value = 0
    dialogThreadNeedOpenEntry.value = ''
    dialogActive.value = true
    $modalOld.actionOn(
      ModalEmits.dialogDelete,
      ModalActionEmits.dialogDeleteConfirm,
      onConfirmDeleteDialog
    )
    giftsModalEntity.model.modal.actionOnSelect(onGiftModalSelected)
    $modal.onConfirm('chat-blocking', onDialogBlocked)
    await dialogThreadsLoadFirst()
    await friendsEntity.model.requestsLoad()
    if (userId) await dialogThreadSelectById(userId)
    isReady.value = true
    await nextTick()
    setupIntersectionObserver()
    appLoading.value = false
  })

  onBeforeUnmount(() => {
    dialogReset()
    dialogThreadsReset()
    dialogThreadReset()
    dialogThreadMessagesReset()
    $modalOld.actionOff(
      ModalEmits.dialogDelete,
      ModalActionEmits.dialogDeleteConfirm,
      onConfirmDeleteDialog
    )
    giftsModalEntity.model.modal.actionOffSelect(onGiftModalSelected)
  })

  return {
    appLoading,
    isDesktop,
    isReady,
    isEmpty,
    isThreadsShow,
    isDialogShow,
    dialogThreadsLoading,
    isDialogNotSelectShow,
    isDialogMessagesLoading,
    isDialogMessagesEmpty,
    isFriendsOpen,
    isFriendRemove,
    selectedThreadName,
    selectedThreadAvatar,
    selectedThreadGender,
    selectedIsOnline,
    selectedUnlockShow,
    selectedUnlockPrice,
    dialogNotificationPrivateCalls,
    dialogPrivateCallsShow,
    dialogThreads,
    messagesParsed,
    privateCallsParsed,
    checkDialogActive,
    showBuyPremium,
    onSelectThreadFriends,
    onSelectThread,
    onClickThreadBack,
    onCallToPrivateInDialog,
    onOpenSettings,
    onOpenDeleting,
    onOpenBlocking,
    onOpenReporting,
    onGiftModalOpen,
    onMessageSend,
    onMessageScrollTop,
    onFileAttach,
    onClickEmpty,
    onBackPrivateCalls,
    onCallToPrivate,
    onOpenFile,
    onUnlockMessages,
    onPremium,
    onProfile,
    onFriendsBack,
    onFriendsLoad,
    dialogThreadsLoadMore,
    setupIntersectionObserver,
    onRemoveFriends,
  }
}
