import { IHttpResponse } from '../core/http/http.types'
import { EVENT_AUTH, EVENT_ERROR, EVENT_LOGOUT } from './auth.state'
import { IEventBus, eventBus } from '@/src/shared/lib/eventBus'

export class AuthResponseError {
  protected _eventBus: IEventBus
  protected _emitName: string
  constructor() {
    this._eventBus = eventBus
    this._emitName = this._eventBus.nameGenerate(
      EVENT_AUTH,
      EVENT_ERROR,
      EVENT_LOGOUT
    )
  }

  public emit(response: IHttpResponse) {
    this._eventBus.emit<IHttpResponse>(this._emitName, response)
  }

  public on(callback: Function) {
    this._eventBus.on(this._emitName, callback)
  }

  public off(callback: Function) {
    this._eventBus.off(this._emitName, callback)
  }
}

class AuthResponseError2 {
  protected _eventBus: IEventBus
  protected _emitName: string
  constructor() {
    this._eventBus = eventBus
    this._emitName = this._eventBus.nameGenerate(
      EVENT_AUTH,
      EVENT_ERROR,
      EVENT_LOGOUT
    )
  }

  public emit<P>(payload?: P) {
    this._eventBus.emit<P | undefined>(this._emitName, payload)
  }

  public on(callback: Function) {
    this._eventBus.on(this._emitName, callback)
  }

  public off(callback: Function) {
    this._eventBus.off(this._emitName, callback)
  }
}

export const authResponseError = new AuthResponseError2()
