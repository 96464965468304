import { useRouter } from '@nuxtjs/composition-api'
import { tokenGet } from './token.service'
import { tokenIsValid } from './tokenCheck.service'
import { ROUTE_NAV2 as ROUTE_NAV } from '@/src/shared/config'

export function useToken() {
  const router = useRouter()

  async function checkAndRedirect(): Promise<boolean> {
    const response = await tokenIsValid()
    if (!response) {
      router.push(ROUTE_NAV.authGender)
      return false
    }
    return true
  }

  return {
    checkAndRedirect,
    get: tokenGet,
  }
}
