import { defineStore } from 'pinia'
import { ChatStoreState, ChatThread, ChatThreadSelected } from '../types'

export const useChatStore = defineStore('chat', {
  state: (): ChatStoreState => {
    return {
      ready: false,
      threads: [
        // {
        //   id: 1,
        //   createdAt: Date.now() - 22 * 60 * 60 * 1000,
        //   online: false,
        //   gender: 'female',
        //   name: 'Inna',
        //   age: 26,
        // },
      ],
      threadSelected: undefined,
      users: [],
    }
  },
  getters: {
    isEmpty: (state) => state.ready && !state.threads.length,
    isOpenThread: (state) => !!state.threadSelected,
    isActiveThread: (state) => (id: number) => state.threadSelected?.id === id,
  },
  actions: {
    threadPush(thread: ChatThread) {
      this.threads.push(thread)
    },
    selectThreadSet(threadSelect?: ChatThreadSelected) {
      this.threadSelected = threadSelect
    },
  },
})
