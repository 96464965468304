import { defineStore } from 'pinia'
import { Gift } from '../types'

type GiftsStoreState = {
  loaded: boolean
  gifts: Gift[]
}

export const useGiftsStore = defineStore('gifts', {
  state: (): GiftsStoreState => {
    return {
      loaded: false,
      gifts: [],
    }
  },
  actions: {
    giftAdd(gift: Gift) {
      this.gifts.push(gift)
    },
  },
})
