import { HttpResponseType } from '../core/http'
import { IHttpAPI, HttpAuthRequest } from '@/logic/core/http'
import { authToken, authTokenCache } from '@/logic/auth'

export class AuthAccessTokenApi extends HttpAuthRequest implements IHttpAPI {
  public async tokenCheck(): Promise<boolean> {
    if (!authTokenCache.accessToken) return false
    this.accessTokenSet(authTokenCache.accessToken)
    if (!authTokenCache.isExpires) return true
    await this.tokenRefreshPOST()
    return !authTokenCache.isExpires
  }

  public async tokenRefreshPOST(): Promise<void> {
    const url = this.createURL('refresh')

    const formData = new FormData()
    formData.append('access_token', authTokenCache.accessToken)
    formData.append('refresh_token', authTokenCache.refreshToken)

    const response = await this.post(url, formData)
    if (response.type === HttpResponseType.ResponseErrorAuthintication) {
      authToken.value = undefined
      this.accessTokenReset()
    }
    if (response.type === HttpResponseType.ResponseSuccess) {
      authToken.value = {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        expiresAt: response.data.expires,
      }
      this.accessTokenSet(response.data.accessToken)
    }
  }
}
