import { UserAvatarMapper } from '../user'
import { IDialogThreadDto, IDialogThreadObject } from '.'

export class DialogThreadMapper {
  public static apiDomain(dto: IDialogThreadDto): IDialogThreadObject {
    return {
      interlocutorId: dto.user.id,
      type: dto.threadType,
      online: dto.user.isOnline,
      avatar: UserAvatarMapper.dtoToSmallString(dto.user.avatar),
      name: dto.user.name,
      gender: dto.user.gender,
      age: 0,
      read: dto.read === 1,
      timestamp: dto.ts,
      isVip: dto.user.hasPurchase,
      lastMessage: dto.lastMessage,
      lastMessageReadTimestamp: dto.userReadTime,
    }
  }

  public static apiToDomains(
    dtoArr: IDialogThreadDto[]
  ): IDialogThreadObject[] {
    return dtoArr.map((dto) => this.apiDomain(dto))
  }
}
