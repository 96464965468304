import { useContext, watch, onBeforeUnmount } from '@nuxtjs/composition-api'
import { videoCallEntity } from '@/src/entities/video-call2'
import { privateCallEntity } from '@/src/entities/private-call'

export function privateCallUseStepFriendRequest() {
  const { $modal } = useContext()
  const videoCallStore = videoCallEntity.model.useVideoCallStore()
  const privateCallStore = privateCallEntity.model.usePrivateCallStore()
  let modalShouldBeShowed = false

  onBeforeUnmount(() => {
    eventHandlerSubscription.off()
    unwatchCallTimer()
    unwatchStatusWatcher()
  })

  const unwatchStatusWatcher = watch(
    () => privateCallStore.status,
    (callStatus) => {
      if (callStatus !== 'active') {
        $modal.close('push-to-send-request')
      }
    }
  )

  const unwatchCallTimer = watch(
    () => videoCallStore.timerSec,
    (callTimer: number) => {
      const maxTimeSecToShowModal = 15
      modalShouldBeShowed =
        callTimer >= maxTimeSecToShowModal && !videoCallStore.remoteInFavorite
    }
  )

  const eventHandlerSubscription = videoCallEntity.model.useEventBusOnStep(
    function (step: any) {
      if (step.name !== 'friend_request') return
      const modalPayload = {
        userId: videoCallStore.remoteUser.userId,
        userName: videoCallStore.remoteUser.name,
        avatarSrc: videoCallStore.remoteUser.imageUrl,
      }

      if (modalShouldBeShowed) $modal.open('push-to-send-request', modalPayload)
    }
  )
}
