import {
  GoogleAuth,
  GoogleAuthSignInResponse,
  GoogleAuthError,
} from '../lib/googleAuth'

let googleClientCache: GoogleAuth | undefined
let clientActived = false

export async function googleInit(): Promise<boolean> {
  if (!googleClientCache) {
    googleClientCache = new GoogleAuth(
      process.env.GOOGLE_OAUTH_CLIENT_ID as string
    )
  }
  if (!clientActived) {
    clientActived = await googleClientCache.init()
  }
  return clientActived
}

export async function googleSignIn(): Promise<
  GoogleAuthSignInResponse | GoogleAuthError
> {
  if (!googleClientCache) throw new Error('todo authGoole.signIn')
  const response = await googleClientCache.signIn()
  return response
}
