import { BlocklistUser, BlocklistUserAvatar } from '../types'
import { useBlocklistStore } from './blocklist.store'
import { userBlacklistGet } from '@/src/shared/api'

type BlocklistApiUserBlacklistGetResponseUserAvatar = {
  aspect: number // игнорировать
  id: number
  type: 1 // игнорировать
  urlF?: string // origin
  urlS?: string // medium 200x200
  urlB?: string // big 800x800
  urlO?: string //
  urlT?: string // small 70x70
}
type BlocklistApiUserBlacklistGetResponseUser = {
  id: number
  age: number
  name: string
  avatar: BlocklistApiUserBlacklistGetResponseUserAvatar | null
  // eslint-disable-next-line camelcase
  streamer_photo_validation_status: number
}
type BlocklistApiUserBlacklistGetResponse = {
  users: BlocklistApiUserBlacklistGetResponseUser[]
}

function blocklistUserMapper(
  dto: BlocklistApiUserBlacklistGetResponseUser
): BlocklistUser {
  let avatar: BlocklistUserAvatar | null = null
  if (dto.avatar) {
    avatar = {
      id: dto.avatar.id,
      src: dto.avatar.urlS || dto.avatar.urlF || '',
    }
  }

  return {
    id: dto.id,
    age: dto.age,
    name: dto.name,
    avatar,
  }
}

export async function load() {
  const store = useBlocklistStore()
  const response = await userBlacklistGet<BlocklistApiUserBlacklistGetResponse>()
  store.$reset()
  store.loaded = true
  if (response.isOk) {
    response.data?.users.map(blocklistUserMapper).forEach(store.userAdd)
  }
}
