import { LanguageISO } from '../../types'
import { TRANSLATE_SEPERATE } from '../../config'
import { googleTransalteApi } from './googleTranslate.api'

export async function translateText(
  remoteLanguage: LanguageISO,
  localLanguage: LanguageISO,
  text: string
): Promise<string> {
  if (!localLanguage || !remoteLanguage || remoteLanguage === localLanguage) {
    return Promise.resolve(text)
  }

  const response = await googleTransalteApi.messageText(text, remoteLanguage)
  const answer = response.data.translations[0]
  if (process.env.NODE_ENV !== 'production') {
    console.group('languageTranslateText')
    console.log(
      'localeLocal=%s remoteLanguage=%s detectedSourceLanguage=%s',
      localLanguage,
      remoteLanguage,
      answer.detectedSourceLanguage
    )
    console.log(text)
    console.log(answer.translatedText)
    console.groupEnd()
  }

  const returnText = [text]
  if (remoteLanguage !== answer.detectedSourceLanguage) {
    returnText.push(TRANSLATE_SEPERATE)
    returnText.push(answer.translatedText)
  }
  return returnText.join('')
}
