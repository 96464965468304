import { randomCallScreen, randomCallGenderFilter, randomCallStatus } from '.'

export function useRandomCallHelpers() {
  /**
   * Public functions
   */
  function randomCallResetState() {
    randomCallScreen.value = 'main'
    randomCallGenderFilter.value = 'women'
    randomCallStatus.value = 'none'
  }

  return {
    randomCallResetState,
  }
}
