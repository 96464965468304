import { IHttpAPI, HttpAppRequest, IHttpResponse } from '@/logic/core/http'

export class BillingApi extends HttpAppRequest implements IHttpAPI {
  async balanceGET(): Promise<IHttpResponse> {
    const url = this.createURL('user/balance')
    return await this.get(url)
  }

  async cardsGET(): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/card')
    return await this.get(url)
  }

  async transactionGET(): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/transaction')
    return await this.get(url)
  }

  async refundPOST(orderId: string): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/refund')
    const formData = {
      order_id: orderId,
    }
    return await this.post(url, formData)
  }

  async initPaymentPOST(productId: string): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/init_payment')
    const formData = {
      product_id: productId,
      context: {
        text: 'view1',
      },
    }
    return await this.post(url, formData)
  }

  async recurringPOST(
    productId: string,
    cardId: string
  ): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/recurring')
    const formData = {
      product_id: productId,
      card_id: cardId,
      context: {
        text: 'view1',
      },
    }
    return await this.post(url, formData)
  }

  async orderStatusPOST(orderId: number): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/order_status')
    const formData = {
      order_id: orderId.toString(),
    }
    return await this.post(url, formData)
  }

  async solidGatePaymentPOST(productId: string): Promise<IHttpResponse> {
    const url = this.createURL('payment/solid_gate/get_payment')
    const formData = {
      product_id: productId,
      context: {
        text: 'view1',
      },
    }
    return await this.post(url, formData)
  }
}
