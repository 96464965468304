const CACHE_NAME = 'ban-reason'

export function banCacheGet(): string {
  const cache = sessionStorage.getItem(CACHE_NAME)
  return cache || ''
}

export function banCacheSet(reason: string) {
  sessionStorage.setItem(CACHE_NAME, reason)
}

export function banCacheRemove() {
  sessionStorage.removeItem(CACHE_NAME)
}
