// import { onBeforeUnmount } from '@nuxtjs/composition-api'
import { useUserStore } from './user.store'
import { websocket } from '@/src/shared/lib/websocket'
import { billingBalance } from '@/logic/billing'

const EMIT_NAME = 'balance'

export function useWebsocketOnBalance() {
  function callback(payload: any) {
    const store = useUserStore()
    if (typeof payload === 'object' && payload?.a?.balance >= 0) {
      store.balance = payload.a.balance
      billingBalance.value = payload.a.balance
    } else if (typeof payload === 'object' && payload.balance >= 0) {
      billingBalance.value = payload.balance
      store.balance = payload.balance
    } else if (payload >= 0) {
      billingBalance.value = payload
      store.balance = payload
    }
  }

  const on = () => {
    const defaultClient = websocket.client('application')
    defaultClient.on(EMIT_NAME, callback)
  }
  const off = () => {
    const defaultClient = websocket.client('application')
    defaultClient.off(EMIT_NAME, callback)
  }

  // onBeforeUnmount(() => off())
  return {
    on,
    off,
  }
}
