import { videoCallEntity } from '@/src/entities/video-call2'

export function secondRoleGet() {
  return videoCallEntity.model.secondRoleGet()
}

export function connectionUserId() {
  const store = videoCallEntity.model.useVideoCallStore()
  return store.remoteUser?.userId
}

export function isPrivateCall(): boolean {
  const store = videoCallEntity.model.useVideoCallStore()
  return !!store.remoteUser
}
