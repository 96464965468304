import { userPhotoPost } from '@/src/shared/api'
type UserApiResponse = {
  success: boolean
  userPhoto: {
    id: number
  }
}

export async function userPhotoUpload(file: File): Promise<number> {
  const response = await userPhotoPost<UserApiResponse>(file)
  const isResponseOk = response.isOk && response.data
  return isResponseOk ? response.data.userPhoto.id : 0
}
