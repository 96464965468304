import { ChatApiMessagesResponse, ChatApiThreadDto, ChatThread } from '../types'
import { THREAD_LOAD_LIMIT } from '../config'
import { useChatStore } from './chat.store'
import { messagesGet } from '@/src/shared/api'

function messagesMapper(dto: ChatApiThreadDto): ChatThread {
  return {
    id: dto.user.id,
    // type: dto.threadType,
    online: dto.user.isOnline,
    // avatar: UserAvatarMapper.dtoToSmallString(dto.user.avatar),
    avatar: dto.user.avatar?.urlS || '',
    name: dto.user.name,
    gender: dto.user.gender,
    age: 0,
    // read: dto.read === 1,
    createdAt: dto.ts,
    // isVip: dto.user.hasPurchase,
    // lastMessage: dto.lastMessage,
    // lastMessageReadTimestamp: dto.userReadTime,
  }
}

export async function threadsLoad(offsetTimeMarker?: number): Promise<void> {
  const chatStore = useChatStore()
  const response = await messagesGet<ChatApiMessagesResponse>(
    offsetTimeMarker,
    THREAD_LOAD_LIMIT
  )
  if (response.isOk) {
    if (!offsetTimeMarker) {
      response.data?.messages
        .map((thread) => messagesMapper(thread))
        .forEach((thread) => chatStore.threadPush(thread))
    }
  }
}
