import { statusSet } from './privateCallStatus.setup'
import { videoCallEntity } from '@/src/entities/video-call2'

export function useRemotUser() {
  videoCallEntity.model.useWebsocketOnUser(function (
    remoteUser: videoCallEntity.types.VideoCallPayloadSocketUser
  ) {
    videoCallEntity.model.remoteUserSet(remoteUser)
    statusSet('connecting')
    videoCallEntity.model.websocketSendAccept(remoteUser.socketId)
  })
}
