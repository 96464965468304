import { PrivateCallRemoteUser, PrivateCallStatusResponse } from '../types'
import { usePrivateCallStore } from './privateCall.store'
import { videoCallPost } from '@/src/shared/api'

type PrivateCallApiCreateResponse = {
  connectionPrice: number // 120
  message: string // 'Call'
  status: PrivateCallStatusResponse
  callTag: string // 'default'
  user: {
    id: number
    name: string // 'Valentina Herera'
    age: number
    isHost: boolean
    avatar: {
      id: number
      type: number // 1
      userId: number
      bucket: string // 'litsta'
      key: string // '2lp8vlvtea04g4cg8w8w4ggko.jpg'
      order: number // 0
      faceCoord: {
        top: number // 0.3739526867866516
        left: number // 0.30166515707969666
        width: number // 0.40497657656669617
        height: number // 0.33991947770118713
      }
      cdnLink: string // 'https://d1qliwb04d4vjq.cloudfront.net'
      urlT: string // 'https://d1qliwb04d4vjq.cloudfront.net/eyJidWNrZXQiOiJsaXRzdGEiLCJrZXkiOiIybHA4dmx2dGVhMDRnNGNnOHc4dzRnZ2tvLmpwZyIsImVkaXRzIjp7InNtYXJ0Q3JvcCI6eyJwYWRkaW5nIjoxNTB9LCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=.jpg'
      urlS: string // 'https://d1qliwb04d4vjq.cloudfront.net/eyJidWNrZXQiOiJsaXRzdGEiLCJrZXkiOiIybHA4dmx2dGVhMDRnNGNnOHc4dzRnZ2tvLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6MjAwLCJoZWlnaHQiOjIwMCwiZml0IjoiY292ZXIiLCJzYXZlRmFjZSI6dHJ1ZX19fQ==.jpg'
      urlM: string // 'https://d1qliwb04d4vjq.cloudfront.net/eyJidWNrZXQiOiJsaXRzdGEiLCJrZXkiOiIybHA4dmx2dGVhMDRnNGNnOHc4dzRnZ2tvLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJoZWlnaHQiOjQwMCwiZml0IjoiY292ZXIifX19.jpg'
      urlB: string // 'https://d1qliwb04d4vjq.cloudfront.net/eyJidWNrZXQiOiJsaXRzdGEiLCJrZXkiOiIybHA4dmx2dGVhMDRnNGNnOHc4dzRnZ2tvLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6ODAwLCJoZWlnaHQiOjgwMCwiZml0IjoiY292ZXIiLCJzYXZlRmFjZSI6dHJ1ZX19fQ==.jpg'
      urlO: string // 'https://d1qliwb04d4vjq.cloudfront.net/eyJidWNrZXQiOiJsaXRzdGEiLCJrZXkiOiIybHA4dmx2dGVhMDRnNGNnOHc4dzRnZ2tvLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJoZWlnaHQiOjgwMCwiZml0IjoiY292ZXIifX19.jpg'
      urlF: string // 'https://d1qliwb04d4vjq.cloudfront.net/eyJidWNrZXQiOiJsaXRzdGEiLCJrZXkiOiIybHA4dmx2dGVhMDRnNGNnOHc4dzRnZ2tvLmpwZyIsImVkaXRzIjpbXX0=.jpg'
    }
    genderValue: number // 2
    isFake: boolean
    videoStory: any | null // ???
  }
}

export async function createCall(
  callerId: number,
  receiverId: number,
  entry: string = 'default'
): Promise<PrivateCallStatusResponse | undefined> {
  const store = usePrivateCallStore()
  const response = await videoCallPost<PrivateCallApiCreateResponse>(
    receiverId,
    entry
  )

  if (response.isOk && response.data) {
    store.callerId = callerId
    store.receiverId = receiverId
    const remoteUser: PrivateCallRemoteUser = {
      id: response.data?.user.id || 0,
      name: response.data?.user.name || '',
      avatar: response.data?.user.avatar?.urlS || '',
      age: response.data?.user.age || 0,
      isHost: response.data?.user.isHost || false,
    }
    store.remoteUser = remoteUser
    store.direction = 'outcome'
    store.status = 'calling'
    return response.data.status
  }

  return undefined
}
