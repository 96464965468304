import { VideoCallStatus } from '../types'
import { useVideoCallStore } from './videoCall.store'

export function statusSet(status: VideoCallStatus): void {
  const videoStore = useVideoCallStore()
  videoStore.status = status
}

export function reset() {
  const videoStore = useVideoCallStore()
  videoStore.reset()
}
