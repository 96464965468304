import { ref, watch } from '@nuxtjs/composition-api'
import { useMediaStore } from './media.store'
import { mediaLocalVideoSettings } from './mediaLocalStream.setup'

export function useMediaTackePicture() {
  const mediaStore = useMediaStore()
  const videoNode = ref<HTMLVideoElement>()

  watch(
    () => mediaStore.localStream,
    () => init()
  )

  function init() {
    if (process.server || !mediaStore.localStream) return
    videoNode.value = document.createElement('video')
    videoNode.value.muted = true
    videoNode.value.autoplay = true
    videoNode.value.srcObject = mediaStore.localStream
    videoNode.value.oncanplay = function () {
      videoNode.value?.play()
    }
  }

  function shot(): string {
    if (!videoNode.value || !mediaStore.localStream) return ''
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) return ''

    const { width = 200, height = 200 } = mediaLocalVideoSettings()

    canvas.width = width
    canvas.height = height
    context.drawImage(videoNode.value, 0, 0, width, height)

    const data = canvas.toDataURL('image/png')
    return data
  }

  function reset() {
    videoNode.value = undefined
  }

  return {
    init,
    reset,
    shot,
  }
}
