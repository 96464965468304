import {
  dialogNitification,
  dialogNotificationPrivateCalls,
  dialogActive,
  dialogThreads,
  dialogThreadsCanLoadMore,
  dialogThreadsLoading,
  dialogThreadNeedOpenId,
  dialogThreadNeedOpenEntry,
  dialogThreadMessages,
  dialogThreadSelectedId,
  dialogThreadSelectedObject,
  dialogThreadMessagesLoading,
  dialogThreadMessagesCanLoadMore,
  dialogPrivateCallsShow,
} from '.'

export function useDialogHelpers() {
  function dialogResetState() {
    dialogNitification.value = 0
    dialogNotificationPrivateCalls.value = 0
    dialogPrivateCallsShow.value = false
    dialogActive.value = false
    dialogThreadsCanLoadMore.value = false
    dialogThreadsLoading.value = false
    dialogThreads.value = []
    dialogThreadNeedOpenId.value = 0
    dialogThreadNeedOpenEntry.value = ''
    dialogThreadSelectedId.value = 0
    dialogThreadSelectedObject.value = undefined
    dialogThreadMessagesLoading.value = false
    dialogThreadMessages.value = []
    dialogThreadMessagesCanLoadMore.value = false
  }

  // function dialogResetAll() {
  //   dialogActive.value = false
  //   // dialogThreadActiveId.value = 0
  //   dialogThreadMessages.value = []
  //   dialogPrivateCallsShow.value = false
  // }

  // function dialogThreadFindIndexById(interlocutorId: number): number {
  //   return dialogThreads.value.findIndex(
  //     (thread) => thread.interlocutorId === interlocutorId
  //   )
  // }

  // function dialogThreadMessageSet(index: number, update: any): void {
  //   const message = dialogThreadMessages.value[index]
  //   dialogThreadMessages.value.splice(index, 1, Object.assign(message, update))
  // }

  return {
    dialogResetState,
  }
}
