import { useContext } from '@nuxtjs/composition-api'
import { AnalyticAuthMethod } from '@/src/shared/lib/analytics'
import { urlCache } from '@/src/shared/lib/urlCache'
import { userEntity } from '@/src/entities/user'

export function useAfterRegistrationAnalytics() {
  const { $analytics } = useContext()
  const userStore = userEntity.model.useUserStore()

  return (method: AnalyticAuthMethod) => {
    $analytics.event('registration_complete', {
      source: method,
    })
    $analytics.signUp(method)
    if (userStore.me) {
      $analytics.userId(userStore.me.id.toString())

      if (userStore.me?.userTests?.length) {
        $analytics.userProps({ userTests: userStore.me.userTests.join(',') })
      }

      const userProps = $analytics.userPropsGet({
        category: userStore.me.category,
        role: userEntity.model.roleGet(),
      })

      const partnerId = userStore.me.partnerId?.toString()
      userProps.partner_id = partnerId
      const params = urlCache.searchParamsGet('enter')
      const regUserProps: any = {
        reg_partner_id: params?.p || '',
        reg_utm_source: params?.utm_source || '',
        reg_utm_medium: params?.utm_medium || '',
        reg_utm_campaign: params?.utm_campaign || '',
        reg_utm_term: params?.utm_term || '',
        reg_utm_content: params?.utm_content || '',
      }

      regUserProps.reg_partner_id = partnerId
      $analytics.userProps({ ...userProps, ...regUserProps })
    }
  }
}
