import { MODAL_NAME } from './config'
import { PrivateCallBusyModalOpenPayload } from './types'
import { ModalExternalService } from '@/src/shared/lib/modal'
import { eventBus } from '@/src/shared/lib/eventBus'

class ModalService extends ModalExternalService {
  constructor() {
    super(MODAL_NAME, eventBus)
  }

  public open(payload: PrivateCallBusyModalOpenPayload) {
    super.open(payload)
  }

  public close() {
    super.close()
  }
}

export const modal = new ModalService()
