import { useContext, useRouter } from '@nuxtjs/composition-api'

import { ROUTE_NAV } from '@/src/shared/config'
import { cookiesAccess } from '@/src/shared/lib/cookiesAccess'

import { authEntity } from '@/src/entities/auth'
import { userEntity } from '@/src/entities/user'

export function useAuthAutoLogin() {
  const router = useRouter()
  const {
    $analytics,
    $hotjar,
    $modal,
    $facebook,
    $ga,
    $amplitude,
  } = useContext()

  return async (analyticsShouldBeEnabled: boolean = false) => {
    const reponseValid = await authEntity.model.tokenIsValid()
    if (!reponseValid) return false

    const meResult = await userEntity.model.meLoad()
    if (!meResult) {
      $analytics.event('login_auto', {
        type: 'error',
        error: 'user me not load',
      })
      return false
    }

    if (userEntity.model.isPaid() && analyticsShouldBeEnabled) {
      $modal?.close('cookies-access-tollbar')
      $hotjar.init()
      $facebook.init()
      $ga.init()
      $amplitude.init()
      cookiesAccess.acceptAll()
    }

    $analytics.event('login_auto', {
      type: 'success',
    })
    router.replace(ROUTE_NAV.main)
    return true
  }
}
