import { VideoCallRemoteUser } from '../types'
import { websocket } from '@/src/shared/lib/websocket'

type VideoCallWebsocketSendDialogStartPayload = VideoCallRemoteUser

export function websocketSendDialogStart(
  payload: VideoCallWebsocketSendDialogStartPayload
): void {
  websocket.emitDefault('dialogStart', payload)
}
