import { FavoritesContext } from '../config'
import { userFavoritesPost } from '@/src/shared/api'

type FavoritesApiResponse = {
  success: boolean
}

export async function toogle(
  userId: number,
  context: FavoritesContext
): Promise<boolean> {
  const response = await userFavoritesPost<FavoritesApiResponse>(
    userId,
    context
  )
  if (response.isOk) {
    return response.data?.success || false
  }
  return false
}
