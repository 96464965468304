import { VideoCallError, VideoCallErrorDescription } from './videoCall.error'
import { useVideoCallStore } from './videoCall.store'
import { timerStart, timerStop } from './videoCallTimer.setup'
import { peerDestroy } from './videoCallPeer.setup'
import { websocketEmit } from './videoCallWebsocketEmit.setup'

export function dialogStart() {
  const videoCallStore = useVideoCallStore()
  timerStop()
  timerStart()
  websocketEmit('dialogStart', videoCallStore.connectionUser)
  videoCallStore.status = 'active'
}

export function unregister(): void {
  const videoCallStore = useVideoCallStore()
  resetConnection()
  if (!videoCallStore.isEmpty) return undefined
  websocketEmit('unregister', undefined)
  videoCallStore.status = ''
}

export function blockUser() {
  const videoCallStore = useVideoCallStore()
  if (!videoCallStore.connectionUser)
    throw new VideoCallError(VideoCallErrorDescription.noRemoteUser)
  const userId = videoCallStore.connectionUser.userId
  websocketEmit('block', userId)
}

export function callSkip() {
  const videoCallStore = useVideoCallStore()
  if (!videoCallStore.remoteSocketId) return
  websocketEmit('skip', videoCallStore.remoteSocketId)
}

// export function callNext() {
//   const videoCallStore = useVideoCallStore()
//   callSkip()
//   resetConnection()
//   videoCallStore.status = ''
// }

function resetConnection() {
  const videoCallStore = useVideoCallStore()
  timerStop()
  peerDestroy()
  videoCallStore.resetConnection()
}
