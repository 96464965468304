import {
  ref,
  watch,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  useContext,
  useRouter,
  computed,
  useRoute,
} from '@nuxtjs/composition-api'
import { AuthResponseError } from '../auth/authError.service'
import { useAppWarmup } from '../app/app.usecase'
import { ModalEmits } from '../modal'
import { userMe } from '../user'
import {
  useAuth,
  useUser,
  useUserNotification,
  useBilling,
  useDialogWebsocket,
  appLoading,
} from '.'

import { websocket } from '@/src/shared/lib/websocket'
import { ROUTE_NAV } from '@/src/shared/config'

import { appEntity } from '@/src/entities/app'
import { authEntity } from '@/src/entities/auth'
import { rewardsEntity } from '@/src/entities/rewards'
import { userEntity } from '@/src/entities/user'
import { experimentEntity } from '@/src/entities/experiment'
import { alertFromServerEntity } from '@/src/entities/alert-from-server'

import { useDoubleWebsocketHandler } from '@/src/features/double-connection'
import { confirmEmailFeature } from '@/src/features/confirm-email'
import { favorites } from '@/src/features/favorites'
import { resetFeature } from '@/src/features/reset'
import { privateCallFeature } from '@/src/features/private-call'

export function useCab(_props: any, context: any) {
  const router = useRouter()
  const route = useRoute()
  const { $analytics, $modalOld } = useContext()
  const { authLogout } = useAuth()
  const { userMeLoad } = useUser()
  const { billingBalanceLoad } = useBilling()
  const { dialogWebsocketOn, dialogWebsocketOff } = useDialogWebsocket()
  const privateCallRequest = privateCallFeature.model.useOnIncome()
  const privateCallAnswer = privateCallFeature.model.useOnAnswer()
  const doubleWebsocketHandler = useDoubleWebsocketHandler()
  const userStore = userEntity.model.useUserStore()
  const userOnBalance = userEntity.model.useWebsocketOnBalance()
  const userOnUpdateMe = userEntity.model.useWebsocketOnUpdateMe()
  const userOnBan = userEntity.model.useWebsocketOnBan(cabWebsocketOff)
  const alertNotify = alertFromServerEntity.model.useWebsocketOnNotify()
  const alertOnOneTimeReward = rewardsEntity.model.useWebsocketOnOneTimeReward()
  const alertOnDailyReward = rewardsEntity.model.useWebsocketOnDailyReward()

  const isStoriesPage = computed(() => route.value.path.includes('/stories'))
  const ready = ref(false)

  let authResponseError: AuthResponseError | undefined

  /**
   * Event handlers
   */
  function cabWebsocketOn() {
    if (!websocket.connected('application')) return
    userOnBalance.on()
    userOnUpdateMe.on()
    userOnBan.on()
    privateCallRequest.on()
    privateCallAnswer.on()
    alertNotify.on()
    alertOnOneTimeReward.on()
    alertOnDailyReward.on()
  }

  function cabWebsocketOff() {
    if (!websocket.connected('application')) return
    userOnBalance.off()
    userOnUpdateMe.off()
    userOnBan.off()
    privateCallRequest.off()
    privateCallAnswer.off()
    alertNotify.off()
    alertOnOneTimeReward.off()
    alertOnDailyReward.off()
  }

  async function websocketConnect() {
    const userId = userStore.me?.id
    const accessToken = authEntity.model.accessTokenGet()
    if (!userId || !accessToken) return
    await websocket.connectAll(userId, accessToken)
    doubleWebsocketHandler.on()
    dialogWebsocketOn()
    cabWebsocketOn()
    favorites.onUserAddedSignal()
  }

  function websocketAllOff() {
    doubleWebsocketHandler.off()
    dialogWebsocketOff()
    cabWebsocketOff()
    favorites.offUserAddedSignal()
  }

  function onAuthError() {
    appLoading.value = true
    authLogout()
    resetFeature.model.all()
    router.push(ROUTE_NAV.authWelcome)
  }

  onBeforeMount(() => {
    resetFeature.model.all()
    authResponseError = new AuthResponseError()
    authResponseError.on(onAuthError)
  })

  /**
   * Public functions
   */
  onMounted(async () => {
    const reponseValid = await authEntity.model.tokenIsValid()
    if (!reponseValid) {
      return router.replace(ROUTE_NAV.authWelcome)
    }
    // Analytics
    $analytics.userProps({
      viewtype: window.innerWidth >= 1024 ? 'desktop' : 'mobile',
      domain: 'peachfy',
    })
    // Warmup load
    await useAppWarmup()
    await appEntity.model.warmupLoad()

    // Auth
    // await authTokenCheck()

    // User load
    await userMeLoad(true)
    await userEntity.model.meLoad()
    if (!userMe.value) {
      alert('You have been banned. To solve the problem ask support@mili.live')
      authLogout()
      router.replace(ROUTE_NAV.authWelcome)
      return
    }
    if (userMe.value.id) {
      $analytics.userId(userMe.value.id.toString())
    }
    if (userMe.value.partnerId) {
      $analytics.userProps({
        partner_id: userMe.value.partnerId.toString(),
      })
    }
    // Balance load
    await billingBalanceLoad()
    await userEntity.model.balanceLoad()

    await websocketConnect()
    // Experiment
    await experimentEntity.model.fetch()
    experimentEntity.model.analyticsProps($analytics)
    // Redirect
    ready.value = true
    // notification
    useUserNotification()
    // web push init
    // await webPushInit()
    // webPushOn()
    // online
    watch(
      () => context.root.isOnline,
      async (value: boolean) => {
        if (value) {
          await websocketConnect()
          $modalOld.close(ModalEmits.connectionFailed)
        } else {
          $modalOld.open(ModalEmits.connectionFailed)
          websocketAllOff()
          websocket.disconnectAll()
        }
      }
    )
    //
    confirmEmailFeature.model.openOnlyOne(userMe.value, 'refresh')
  })

  onBeforeUnmount(() => {
    websocketAllOff()
    websocket.disconnectAll()
    authResponseError?.off(onAuthError)
  })

  return {
    ready,
    isStoriesPage,
  }
}
